import { Row, Col } from "react-bootstrap";
import { Crown, Heart, Leaf, Sparkles, Star, Smartphone, Users2 as Users2Icon, Sofa, Paintbrush, Waves, Camera, VenetianMask, Gem, MapPin, Compass, Medal, Palette, Sun, Globe, Music, BatteryFull, Utensils, Salad, Pizza, Wine, Soup, Fish, Drumstick, Beef, Sandwich, Mountain, Sword, Library } from "lucide-react";
import { useEffect, useState } from "react";

const Keywords = ({ t }) => {

  const [product, setProduct] = useState("");

  useEffect(() => {
    const savedType = localStorage.getItem("product");
    if (savedType) {
      setProduct(savedType);
    }
  }, []);

  const initialCheckboxes = {
    Maison: [
      { title: t('luxe'), value: "Luxe", icon: <Crown size={20} strokeWidth={1.5} /> },
      { title: t('couple'), value: "Couple", icon: <Heart size={20} strokeWidth={1.5} /> },
      { title: t('nature'), value: "Nature", icon: <Leaf size={20} strokeWidth={1.5} /> },
      { title: t('charming'), value: "Charmant", icon: <Sparkles size={20} strokeWidth={1.5} /> },
      { title: t('unique'), value: "Unique", icon: <Star size={20} strokeWidth={1.5} /> },
      { title: t('modern'), value: "Moderne", icon: <Smartphone size={20} strokeWidth={1.5} /> },
      { title: t('conviv'), value: "Convivial", icon: <Users2Icon size={20} strokeWidth={1.5} /> },
      { title: t('comfort'), value: "Confort", icon: <Sofa size={20} strokeWidth={1.5} /> },
      { title: t('design'), value: "Design", icon: <Paintbrush size={20} strokeWidth={1.5} /> },
      { title: t('view_on_sea'), value: "Vue sur mer", icon: <Waves size={20} strokeWidth={1.5} /> },
      { title: t('tendency'), value: "Tendance", icon: <Camera size={20} strokeWidth={1.5} /> },
      { title: t('scenic'), value: "Scénique", icon: <VenetianMask size={20} strokeWidth={1.5} /> },
      { title: t('high_end'), value: "Haut de gamme", icon: <Gem size={20} strokeWidth={1.5} /> },
    ],

    Hotel: [
      { title: t('romantic'), value: "Romantique", icon: <Heart size={20} strokeWidth={1.5} /> },
      { title: t('nature'), value: "Nature", icon: <Leaf size={20} strokeWidth={1.5} /> },
      { title: t('charming'), value: "Charmant", icon: <Sparkles size={20} strokeWidth={1.5} /> },
      { title: t('unique'), value: "Unique", icon: <Star size={20} strokeWidth={1.5} /> },
      { title: t('modern'), value: "Moderne", icon: <Smartphone size={20} strokeWidth={1.5} /> },
      { title: t('conviv'), value: "Convivial", icon: <Users2Icon size={20} strokeWidth={1.5} /> },
      { title: t('comfort'), value: "Confort", icon: <Sofa size={20} strokeWidth={1.5} /> },
      { title: t('design'), value: "Design", icon: <Paintbrush size={20} strokeWidth={1.5} /> },
      { title: t('central'), value: "Central", icon: <MapPin size={20} strokeWidth={1.5} /> },
      { title: t('scenic'), value: "Scénique", icon: <VenetianMask size={20} strokeWidth={1.5} /> },
    ],

    Activité: [
      { title: t('scenic'), value: "Scénique", icon: <VenetianMask size={20} strokeWidth={1.5} /> },
      { title: t('nature'), value: "Nature", icon: <Leaf size={20} strokeWidth={1.5} /> },
      { title: t('safari'), value: "Safari", icon: <Compass size={20} strokeWidth={1.5} /> },
      { title: t('dive'), value: "Plongée", icon: <Compass size={20} strokeWidth={1.5} /> },
      { title: t('sport'), value: "Sport", icon: <Medal size={20} strokeWidth={1.5} /> },
      { title: t('art'), value: "Art", icon: <Palette size={20} strokeWidth={1.5} /> },
      { title: t('outdoors'), value: "En plein air", icon: <Sun size={20} strokeWidth={1.5} /> },
      { title: t('adventure'), value: "Aventure", icon: <Mountain size={20} strokeWidth={1.5} /> },
      { title: t('romantic'), value: "Romantique", icon: <Heart size={20} strokeWidth={1.5} /> },
      { title: t('conviv'), value: "Convivial", icon: <Users2Icon size={20} strokeWidth={1.5} /> },
      { title: t('culinary'), value: "Culinaires", icon: <Utensils size={20} strokeWidth={1.5} /> },
      { title: t('discover'), value: "Découverte", icon: <Globe size={20} strokeWidth={1.5} /> },
      { title: t('music'), value: "Musique", icon: <Music size={20} strokeWidth={1.5} /> },
      { title: t('hidden_gems'), value: "Joyaux cachés", icon: <Gem size={20} strokeWidth={1.5} /> },
      { title: t('fantasy'), value: "Fantasie", icon: <Sword size={20} strokeWidth={1.5} /> },
      { title: t('cultural'), value: "Culturel", icon: <Library size={20} strokeWidth={1.5} /> },
    ],

    Voiture: [
      { title: t('luxe'), value: "Luxe", icon: <Crown size={20} strokeWidth={1.5} /> },
      { title: t('high_end'), value: "Haut de gamme", icon: <Gem size={20} strokeWidth={1.5} /> },
      { title: t('unique'), value: "Unique", icon: <Star size={20} strokeWidth={1.5} /> },
      { title: t('modern'), value: "Moderne", icon: <Smartphone size={20} strokeWidth={1.5} /> },
      { title: t('ecologic'), value: "Ecologique", icon: <Leaf size={20} strokeWidth={1.5} /> },
      { title: t('comfort'), value: "Confort", icon: <Sofa size={20} strokeWidth={1.5} /> },
      { title: t('economic'), value: "Economique", icon: <BatteryFull size={20} strokeWidth={1.5} /> },
      { title: t('tendency'), value: "Tendance", icon: <Camera size={20} strokeWidth={1.5} /> },
      { title: t('design'), value: "Design", icon: <Paintbrush size={20} strokeWidth={1.5} /> },
      { title: t('conviv'), value: "Convivial", icon: <Users2Icon size={20} strokeWidth={1.5} /> },
    ],

    Package: [
      { title: t('luxe'), value: "Luxe", icon: <Crown size={20} strokeWidth={1.5} /> },
      { title: t('romantic'), value: "Romantique", icon: <Heart size={20} strokeWidth={1.5} /> },
      { title: t('conviv'), value: "Convivial", icon: <Users2Icon size={20} strokeWidth={1.5} /> },
      { title: t('dive'), value: "Plongée", icon: <Compass size={20} strokeWidth={1.5} /> },
      { title: t('sport'), value: "Sport", icon: <Medal size={20} strokeWidth={1.5} /> },
      { title: t('art'), value: "Art", icon: <Palette size={20} strokeWidth={1.5} /> },
      { title: t('culinary'), value: "Culinaires", icon: <Utensils size={20} strokeWidth={1.5} /> },
      { title: t('discover'), value: "Découverte", icon: <Globe size={20} strokeWidth={1.5} /> },
      { title: t('music'), value: "Musique", icon: <Music size={20} strokeWidth={1.5} /> },
      { title: t('hidden_gems'), value: "Joyaux cachés", icon: <Gem size={20} strokeWidth={1.5} /> },
      { title: t('scenic'), value: "Scénique", icon: <VenetianMask size={20} strokeWidth={1.5} /> },
      { title: t('cultural'), value: "Culturel", icon: <Library size={20} strokeWidth={1.5} /> },
    ],

    Restaurant: [
      { title: t('romantic'), value: "Romantique", icon: <Heart size={20} strokeWidth={1.5} /> },
      { title: t('conviv'), value: "Convivial", icon: <Users2Icon size={20} strokeWidth={1.5} /> },
      { title: t('charming'), value: "Charmant", icon: <Sparkles size={20} strokeWidth={1.5} /> },
      { title: t('unique'), value: "Unique", icon: <Star size={20} strokeWidth={1.5} /> },
      { title: t('modern'), value: "Moderne", icon: <Smartphone size={20} strokeWidth={1.5} /> },
      { title: t('italian'), value: "Italienne", icon: <Pizza size={20} strokeWidth={1.5} /> },
      { title: t('french'), value: "Française", icon: <Wine size={20} strokeWidth={1.5} /> },
      { title: t('chinese'), value: "Chinoise", icon: <Salad size={20} strokeWidth={1.5} /> },
      { title: t('mexican'), value: "Mexicaine", icon: <Sandwich size={20} strokeWidth={1.5} /> },
      { title: t('japanese'), value: "Japonaise", icon: <Soup size={20} strokeWidth={1.5} /> },
      { title: t('spanish'), value: "Espagnole", icon: <Fish size={20} strokeWidth={1.5} /> },
      { title: t('indian'), value: "Indienne", icon: <Drumstick size={20} strokeWidth={1.5} /> },
      { title: t('american'), value: "Américaine", icon: <Beef size={20} strokeWidth={1.5} /> },
    ],

    Transport: [
      { title: t('luxe'), value: "Luxe", icon: <Crown size={20} strokeWidth={1.5} /> },
      { title: t('conviv'), value: "Convivial", icon: <Users2Icon size={20} strokeWidth={1.5} /> },
      { title: t('scenic'), value: "Scénique", icon: <VenetianMask size={20} strokeWidth={1.5} /> },
      { title: t('central'), value: "Central", icon: <MapPin size={20} strokeWidth={1.5} /> },
      { title: t('comfort'), value: "Confort", icon: <Sofa size={20} strokeWidth={1.5} /> },
      { title: t('nature'), value: "Nature", icon: <Leaf size={20} strokeWidth={1.5} /> },
      { title: t('modern'), value: "Moderne", icon: <Smartphone size={20} strokeWidth={1.5} /> },
      { title: t('high_end'), value: "Haut de gamme", icon: <Gem size={20} strokeWidth={1.5} /> },
      { title: t('design'), value: "Design", icon: <Paintbrush size={20} strokeWidth={1.5} /> },
      { title: t('ecologic'), value: "Ecologique", icon: <Leaf size={20} strokeWidth={1.5} /> },
    ]
  };

  const [checkboxes, setCheckboxes] = useState(initialCheckboxes);

  const toggleCheckbox = (category, value) => {
    setCheckboxes(prevState => {
      const selectedCount = prevState[category].filter(checkbox => checkbox.isChecked).length;

      return {
        ...prevState,
        [category]: prevState[category].map(checkbox => {
          if (checkbox.value === value) {
            if (checkbox.isChecked) {

              return { ...checkbox, isChecked: false };
            } else if (selectedCount < 5) {

              return { ...checkbox, isChecked: true };
            } else {

              return checkbox;
            }
          }
          return checkbox;
        })
      };
    });
  };

  const data = checkboxes[product] || [];

  return (
    <section className="container form-transition">
      <Row className="justify-content-center">
        <Col lg="7" xl="6" className="my-3">
          <>
            <h2 className="fs-3 lh-base fw-medium text-dark">{t('keywords_title')}</h2>
            <p className="fs-5 fw-light">{t('keywords_caption')}</p>
            <div className="my-3">
              <Row>
                {data.map((checkbox, index) => (
                  <Col key={index} xs="6" sm="4" md="4" className="my-2">
                    <div
                      role="button"
                      className={`p-3 rounded-5 text-dark h-100 d-flex align-items-center ${checkbox.isChecked ? "border-dark  " : "border"}`}
                      onClick={() => toggleCheckbox(product, checkbox.value)}
                    >
                      <span className="text-dark">{checkbox.icon}</span>
                      <span className="mx-2 fs-6 fw-light">{checkbox.title}</span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </>
        </Col>
      </Row>
    </section>
  );
};

export default Keywords;

