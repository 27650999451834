import Home from "./Home";
import Operations from "../product/Operations";
import Structure from "../product/Structure";

function Main(props) {

  const components = {
    home: <Home />,
    hosting: <Operations />,
    structure: <Structure />,
  };

  const Component = components[props.component];

  return Component || null;
}

export default Main;

