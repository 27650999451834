import Title from '../Title'
import Photos from '../Photos'
import Informations from '../Informations'
import Review from "../Review";
import Booking from "../Booking";
import Location from "../Location";
import Attributes from "../hotelComponents/Attributes";
import Rooming from "../hotelComponents/Rooming";
import Policy from "../hotelComponents/HotelPolicy";
import HandleSteps from "../../../components/HandleSteps";

const Hotel = ({ step, t, isLoading, handlePrevStep, handleNextStep }) => {
    return (
        <section className="position-relative py-5 mb-5 h-100">
            <form action="/">
                {step === 1 && <Title />}
                {step === 2 && <Location t={t} />}
                {step === 3 && <Informations />}
                {step === 4 && <Attributes />}
                {step === 5 && <Rooming />}
                {step === 6 && <Photos />}
                {step === 7 && <Policy t={t} />}
                {step === 8 && <Booking t={t} />}
                {step === 9 && <Review t={t} />}
            </form>
            <HandleSteps isLoading={isLoading} totalSteps={9} step={step} prev={handlePrevStep} next={handleNextStep} sepratorOne={-1} SeparatorTwo={-1} />
        </section>
    )
}

export default Hotel
