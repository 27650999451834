import { Monitor, Smartphone, Tablet } from 'lucide-react'
import { Progress } from 'antd';

const Devices = ({mode, t }) => {

    const data = [
        { title: t('mobile'), icon: <Smartphone strokeWidth={1.3} />, count: 29.9, color: "#8077ec" },
        { title: t('desktop'), icon: <Monitor strokeWidth={1.3} />, count: 57.3, color: "#16A571" },
        { title: t('tablet'), icon: <Tablet strokeWidth={1.3} />, count: 12.8, color: "#FE7051" },
    ]

    return (
        <div className={`p-3 rounded h-100  ${mode ? "bg-dark text-white" : "border bg-white text-dark"}`}>
            <div className="fs-5 fw-medium">{t("traffic_devices")}</div>
            <p className="fs-6 text-secondary">{t('flux_source')}</p>
            <>
                {data.map(item => (
                    <div key={item.title} className='my-4'>

                        <div className='d-flex flex-column'>
                            <span style={{ color: item.color }}>{item.icon}</span><span className='fs-5 mt-2'>{item.title}</span>
                        </div>

                        <div className='mt-2'>
                            <Progress strokeLinecap="butt" percent={item.count} strokeColor={item.color} />
                        </div>

                    </div>
                ))}
            </>
        </div>
    )
}

export default Devices
