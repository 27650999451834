import ManageHeader from '../../components/ManageHeader'

const Administration = ({ t }) => {

   
    return (
        <section>
            <ManageHeader
                title={t('admin')}
                content={t('admin_caption')}
            />

            
        </section>
    )
}

export default Administration
