import React from 'react'
import ToolTip from '../Tooltip'

const CheckInput = ({ label, onChange, id, disabled, checked, info, value, name }) => {
    return (
        <div className='d-flex align-items-center'>

            <label class="checkbox-container fw-light">
                <input type="checkbox" id={id} onChange={onChange} disabled={disabled} checked={checked} value={value} name={name} />
                <span class="checkmark"></span>
                <span className='mx-2'>{label}</span>
            </label>
            {info && (<ToolTip body={info} placement="top"/>) }
        </div>
    )
}

export default CheckInput