import { Row, Col } from "react-bootstrap";
import { useParams } from 'react-router-dom';
import products from '../../data/products.json'
import { Progress } from "rsuite";
import Listing from "../dashboard/bookings/Listing";
import Stats from "../dashboard/bookings/Stats";
import Chart from "./components/Chart";

const Details = ({ t }) => {

  const { id } = useParams();
  const item = products.products.find(item => item.id === parseInt(id));

  return (
    <div>
      <Row>
        <Col xl="6" className="my-3">
          <div className="bg-white border rounded p-3 h-100">
            <Row className="justify-content-between align-items-end">
              <Col xl="9">
                <div className="fs-4 fw-medium text-dark">{item.title}</div>
                <div className="fs-5 text-capitalize my-2">{item.type}</div>
                <p className="fs-6 fw-light my-2">{t('created_the')} : {item.date}</p>
                <div className="mt-3">
                  <Progress.Line className="p-0" percent={item.progress} strokeColor="#8077EC" />
                </div>
              </Col>
              <Col xl="2">
                <div className="fs-6 fw-light text-blue">{t('continue')}</div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col md="6" xl="3" className="my-3">
          <div className="rounded text-white text-center bg-blue h-100 p-5">
            <div className="fs-1 fw-medium">{item.amount}€</div>
            <p className="fs-5 fw-light">{t('total_benefits_for_this_product')}</p>
          </div>
        </Col>
        <Col md="6" xl="3" className="my-3">
          <div className="rounded text-dark text-center bg-orange h-100 p-5">
            <div className="fs-1 fw-medium">{item.bookings}</div>
            <p className="fs-5 fw-light">{t('total_bookings_for_this_product')}</p>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl="12" className="my-2">
          <div className="fs-4 fw-medium text-dark">{t('my_bookings')}</div>
          <Listing t={t} />
        </Col>
      </Row>

      <Row>
        <Col xl="9" className="my-2">
          <Chart t={t} />
        </Col>
        <Col xl="3">
          <Stats t={t} />
        </Col>
      </Row>
    </div>
  );
};

export default Details;
