import Modal from "react-bootstrap/Modal";
import SignIn from "./SignIn";
import { Col, Row } from "react-bootstrap";
import ImageItem from "./ImageItem";
import { useState } from "react";
import SignUp from "./SignUp";
import { useTranslation } from "react-i18next";
import { X } from "lucide-react";

const Register = ({ show, setShow, handleLogin }) => {

  const { t } = useTranslation();

  const [activButton, setActivButton] = useState("in");

  const handleActivButton = (value) => setActivButton(value);

  return (
    <>
      <Modal show={show} fullscreen={true} onHide={() => setShow(false)}>
        <Modal.Body>
        <div role="button" className="d-flex justify-content-end text-dark" onClick={() => setShow(false)}><X size={30} strokeWidth={1.5} /></div>
          <div className="container pt-3">
            <Row className="justify-content-between mt-2">
              <Col lg="6" xl="6">
                <Row className="border">
                  <Col sm="6" className="my-3">
                    <button onClick={() => handleActivButton("in")} className={`p-3 w-100 ${activButton === "in" ? "btn-blue" : "btn-outline-blue"}`}>
                      {t('log_in')}
                    </button>
                  </Col>
                  <Col sm="6" className="my-3">
                    <button onClick={() => handleActivButton("up")} className={`p-3 w-100 ${activButton === "up" ? "btn-blue" : "btn-outline-blue"}`}>
                      {t('register')}
                    </button>
                  </Col>
                </Row>

                {activButton === "in" ? <SignIn handleLogin={handleLogin} /> : <SignUp handleLogin={handleLogin} />}
              </Col>
              <Col className="d-none d-lg-block" lg="6" xl="5">
                <ImageItem />
              </Col>
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Register;
