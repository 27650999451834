import Faq from "../../components/home/Faq";
import { useTranslation } from "react-i18next";
import Steps from "../../components/home/Steps";
import Member from "../../components/home/Member";
import { useState } from "react";
import Register from "./Register";
import Reasons from "../../components/home/Reasons";
import Footer from "../../layout/Footer";
import Competitors from "./Competitors";
import Partner from "./Partner";
import Main from "./Main";

const Home = ({ handleLogin }) => {

  const { t } = useTranslation();

  const [show, setShow] = useState(false);

  function handleShow() {
    setShow(true);
  }

  return (
    <section className="fs-6 text-dark">
      <div className="container">
        <Main handleShow={handleShow} />
        <Reasons t={t} />
        <Steps t={t} />
      </div>
      <Partner t={t} />
      <div className="container">
        <Competitors t={t} />
        <Member t={t} show={show} setShow={setShow} />
        <Faq />
      </div>
      <Register show={show} setShow={setShow} handleLogin={handleLogin} />
      <Footer t={t} />
    </section>
  );
};

export default Home;
