import React from "react";
import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import { useState } from "react";
import RoomOption from "./RoomOption";
import Size from "../../../components/Size";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const DetailsLayout = () => {
  const { t } = useTranslation();
  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (value) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [value]: !prevCheckedItems[value],
    }));
  };

  const data = [
    { title: t("bedroom_size"), value: "TailleChambre" },
    { title: t("floor_type"), value: "TypesSols" },
    {
      title: t("exceptional_location_for_bedroom"),
      value: "EmplacementChambre",
      info: t("special_step_for_bedroom"),
    },
    { title: t("room_assigned_before_arrival"), value: "ChambreAttribuée" },
    { title: t("separated_dining_room"), value: "SalleAManger" },
    { title: t("cupboard"), value: "Placard" },
    { title: t("separated_living_area"), value: "EspaceSalonSéparé" },
    {
      title: t("shared_accommodations"),
      value: "LogementsPartagés",
      info: t("bedrooms_are_shared_with_other_persons"),
    },
    { title: t("desk"), value: "Bureau" },
    { title: t("fireplace"), value: "Cheminée" },
    { title: t("floor_heating"), value: "ChauffageParLeSol" },
    { title: t("private_pool"), value: "PiscinePrivée" },
    { title: t("private_hot_tub"), value: "BainRemous" },
    { title: t("external_areas"), value: "EspacesExtérieurs" },
    { title: t("individual_decoration"), value: "DécorationIndividuelle" },
    { title: t("individual_furnitures"), value: "MobilierIndividuels" },
    { title: t("yard"), value: "Cour" },
    { title: t("no_window"), value: "AucuneFenêtre" },
  ];

  const floors = [
    { title: t("carpet"), value: "Tapis" },
    { title: t("parquet"), value: "Parquet" },
    { title: t("piles_capet"), value: "TapisPoilsLongs" },
    { title: t("smooth_floor"), value: "Sol lisse" },
    { title: t("floor_tile"), value: "Carrelage" },
    { title: t("fine_carpet"), value: "Moquette fine" },
  ];

  const location = [
    { title: t("basement"), value: "SousSol" },
    { title: t("ground_floor"), value: "RezDeChaussée" },
    { title: t("last_stage"), value: "DernierEtage" },
  ];

  const whirlpool = [
    { title: "A l'extérieur", value: "Extérieur" },
    { title: "A l'intérieur", value: "Intérieur" },
    { title: t("roof_top_terrace"), value: "TerasseSurLeToit" },
  ];

  const spaces = [
    { title: t("balcony"), value: "Balcon" },
    { title: t("balcony_or_patio"), value: "BalconOuPatio" },
    { title: t("wood_terrace"), value: "TerrasseOuPatio" },
    { title: t("furnished_terrace"), value: "BalconAménagé" },
    { title: t("furnished_balcony_or_patio"), value: "PatioOuBalconAménagé" },
    { title: t("furnished_lanai"), value: "LanaiAménagé" },
    { title: t("furnished_patio"), value: "PatioAménagé" },
    { title: t("lanai"), value: "Lanai" },
    { title: t("patio"), value: "Patio" },
  ];

  return (
    <div className="my-3 fs-6">
      <>
        {data.map((check, index) => (
          <div key={index} className="my-3">
            <CheckInput
              label={check.title}
              id={check.value}
              value={check.value}
              checked={checkedItems[check.value] || false}
              onChange={() => handleCheckboxChange(check.value)}
              info={check.info}
            />

            {checkedItems[check.value] && (
              <>
                {check.value === "TailleChambre" && (
                  <div className="mt-3">
                    <Size label={t("bedroom_size")} />
                  </div>
                )}

                {check.value === "TypesSols" && (
                  <>
                    <RoomOption />
                    {floors.map((check, index) => (
                      <div key={index} className="px-3 my-3">
                        <CheckInput id={check.value} label={check.title} />
                      </div>
                    ))}
                  </>
                )}

                {check.value === "EmplacementChambre" && (
                  <Row className="align-items-center">
                    <Col md="6" className="my-3">
                      <span className="fs-6 text-dark">
                        {t("bedroom_location")}
                      </span>
                    </Col>
                    <Col md="6" className="my-3">
                      <Select data={location} />
                    </Col>
                  </Row>
                )}

                {[
                  "SalleAManger",
                  "Placard",
                  "EspaceSalonSéparé",
                  "Bureau",
                  "LogementsPartagés",
                  "EspaceDeTravail",
                  "Cheminée",
                  "ChauffageParLeSol",
                  "AccèsViaCouloirs",
                  "PiscinePrivée",
                  "PetitePiscine",
                  "DécorationIndividuelle",
                  "MobilierIndividuels",
                  "Cour",
                ].includes(check.value) && <RoomOption />}

                {check.value === "EspacesExtérieurs" && (
                  <>
                    <RoomOption />
                    <Row className="align-items-center">
                      <Col md="6" className="my-2">
                        <span className="fs-6 text-dark">
                          {t("type_of_spaces")}
                        </span>
                      </Col>
                      <Col md="6" className="my-2">
                        <Select data={spaces} />
                      </Col>
                    </Row>
                  </>
                )}

                {check.value === "BainRemous" && (
                  <>
                    <RoomOption />
                    <Row className="align-items-center">
                      <Col md="6" className="my-2">
                        <span className="fs-6 text-dark">
                          {t("location")}
                        </span>
                      </Col>
                      <Col md="6" className="my-2">
                        <Select data={whirlpool} />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </div>
        ))}
      </>
    </div>
  );
};

export default DetailsLayout;
