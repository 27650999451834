import React from 'react'
import { Progress } from 'antd';
import { Col, Row } from 'react-bootstrap';

const Goals = ({ t, mode }) => {

    const data = [
        { title: t('costumers'), value: 38, count: 2.331 },
        { title: t('earnings'), value: 67, count: 121.9 },
        { title: t('bookings'), value: 75, count: 9624 },
    ]

    return (
        <div className={`rounded p-3  h-100 ${mode ? "bg-dark text-white" : "border bg-white text-dark"}`}>
            <div className="fw-medium fs-5">{t('goal_accomplished')}</div>
            <p className='fs-6 fw-light text-secondary'>62% {t('acquired_this_month')}</p>
            <div className='mt-5'>
                <Row>
                    {data.map(item => (
                        <Col md="4" key={item.value} className='my-3'>
                            <div className='d-flex flex-column align-items-center'>
                                <Progress strokeLinecap="butt" type="circle" size={140} percent={item.value} strokeColor={item.value >= 70 ? "#16A571" : item.value >= 45 ? "#FE7051" : "#f43f3f"} />
                                <div className='fs-3 fw-medium mt-5'>{item.title}</div>
                                <div className='fs-5 fw-light my-2'>{item.count}</div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    )
}

export default Goals
