import { Row, Col } from "react-bootstrap";
import Services from "./Amenties";

const FlightAtt = ({t}) => {

  return (
    <section className="container form-transition">
      <Row className="justify-content-center">
        <Col lg="10" xl="8" className="my-3">
          <div className="fs-3 fw-medium text-dark">{t('services_and_equipments')}</div>
          <p className="fs-5 fw-light">{t('services_and_equipments_for_travelers')} </p>
          <div className="my-4">
            <Services />
          </div>
        </Col>
      </Row>
    </section>
  );
};

export default FlightAtt;
