import React from 'react'
import { useTranslation } from "react-i18next";
import Stats from './Stats';
import { Col, Row } from 'react-bootstrap';
import Listing from "./Listing";
import data from "../../../data/clients.json"

const Details = ({ mode }) => {

    const { t } = useTranslation();

    return (
        <section className='py-3'>
            <h2 className={`fs-3 ${mode ? "text-white" : "text-dark"}`}>{t('my_clients')}</h2>
            <Row>
                <Col xl="9" className='my-2'>
                    <Listing mode={mode} t={t} data={data.clients} />
                </Col>
                <Col xl="3" className='my-2'>
                    <Stats mode={mode} t={t} />
                </Col>
            </Row>
        </section>
    )
}

export default Details
