import { AlarmClock, Bell } from 'lucide-react';
import React, { useState, useEffect } from 'react';

const DateAndNoteApp = ({ t,mode }) => {
    const [currentDate, setCurrentDate] = useState('');
    const [currentTime, setCurrentTime] = useState('');
    const [activButton, setActivButton] = useState('clock');
    const [note, setNote] = useState('');

    useEffect(() => {
        const updateDateTime = () => {
            const now = new Date();
            setCurrentDate(now.toLocaleString('en-US', {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            }));
            setCurrentTime(now.toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: false
            }));
        };

        updateDateTime();
        const interval = setInterval(updateDateTime, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const savedNote = sessionStorage.getItem('note');
        if (savedNote) {
            setNote(savedNote);
        }
    }, []);

    const handleButton = (value) => {
        setActivButton(value);
    };

    const handleNoteChange = (event) => {
        const newNote = event.target.value;
        setNote(newNote);
        sessionStorage.setItem('note', newNote);
    };

    return (
        <div className={`rounded p-3 py-4 h-100 d-flex flex-column justify-content-between ${mode ? 'bg-dark text-white' : 'border bg-white text-dark'}`}>

            {activButton === "clock" && (
                <div>
                    <div className='fs-6 fw-light text-secondary'>{currentDate}</div>
                    <div className='fs-1 fw-bold my-2'>{currentTime}</div>
                </div>
            )}

            <div>
                {activButton === "reminder" && (
                    <input
                        className='border-0 w-100 py-4 px-3'
                        placeholder={t('write_reminder')}
                        value={note}
                        onChange={handleNoteChange}
                    />
                )}
            </div>

            <div className='d-flex border rounded p-1'>
                <button title={t('clock')} className={`p-2 w-50 rounded-start ${activButton === "clock" ? "btn-blue" : "btn-outline-blue"}`} onClick={() => handleButton("clock")}><AlarmClock size={15} /></button>
                <button title={t('reminder')} className={`p-2 w-50 rounded-end ${activButton === "reminder" ? "btn-blue" : "btn-outline-blue"}`} onClick={() => handleButton("reminder")}><Bell size={15} /></button>
            </div>
        </div>
    );
};

export default DateAndNoteApp;
