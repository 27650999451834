
const ManageHeader = ({ title, content, icon }) => {
  return (
    <div className="bg-secondary-blue border-bottom">
      <div className="container d-flex align-items-start py-5">
        <span className="bg-white p-3 rounded-circle text-blue">{icon}</span>
        <span className="mx-3">
          <div className="fs-4 fw-medium text-dark">{title}</div>
          <p className="fs-6 fw-light">{content}</p>
        </span>
      </div>
    </div>
  );
};

export default ManageHeader;
