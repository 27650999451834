import { useTranslation } from "react-i18next";
import { Progress } from "rsuite";

const BestProduct = ({ mode, products }) => {

    const { t } = useTranslation();
    const currentYear = new Date().getFullYear();

    const bgOrange = {
        background: "linear-gradient(180deg, #ffef9d 0%, #f8d448 100%)",
        overflow: "hidden",
    };

    const item = products.reduce((max, current) =>
        current.earnings > max.earnings ? current : max
    );


    return (
        <div style={mode ? {} : bgOrange} className={`rounded p-3 ${mode ? "bg-dark text-white" : "text-dark"}`}>
            <div className="fw-medium fs-5">{t("best_selling_product")}</div>
            <p className="fs-6 text-secondary">{t("overview")} {currentYear}</p>

            <img width="100%" height="100%" src="https://modernize-angular-main.netlify.app/assets/images/backgrounds/piggy.png" alt="figure" />
            <div className={`p-3 mt-3 rounded ${mode ? "" : "bg-white"}`}>
                <div className="fs-6 fw-bold">{item.title.slice(0, 40)}</div>
                <div className="fs-6 my-1">{item.earnings}€</div>
                <Progress.Line className="px-0" percent={item.progress} strokeColor="#8077EC" />
            </div>
        </div>
    )
}

export default BestProduct
