import { Row, Col } from "react-bootstrap";
import { Bell, Maximize, Moon, Power, Sun } from "lucide-react";
import { useLocation } from "react-router-dom";
import LangChanger from "./LangChanger";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { logout, setLoading, toggleMode } from '../global/actions';

function Header({ darkMode }) {

  const dispatch = useDispatch();

  const { mode } = useSelector(state => state);

  const handleLogout = () => {
    dispatch(setLoading(true));
    setTimeout(() => {
      dispatch(logout());
      localStorage.removeItem("isLoggedIn");
      dispatch(setLoading(false));
    }, 3000);
  };

  const toggleDark = () => {
    dispatch(setLoading(true));
    const newMode = !mode;

    setTimeout(() => {
      dispatch(toggleMode(newMode));
      localStorage.setItem('mode', newMode);
      dispatch(setLoading(false));
    }, 1000);
  };

  const { t } = useTranslation();

  const location = useLocation();

  const handleFullscreenToggle = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };

  return (
    <header className={`w-100 rounded py-2 px-3 ${darkMode ? 'bg-dark text-white' : 'border bg-white text-dark'}`}>
      <Row className="justify-content-between align-items-center">
        <Col sm="6" className="d-none d-sm-block">
          <input className={`p-3 fw-light border-0 w-100 ${darkMode ? "bg-dark text-white" : "bg-white text-dark"}`} placeholder={t('search_something')} />
        </Col>
        <Col sm="6" md="5" lg="4" xl="3">
          <div className="d-flex justify-content-between align-items-center">

            <span className="ms-4" role="button" title={t('notifications')}>
              <Bell size={20} strokeWidth={1.3} />
            </span>

            {darkMode ?

              <span className="ms-4" role="button" title={t('light_mode')} onClick={toggleDark}>
                <Sun size={20} strokeWidth={1.3} color="#EFDF2B" fill="#EFDF2B" />
              </span>

              :

              <span className="ms-4" role="button" title={t('dark_mode')} onClick={toggleDark}>
                <Moon size={20} strokeWidth={1.3} color="#2A3650" fill="#2A3650" />
              </span>
            }

            <span className="ms-4" role="button" title={t('full_screen')} onClick={handleFullscreenToggle}>
              <Maximize size={20} strokeWidth={1.3} />
            </span>

            <span className="ms-4" title={t('langs')}>
              <LangChanger t={t} location={location} />
            </span>

            <span className="ms-4 text-red" role="button" title={t('log_out')} onClick={handleLogout}>
              <Power size={20} strokeWidth={1.3} />
            </span>

          </div>
        </Col>
      </Row>
    </header>
  );
}

export default Header;
