import { Gamepad2, Utensils, Wifi } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Choice from "../../../components/Choice";
import Services from "./Services";


const EquipmentItem = ({ title, value, onChange, icon, content }) => (

    <div className="my-4">
        <Choice
            text={<div className="d-flex align-items-start"><span>{icon}</span><span className="mx-2">{title}</span></div>}
            onChange={onChange}
            selectedChoice={value}
        />
        {value === "yes" && content.map((element, index) => (
            <div key={index} className="py-3 my-3">
                <div className="text-blue fw-light fs-6">{element.title}</div>
                <div>{element.content}</div>
            </div>
        ))}
    </div>
);

const Amenties = () => {

    const { t } = useTranslation();

    const [infos, setInfos] = useState({
        wifi: "yes",
        food: "yes",
        entertaining: "yes"
    });

    const handleChange = (key) => (value) => {
        setInfos(prev => ({ ...prev, [key]: value }));
    };

    const data = [
        {
            title: t('wifi_on_board'),
            value: infos.wifi,
            onChange: handleChange('wifi'),
            icon: <Wifi size={20} strokeWidth={1.5} />,
            content: [
                { title: t('wifi'), content: <Services serviceKey="wifi" /> },
            ]
        },
        {
            title: t('food_offerts'),
            value: infos.food,
            onChange: handleChange('food'),
            icon: <Utensils size={20} strokeWidth={1.5} />,
            content: [
                { title: t('served_food'), content: <Services serviceKey="food" /> },
            ]
        },
        {
            title: t('entertaining_title'),
            value: infos.entertaining,
            onChange: handleChange('entertaining'),
            icon: <Gamepad2 size={20} strokeWidth={1.5} />,
            content: [
                { title: t('entertaining_board'), content: <Services serviceKey="entertaining" /> },
            ]
        },
    ];

    return (
        <div className="my-4">
            {data.map((item, index) => (
                <>
                    <EquipmentItem
                        key={index}
                        title={item.title}
                        value={item.value}
                        onChange={item.onChange}
                        icon={item.icon}
                        content={item.content}
                    />
                    <hr className="border-secondary my-5" />
                </>
            ))}

        </div>
    );
};

export default Amenties;
