import { Col, Row } from 'react-bootstrap';
import { Image, Mic, Paperclip, Send } from 'lucide-react';

const SendingMessage = ({mode,t}) => {
    return (
        <Row className='justify-content-end'>
            <Col md="10" lg="8" xl="9">
                <Row className='align-items-center'>
                    <Col sm="4" md="3" xl="2" className='my-1'> 
                        <span className='d-flex'>
                            <span role='button' title={t('add_file')} className='mx-3'><Paperclip size={20} strokeWidth={1.5} /></span>
                            <span role='button' title={t('add_image')} className='mx-3'><Image size={20} strokeWidth={1.5} /></span>
                            <span role='button' title={t('save_vocal')} className='mx-3'><Mic size={20} strokeWidth={1.5} /></span>
                        </span>
                    </Col>
                    <Col sm="7"  md="8" xl="9" className='my-1'>
                        <input className={`w-100 p-3 border-0 ${mode ? "bg-dark text-white" : "bg-white text-dark"}`} placeholder="Votre message..." />
                    </Col>
                    <Col sm="1"  md="1" className='my-1'>
                        <span className='text-green' role='button' title={t('submit')}>
                            <Send strokeWidth={1.5} />
                        </span>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default SendingMessage
