import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Eye, UserCheck2, UserPlus2 } from 'lucide-react'
import ChartItem from '../components/ChartItem'

const Stats = ({ t ,mode}) => {
    const data = [
        { title: t('newest'), count: "39,6%", icon: <UserPlus2 size={25} strokeWidth={1.5} />, data: [12, 15, 13, 16, 18, 19, 11], color: "#8077EC", status: "up", growth: 3 },
        { title: t('returning_costumers'), count: "61.4%", icon: <UserCheck2 size={25} strokeWidth={1.5} />, data: [42, 64, 48, 26, 44, 55, 41], color: "#16A571", status: "down", growth: 2 },
        { title: t('seen'), count: 24.933, icon: <Eye size={25} strokeWidth={1.5} />, data: [131, 102, 223, 45, 145, 148, 166], color: "#FF5733", status: "up", growth: 16 },
    ]

    return (
        <Row>
            {data.map(item => (
                <Col md="6" lg="4" xl="12" className='my-3'>
                    <ChartItem mode={mode} icon={item.icon} title={item.title} count={item.count} color={item.color} data={item.data} status={item.status} growth={item.growth} />
                </Col>
            ))}
        </Row>
    )
}

export default Stats
