import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import supplierData from "../../../data/globaldata.json";
import Choice from "../../../components/Choice";
import LateArrival from "./LateArrival";
import CheckInput from "../../../components/forms/CheckInput";
import DepartFees from "./DepartFees";
import { useTranslation } from "react-i18next";

const Arrival = () => {
  const { t } = useTranslation();

  const [infos, setInfos] = useState({
    hourLimit: false,
    lateArrival: "no",
    anticipatedArrival: "no",
    timeRange: false,
  });

  const handleLateArrival = (value) => {
    setInfos({ ...infos, lateArrival: value });
  };

  const handleAnticipatedArrival = (value) => {
    setInfos({ ...infos, anticipatedArrival: value });
  };

  const handleHourLimit = () => {
    setInfos({ ...infos, hourLimit: !infos.hourLimit });
  };

  return (
    <div className="my-3 fs-6">
      <span className="text-dark">{t('when_travelers_arrive')}</span>
      <Row className="align-items-center">
        <Col md="6" className="d-flex align-items-center my-3">
          <div className="d-flex flex-column w-100">
            <span className="fs-6">{t('from')}</span>
            <div className="my-1">
              <Select data={supplierData.schedules} />
            </div>
          </div>
        </Col>
        {!infos.hourLimit && (
          <Col md="6" className="d-flex align-items-center my-3">
            <div className="d-flex flex-column w-100">
              <span className="fs-6">{t('at_the')}</span>
              <div className="my-1">
                <Select data={supplierData.schedules} />
              </div>
            </div>
          </Col>
        )}
      </Row>

      <div className="my-3">
        <CheckInput id="NoArrivalLimit" label={t('no_arrival_deadline')} checked={infos.hourLimit} onChange={handleHourLimit} />
      </div>

      {!infos.timeRange ||
        (!infos.hourLimit && (
          <>
            <hr className="border-secondary my-3" />
            <Row className="align-items-center">
              <Col md="6" className="d-flex align-items-center my-3">
                <div className="d-flex flex-column w-100">
                  <span className="fw-medium">{t('from')}</span>
                  <Select data={supplierData.schedules} />
                </div>
              </Col>

              <Col md="6" className="d-flex align-items-center my-3">
                <div className="d-flex flex-column w-100">
                  <span className="fw-medium">{t('at_the')}</span>
                  <Select data={supplierData.schedules} />
                </div>
              </Col>
            </Row>
          </>
        ))}

      <>
        <div className="my-3 fs-6">
          <Choice text={t('early_arrival_available')} selectedChoice={infos.anticipatedArrival} onChange={handleAnticipatedArrival} />
        </div>

        {infos.anticipatedArrival === "yes" && <DepartFees text={t('early_arrival_fees')} />}
      </>

      <>
        <div className="my-3 fs-6">
          <Choice text={t('late_arrival_service')} selectedChoice={infos.lateArrival} onChange={handleLateArrival} />
        </div>

        {infos.lateArrival === "yes" && <LateArrival />}
      </>
    </div>
  );
};

export default Arrival;
