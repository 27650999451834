import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Autocomplete from '../../../components/forms/Autocomplete'
import countries from "../../../data/countries.json"
import airports from "../../../data/airports.json"
import TimeInput from '../../../components/forms/TimeInput'
const Stopover = ({ t }) => {
    return (
        <Row>
            <Col md="4" className="my-3">
                <div className="text-dark">{t('stopover_country')}</div>
                <div className="my-1">
                    <Autocomplete data={countries.countries} labelKey="title" id="StopCountry" placeholder={t('country')} />
                </div>
            </Col>
            <Col md="4" className="my-3">
                <div className="text-dark">{t('stopover_airport')}</div>
                <div className="my-1">
                    <Autocomplete data={airports.airports} labelKey="name" id="StopAirport" placeholder={t('airport')} />
                </div>
            </Col>
            <Col md="4" className="my-3">
                <div className="text-dark">{t('stopover_duration')}</div>
                <div className="my-1">
                    <TimeInput placeholder={t('duration')} />
                </div>
            </Col>
        </Row>
    )
}

export default Stopover
