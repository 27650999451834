import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import Height from "../../../components/Height";
import { useTranslation } from "react-i18next";

const DetailsAccessibilityServices = ({ checked }) => {
    const { t } = useTranslation();


    const stairsLength = Array.from({ length: 99 }, (_, index) => ({
        title: `${index + 1}`,
        value: index + 1,
    }));

    return (

        <div className="my-3 fs-6">

            {checked === "EscaliersPourEntrée" && (
                <>
                    <Row className="fs-6 align-items-center">
                        <Col md="6" className="d-flex align-items-center my-3">
                            <span className="text-dark">{t('steps_count_for_reach_the_entrance')}</span>
                        </Col>
                        <Col md="6" className="d-flex align-items-center my-3">
                            <Select data={stairsLength} />
                        </Col>
                    </Row>
                </>
            )}



            {checked === "RampesDansLesCouloirs" && <Height label={t('hallway_ramp_height')} />}

            {checked === "RampesDansLesEscaliers" && <Height label={t('stairwell_ramp_height')} />}
        </div>
    );
};

export default DetailsAccessibilityServices;
