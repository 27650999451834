import HandleSteps from "../../../components/HandleSteps";
import Attributes from '../transportComponents/Attributes';
import Type from '../Type';
import Pricing from '../Pricing';
import Booking from '../Booking';
import StepComponent from '../../../components/StepComponent';
import Title from '../Title';
import Location from '../Location';
import Itenerary from '../transportComponents/Itenerary';
import Theme from '../transportComponents/Theme';
import Description from '../Description';
import Photos from '../Photos';
import Discount from '../Discount';
import Policy from "../transportComponents/Policy";

const Transport = ({ step, t, isLoading, handlePrevStep, handleNextStep }) => {
    return (
        <section className="position-relative py-5 mb-5 h-100">
            <form action="/">
                {step === 1 && <StepComponent step={1} />}

                {step === 2 && <Title />}
                {step === 3 && <Type t={t} />}
                {step === 4 && <Location t={t} />}
                {step === 5 && <Itenerary t={t} />}

                {step === 6 && <StepComponent step={2} />}

                {step === 7 && <Description />}
                {step === 8 && <Attributes t={t} />}
                {step === 9 && <Theme t={t} />}
                {step === 10 && <Photos />}

                {step === 11 && <StepComponent step={3} />}

                {step === 12 && <Policy t={t} />}
                {step === 13 && <Pricing />}
                {step === 14 && <Discount t={t} />}
                {step === 15 && <Booking t={t} />}
            </form>
            <HandleSteps isLoading={isLoading} totalSteps={15} step={step} prev={handlePrevStep} next={handleNextStep} sepratorOne={5} SeparatorTwo={10} />
        </section>
    )
}

export default Transport
