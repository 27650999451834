export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const SET_LOADING = 'SET_LOADING';
export const MODE = 'MODE';


export const login = () => ({
  type: LOGIN,
});

export const logout = () => ({
  type: LOGOUT,
});

export const setLoading = (isLoading) => ({
  type: SET_LOADING,
  payload: isLoading,
});

export const toggleMode = (newMode) => ({
  type: MODE,
  payload: newMode,
});