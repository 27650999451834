import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import RentalImg1 from '../assets/icones/undraw_ordinary_day_re_v5hy.svg';
import RentalImg2 from '../assets/icones/undraw_relaxing_at_home_re_mror.svg';
import RentalImg3 from '../assets/icones/undraw_back_home_nl-5-c.svg';
import PublishImg from '../assets/icones/undraw_remotely_-2-j6y.svg';
import ActivityImg1 from '../assets/icones/undraw_hiking_re_k0bc.svg';
import ActivityImg2 from '../assets/icones/undraw_camping_noc8.svg';
import ActivityImg3 from '../assets/icones/undraw_art_museum_-8-or4.svg';
import RestaurantImg1 from '../assets/icones/undraw_eating_together_re_ux62.svg';
import RestaurantImg2 from '../assets/icones/undraw_cooking_p7m1.svg';
import RestaurantImg3 from '../assets/icones/undraw_chef_cu-0-r.svg';
import TransportImg1 from '../assets/icones/undraw_order_ride_re_372k.svg';
import TransportImg2 from '../assets/icones/undraw_bus_stop_re_h8ej.svg';
import TransportImg3 from '../assets/icones/undraw_subway_-7-vh7.svg';
import CarImg1 from '../assets/icones/undraw_electric_car_b-7-hl.svg';
import CarImg2 from '../assets/icones/undraw_automobile_repair_ywci.svg';
import CarImg3 from '../assets/icones/undraw_delivery_truck_vt6p.svg';
import PackageImg1 from '../assets/icones/undraw_travel_booking_re_6umu.svg';
import PackageImg2 from '../assets/icones/undraw_trip_re_f724.svg';
import PackageImg3 from '../assets/icones/undraw_beach_day_cser.svg';
import EventImg1 from '../assets/icones/undraw_candidate_ubwv.svg';
import EventImg2 from '../assets/icones/undraw_presentation_re_sxof.svg';


const StepComponent = ({ step }) => {

  const { t } = useTranslation();

  const [product, setProduct] = useState("");

  useEffect(() => {
    const savedType = localStorage.getItem("product");
    if (savedType) {
      setProduct(savedType);
    }
  }, []);

  const configurations = {
    Maison: {
      titles: {
        "1": t('step1_rental_title'),
        "2": t('step2_rental_title'),
        "3": t('step3_title'),
      },
      images: {
        "1": RentalImg1,
        "2": RentalImg2,
        "3": RentalImg3
      },
      textes: {
        "1": t('step1_rental_text'),
        "2": t('step2_rental_text'),
        "3": t('step3_text')
      }
    },

    Activité: {
      titles: {
        "1": t('step1_activity_title'),
        "2": t('step2_activity_title'),
        "3": t('step3_title'),
      },
      images: {
        "1": ActivityImg1,
        "2": ActivityImg2,
        "3": ActivityImg3
      },
      textes: {
        "1": t('step1_activity_text'),
        "2": t('step2_activity_text'),
        "3": t('step3_text')
      }
    },
    Transport: {
      titles: {
        "1": t('step1_transport_title'),
        "2": t('step2_transport_title'),
        "3": t('step3_title'),
      },
      images: {
        "1": TransportImg1,
        "2": TransportImg2,
        "3": TransportImg3
      },
      textes: {
        "1": t('step1_transport_text'),
        "2": t('step2_transport_text'),
        "3": t('step3_text')
      }
    },
    Package: {
      titles: {
        "1": t('step1_package_title'),
        "2": t('step2_package_title'),
        "3": t('step3_title'),
      },
      images: {
        "1": PackageImg1,
        "2": PackageImg2,
        "3": PackageImg3
      },
      textes: {
        "1": t('step1_package_text'),
        "2": t('step2_package_text'),
        "3": t('step3_text')
      }
    },
    Voiture: {
      titles: {
        "1": t('step1_car_title'),
        "2": t('step2_car_title'),
        "3": t('step3_title'),
      },
      images: {
        "1": CarImg1,
        "2": CarImg2,
        "3": CarImg3

      },
      textes: {
        "1": t('step1_car_text'),
        "2": t('step2_car_text'),
        "3": t('step3_text')
      }
    },
    Restaurant: {
      titles: {
        "1": t('step1_restaurant_title'),
        "2": t('step2_restaurant_title'),
        "3": t('step3_title'),
      },
      images: {
        "1": RestaurantImg1,
        "2": RestaurantImg2,
        "3": RestaurantImg3
      },
      textes: {
        "1": t('step1_restaurant_text'),
        "2": t('step2_restaurant_text'),
        "3": t('step3_text')
      }
    },
    Événements: {
      titles: {
        "1": t('step1_event_title'),
        "2": t('step2_event_title'),
        "3": t('step3_title'),
      },
      images: {
        "1": EventImg1,
        "2": EventImg2,
        "3": PublishImg
      },
      textes: {
        "1": t('step1_event_text'),
        "2": t('step2_event_text'),
        "3": t('step3_text')
      }
    }
  };

  const data = configurations[product] || {};

  return (
    <section className="position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col xl="10">
            <Row className="align-items-center">
              <Col lg="6" className="my-3">
                <div className="py-5 px-sm-5 text-dark">
                  <div className="fs-5 fw-bold text-blue">
                    {t("step")} {step}
                  </div>
                  <h2 className="fs-1 lh-base fw-bold text-dark my-1">{data.titles?.[step]}</h2>
                  <p className="fs-5 fw-light my-3">{data.textes?.[step]}</p>
                </div>
              </Col>
              <Col lg="6">
                <div className="w-100">
                  <img
                    alt={`illustration de l'étape ${step}`}
                    width="100%"
                    height="100%"
                    src={data.images?.[step]}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default StepComponent;
