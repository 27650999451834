import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

const Member = ({ show, setShow }) => {

  const { t } = useTranslation();

  return (
    <div className='p-5 bg-secondary-blue my-5'>
      <Row className='justify-content-center align-items-center'>
        <Col lg="8" className='my-3'>
          <div className='fs-1 fw-bold' dangerouslySetInnerHTML={{ __html: t('not_member_yet') }}></div>
          <p className='fs-6'>{t('member_caption')}</p>
        </Col>
        <Col lg="4" className='my-3'>
          <Row>
            <Col lg="6" className='my-3'>
              <button className='btn-outline-blue p-3 w-100' onClick={() => setShow(true)}>{t('log_in')}</button>
            </Col>
            <Col lg="6" className='my-3'>
              <button className='btn-blue border-blue p-3 w-100' onClick={() => setShow(true)}>{t('register')}</button>
            </Col>
          </Row>


        </Col>
      </Row>
    </div>
  )
}

export default Member
