import { CopyPlus, EyeOff, PencilLine, Trash2 } from "lucide-react";
import { Col, Row } from "react-bootstrap"
import { HiOutlineEllipsisVertical } from "react-icons/hi2";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Pagination from "../../../components/Pagination";

const Listing = ({ t, data,mode }) => {
    const renderItem = (item, index) => (
        <div key={index} className={`my-3 p-3 rounded w-100 ${mode ? "bg-dark text-white" : "border bg-white text-dark"}`}>
            <Row className="justify-content-between align-items-center">
                <Col xs="2" md="2" lg="1" className="my-1">
                    <img className="rounded-circle" src={item.image} alt="Illustration du client" width="45" height="45" />
                </Col>
                <Col xs="10" md="4" lg="2" className="my-1">
                    <div className='fs-6'>{item.first_name} {item.last_name}</div>
                </Col>
                <Col md="2" lg="1" className="my-1">
                    <div className='fs-6 fw-light'>{item.gender}</div>
                </Col>
                <Col md="4" lg="2" className="my-1">
                    <div className='fs-6 fw-light'>{item.phone}</div>
                </Col>
                <Col md="4" lg="3" className="my-1">
                    <div className='fs-6 fw-light'>{item.email}</div>
                </Col>
                <Col xs="6" md="4" lg="2" className="my-1">
                    <div className='fs-6 fw-light'>{item.date}</div>
                </Col>
                <Col xs="6" md="2" lg="1" className="product-dropdown d-flex justify-content-end my-1">
                    <NavDropdown

                        title={<div role="button"><HiOutlineEllipsisVertical size={25} /></div>}
                        menuVariant="light"
                    >
                        <a href={item.type === "hotel" ? "/myhotel" : `/myactivity/${item.id}`} className="d-flex align-items-center px-3 py-2 text-dark">
                            <span><PencilLine size={15} strokeWidth={1.5} /> </span><span className="mx-2">{t('edit')}</span>
                        </a>
                        <div className="d-flex align-items-center px-3 py-2">
                            <span><CopyPlus size={15} strokeWidth={1.5} /> </span><span className="mx-2">{t('clone')}</span>
                        </div>
                        <div className="d-flex align-items-center px-3 py-2">
                            <span><EyeOff size={15} strokeWidth={1.5} /> </span><span className="mx-2">{t('disable')}</span>
                        </div>
                        <NavDropdown.Divider />
                        <div className="d-flex align-items-center px-3 py-2">
                            <span className="text-red"><Trash2 size={15} strokeWidth={1.5} /> </span><span className="mx-2">{t('delete')}</span>
                        </div>
                    </NavDropdown>
                </Col>
            </Row>
        </div>
    );

    return (
        <Pagination data={data} renderItems={(items) => (<>{items.map(renderItem)}</>)} itemsPerPage={9} />
    );
};

export default Listing;

