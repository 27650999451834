import React from "react";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";
import Input from "../../../components/forms/Input";
import Select from "../../../components/forms/Select";
import ExtraPrices from "./ExtraPrices";
import Choice from "../../../components/Choice";
import RadioInput from "../../../components/forms/RadioInput";
import DateYear from "../../../components/DateYear";
import { MinusCircle, PlusCircle } from "lucide-react";
import { useTranslation } from "react-i18next";

const DetailsPricing = () => {
  const [infos, setInfos] = useState({
    rates: "PourTousLesJours",
    people: "no",
    type: "Montant",
  });

  const handleRates = (value) => {
    setInfos({ ...infos, rates: value });
  };

  const handlePeople = (value) => {
    setInfos({ ...infos, people: value });
  };

  const handleType = (value) => {
    setInfos({ ...infos, type: value });
  };

  const { t } = useTranslation();

  const week = [
    { title: t('saturday'), value: "Samedi" },
    { title: t('sunday'), value: "Dimanche" },
    { title: t('monday'), value: "Lundi" },
    { title: t('tuesday'), value: "Mardi" },
    { title: t('wednesday'), value: "Mercredi" },
    { title: t('thursday'), value: "Jeudi" },
    { title: t('friday'), value: "Vendredi" },
  ];

  const seasons = [
    { title: t('spring'), value: "Printemps" },
    { title: t('summer'), value: "Été" },
    { title: t('autumn'), value: "Automne" },
    { title: t('winter'), value: "Hiver" },
  ];

  const months = [
    { title: t('january'), value: "Janvier" },
    { title: t('february'), value: "Février" },
    { title: t('march'), value: "Mars" },
    { title: t('april'), value: "Avril" },
    { title: t('may'), value: "Mai" },
    { title: t('june'), value: "Juin" },
    { title: t('july'), value: "Juillet" },
    { title: t('august'), value: "Août" },
    { title: t('september'), value: "Septembre" },
    { title: t('october'), value: "Octobre" },
    { title: t('november'), value: "Novembre" },
    { title: t('december'), value: "Décembre" },
  ];


  const type = [
    { title: t('amount'), value: "Montant" },
    { title: t('pourcent'), value: "Pourcentage" },
  ];

  const rates = [
    { title: t('for_every_day'), value: "PourTousLesJours" },
    { title: t('per_week'), value: "ParSemaine" },
    { title: t('per_month'), value: "ParMois" },
    { title: t('per_season'), value: "ParSaison" },
    { title: t('ped_date'), value: "ParDate" },
  ];

  const [date, setBeds] = useState([
    {
      id: 1,
      value: "",
    },
  ]);

  const addDate = () => {
    if (date.length === 0) {
      setBeds([{ id: 1, value: "" }]);
    } else if (date.length < 10) {
      const newId = date[date.length - 1].id + 1;
      setBeds([...date, { id: newId, value: "" }]);
    }
  };

  const removeDate = (id) => {
    const updatedDates = date.filter((bed) => bed.id !== id);
    setBeds(updatedDates);
  };

  return (
    <div className="fs-6">
      <Row className="fs-6 align-items-center">
        <Col md="12" className="d-flex align-items-center my-3">
          <span className="fs-6 text-dark">{t('rates_q')}</span>
        </Col>
        <Col md="12" className="d-flex flex-wrap align-items-center my-3">
          {rates.map((rate, index) => (
            <div key={index} className="mx-3">
              <RadioInput id={rate.value} name="ratestype" label={rate.title} onChange={() => handleRates(rate.value)} value="PourTousLesJours" />
            </div>
          ))}
        </Col>
      </Row>

      {infos.rates === "PourTousLesJours" ? (
        <>
          <Row>
            <Col lg="8" className="my-2">
              <Input placeholder={t('base_rate')} type="number" />
            </Col>
            <Col lg="4" className="my-2">
              <select className="input-blue w-100 p-4 border">
                <option>01 {t('person')}</option>
              </select>
            </Col>
          </Row>
        </>
      ) : infos.rates === "ParSaison" ? (
        <Row>
          {seasons.map((season, index) => (
            <Col key={index} sm="6" md="3" lg="6" xl="3" className="my-2">
              <Input placeholder={season.title} type="number" />
            </Col>
          ))}
        </Row>
      ) : infos.rates === "ParMois" ? (
        <Row>
          {months.map((month, index) => (
            <Col key={index} sm="4" md="3" className="my-3">
              <Input placeholder={month.title} type="number" />
            </Col>
          ))}
        </Row>
      ) : infos.rates === "ParSemaine" ? (
        <Row>
          {week.map((day, index) => (
            <Col key={index} sm="4" md="3" lg="4" xl="3" className="my-3">
              <Input placeholder={day.title} type="number" />
            </Col>
          ))}
        </Row>
      ) : infos.rates === "ParDate" ? (
        <>
          {date.map((bed) => (
            <div className="my-3 d-flex flex-column" key={bed.id}>
              <Row className="align-items-center">
                <Col xs="12">
                  <Col xs="12">
                    <DateYear />
                  </Col>
                  <Row className="align-items-center">
                    <Col md="6" className="my-3">
                      <span className="text-dark">{t('base_rate')}</span>
                    </Col>
                    <Col md="6" className="my-3">
                      <Input placeholder={t('amount')} />
                    </Col>
                  </Row>
                </Col>
              </Row>

              {date.length > 1 && (
                <button className="bg-transparent text-danger mt-3 align-self-end" type="button" onClick={() => removeDate(bed.id)}>
                  <MinusCircle strokeWidth={1.5} /> {t('remove')}
                </button>
              )}
            </div>
          ))}
          {date.length < 10 && (
            <button className="bg-transparent text-blue" type="button" onClick={addDate}>
              <PlusCircle strokeWidth={1.5} /> {t('add')}
            </button>
          )}
        </>
      ) : (
        ""
      )}

      <Choice text={t('do_you_charges_for_extra_peoples')} onChange={handlePeople} selectedChoice={infos.people} />

      {infos.people === "yes" ? (
        <>
          <Row>
            <Col sm="4" className="my-3">
              <Select data={type} value={infos.type} handleChange={(e) => handleType(e.target.value)} />
            </Col>
            <Col sm="4" className="my-3">
              <Input type="number" placeholder={`Adultes ${infos.type === t('amount') ? "$" : "%"}`} />
            </Col>
            <Col sm="4" className="my-3">
              <Input type="number" placeholder={`Enfants ${infos.type === t('amount') ? "$" : "%"}`} />
            </Col>
          </Row>
        </>
      ) : (
        ""
      )}

      <hr className="border-secondary" />

      <ExtraPrices t={t} />
    </div>
  );
};

export default DetailsPricing;
