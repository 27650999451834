import React, { useState } from 'react';
import clients from "../../../data/clients.json"
import Users from './Users';
import Messages from './Messages';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import ChatHeader from './ChatHeader';
import SendingMessage from './SendingMessage';

const Details = ({ mode }) => {
    const { t } = useTranslation();

    const [users] = useState(clients.clients);
    const [selectedUser, setSelectedUser] = useState(users[0]);
    const [messages] = useState({
        1: [{ text: ['Bonjour !', 'Comment allez vous ?'], time: "11:05 PM" }, { text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.", time: "11:07 PM" }],
        2: [{ text: ['Bonjour !'], time: "07:23 AM" }]
    });

    const handleUserClick = (user) => {
        setSelectedUser(user);
    };

    return (
        <div className={`rounded-3 p-3 ${mode ? "bg-dark text-white" : "border bg-white text-dark"}`}>
            <ChatHeader selectedUser={selectedUser} t={t} />
            <Row>
                <Col sm="2" lg="4" xl="3" className='my-3'>
                    <Users t={t} users={users} handleUserClick={handleUserClick} selectedUser={selectedUser} messages={messages} />
                </Col>
                <Col sm="10" lg="8" xl="9" className='my-3'>
                    <Messages t={t} selectedUser={selectedUser} messages={messages} />
                </Col>
            </Row>
            <SendingMessage mode={mode} t={t} />
        </div>
    );
};

export default Details;
