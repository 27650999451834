import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ErrorPage() {

  const { t } = useTranslation();


  return (
    <section>
      <Row className="py-5">
        <Col md="12">
          <div className="d-flex flex-column justify-content-center align-items-center">
            <img style={{ maxWidth: "30%" }} src="https://isomorphic-furyroad.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fnot-found.6ff76223.png&w=640&q=75" alt="logo du site" loading="lazy" />
            <div className="fs-1 text-bold text-dark">{t('error_title')}</div>
          </div>
        </Col>
        <Col md="12">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <Link to="/">
              <button className="mt-3 btn-blue p-4 rounded-3 text-decoration-none">{t('back_to_home')}</button>
            </Link>
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default ErrorPage;
