import { ChevronUp, ChevronDown } from 'lucide-react'
import React from 'react'
import { useTranslation } from "react-i18next";

const Countries = ({ mode }) => {

    const data = [
        { id: 1, img: "https://mannatthemes.com/rizz/default/assets/images/flags/us_flag.jpg", sales: "8,567€", country: "United states", count: "25.8%", status: "up" },
        { id: 2, img: "https://mannatthemes.com/rizz/default/assets/images/flags/spain_flag.jpg", sales: "2,415€", country: "Spain", count: "6.2%", status: "down" },
        { id: 3, img: "https://mannatthemes.com/rizz/default/assets/images/flags/french_flag.jpg", sales: "7,987€", country: "France", count: "16.2%", status: "up" },
        { id: 4, img: "https://mannatthemes.com/rizz/default/assets/images/flags/germany_flag.jpg", sales: "12€", country: "Germany", count: "14.8%", status: "up" },
        { id: 5, img: "https://demos.pixinvent.com/vuexy-html-admin-template/assets/vendor/fonts/flags/1x1/in.svg", sales: "936€", country: "Inde", count: "11.9%", status: "up" },
    ]

    const { t } = useTranslation();

    return (
        <div className={`rounded p-3 h-100 ${mode ? 'bg-dark text-white' : 'border bg-white text-dark'}`}>
            <div className="fw-medium fs-5">{t('sales_by_country')}</div>
            <p className='fs-6 fw-light'>{t('monthly_sales_overview')}</p>
            <div className='mt-4'>
                {data.map(item => (
                    <div key={item.id} className='d-flex align-items-center justify-content-between my-4'>
                        <span className='d-flex'>
                            <img className='rounded-circle' src={item.img} width="30" height="30" alt={`Illustration du drapeau - ${item.country}`} />
                            <div className='mx-3'>
                                <div className='fs-6 fw-medium'>{item.sales}</div>
                                <div className='fs-6 fw-light tetx-secondary'>{item.country}</div>
                            </div>
                        </span>
                        {item.status === "up" ?
                            <span className="fs-6 text-green">
                                <ChevronUp size={20} strokeWidth={1.5} /> {item.count}
                            </span> :
                            <span className="fs-6 text-red">
                                <ChevronDown size={20} strokeWidth={1.5} /> {item.count}
                            </span>}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Countries
