import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import { AiOutlineMessage, AiOutlineThunderbolt } from "react-icons/ai";
import SelectedChoice from "../../components/forms/SelectedChoice";

const Booking = ({ t }) => {
  const [infos, setInfos] = useState({
    choice: "",
  });

  const handleChoice = (value) => {
    setInfos({ ...infos, choice: value });
  };

  const data = [
    { id: 1, title: t('instant_booking'), icon: <AiOutlineThunderbolt size={25} strokeWidth={1.2} />, value: "Réservation instantanée", caption: t('instant_booking_caption') },
    { id: 2, title: t('accept_requests'), icon: <AiOutlineMessage size={25} strokeWidth={1.2} />, value: "Accepter ou refuser les demandes", caption: t('accept_requests_caption') },
  ];

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="8" xl="6" className="my-3">
            <div className="bg-white">
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('booking_title')}</h2>
              <p className="fs-5 fw-light">{t('booking_caption')}</p>

              <SelectedChoice data={data} handleChoice={handleChoice} selectedChoice={infos.choice} />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Booking;
