import React from 'react'
import { Col, Row } from 'react-bootstrap'
import TimeInput from '../../../components/forms/TimeInput'
import Input from '../../../components/forms/Input'
const Stopover = ({ t }) => {
    return (
        <Row>
            <Col md="8" className="my-3">
                <div className="fs-6 text-dark">{t('stop_place')}</div>
                <div className="my-1">
                    <Input type="text"/>
                </div>
            </Col>
            <Col md="4" className="my-3">
                <div className="fs-6 text-dark">{t('stop_duration')}</div>
                <div className="my-1">
                    <TimeInput placeholder={t('duration')} />
                </div>
            </Col>
        </Row>
    )
}

export default Stopover
