import { ChevronRight } from 'lucide-react'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const Partner = ({ t }) => {

    const data = [
        { title: t('partner_title1'), caption: t('partner_caption1') },
        { title: t('partner_title2'), caption: t('partner_caption2') },
        { title: t('partner_title3'), caption: t('partner_caption3') },
        { title: t('partner_title4'), caption: t('partner_caption4') },
        { title: t('partner_title5'), caption: t('partner_caption5') },
        { title: t('partner_title6'), caption: t('partner_caption6') },
        { title: t('partner_title7'), caption: t('partner_caption7') },
        { title: t('partner_title8'), caption: t('partner_caption8') },
        { title: t('partner_title9'), caption: t('partner_caption9') },
        { title: t('partner_title10'), caption: t('partner_caption10') },
    ]

    return (

        <section className='bg-orange my-5 py-5'>
            <div className='container py-5'>
                <div className='fs-1 fw-medium' >{t('partner_title')}</div >
                <p className='fs-3 fw-light'>{t('partner_caption')}</p>

                <Row className='mt-5'>
                    {data.map(item => (
                        <Col key={item.title} sm="6" lg="4" className='my-4'>
                            <div className='h-100 d-flex'>
                                <span className='text-blue'><ChevronRight size={20} strokeWidth={1.5} /></span>
                                <span className='text-dark mx-3'>
                                    <div className='fs-5 fw-medium'>{item.title}</div>
                                    <div className='mt-3 fs-6 fw-light'>{item.caption}</div>
                                </span>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
        </section>
    )
}

export default Partner
