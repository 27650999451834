import Newrate from "./Newrate";
import Updatecancel from "./Updatecancel";
import Newfees from "./Newfees";
import Adjustedrate from "./Adjustedrate";
import Home from "./Home";
import BookingHotels from "./BookingHotels";
import Bookingdetails from "./Bookingdetails";
import Invoicedetails from "./Invoicedetails";
import Paymentglobal from "./Paymentglobal";
import Facturedetails from "./Facturedetails";
import Demanderpayment from "./Demanderpayment";

function Main(props) {

  const components = {
    home: <Home />,
    newrate: <Newrate />,
    updatecancel: <Updatecancel />,
    newfees: <Newfees />,
    adjustedrate: <Adjustedrate />,
    bookinghotels: <BookingHotels />,
    bookingdetails: <Bookingdetails />,
    invoicedetails: <Invoicedetails />,
    paymentglobal: <Paymentglobal />,
    facturedetails: <Facturedetails />,
    demanderpayment: <Demanderpayment />,
  };

  const Component = components[props.component];

  return Component || null;
}

export default Main;


