import { Row, Col } from "react-bootstrap";
import ValidityAndDuration from "../components/ValidityAndDuration";
import Cancel from "../components/Cancel";
import DateOneInput from "../../../components/forms/DateOneInput";

const Policy = ({ t }) => {

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('activityperso')}</h2>
              <p className="fs-5 fw-light">{t('activitypersoP')}</p>
              <div className="fs-6">
                <ValidityAndDuration />
                <div className="my-4">
                  <>
                    <div className="fs-5 text-dark">{t('PackagesDesDates')}</div>
                    <p className="fs-6 fw-light">{t('PackagesDesDatesP')}</p>
                    <Row className="fs-6">
                      <Col md="6" className="my-3">
                        <DateOneInput placeholder={t('debut')} />
                      </Col>

                      <Col md="6" className="my-3">
                        <DateOneInput placeholder={t('end')} />
                      </Col>
                    </Row>
                  </>
                </div>
                <Cancel />
              </div>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Policy;
