import { Row, Col } from "react-bootstrap";
import { useEffect, useReducer, useState } from "react";
import { CableCar, CalendarCheck2, Car, Home, Hotel, Plane, Ship, TrainFront, Utensils } from "lucide-react";
import { useTranslation } from "react-i18next";
import TopBanner from "../../components/TopBanner";
import SelectedChoice from "../../components/forms/SelectedChoice";
import { useNavigate } from "react-router-dom";
import Loader from "../../layout/Loader";

const initialState = {
  product: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_TYPE":
      localStorage.setItem("product", action.payload);
      return { ...state, product: action.payload };
    default:
      return state;
  }
};

const Structure = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showComponent, setShowComponent] = useState(false);

  useEffect(() => {
    const savedType = localStorage.getItem("product");
    if (savedType) {
      dispatch({ type: "SET_TYPE", payload: savedType });
    }
  }, []);

  const handleRadioChange = (value) => {
    dispatch({ type: "SET_TYPE", payload: value });
  };

  const handleNextClick = () => {
    setShowComponent(true);
    setTimeout(() => {
      navigate("/hosting");
    }, 2000);
  };

  const data = [
    { title: t('flight'), value: "Vol", icon: <Plane size={25} strokeWidth={1.2} />, caption: t('flight_caption') },
    { title: t('rental'), value: "Maison", icon: <Home size={25} strokeWidth={1.2} />, caption: t('rental_caption') },
    { title: t('hotel'), value: "Hotel", icon: <Hotel size={25} strokeWidth={1.2} />, caption: t('hotel_caption') },
    { title: t('activity'), value: "Activité", icon: <CableCar size={25} strokeWidth={1.2} />, caption: t('activity_caption') },
    { title: t('transport'), value: "Transport", icon: <TrainFront size={25} strokeWidth={1.2} />, caption: t('transport_caption') },
    { title: t('package'), value: "Package", icon: <Ship size={25} strokeWidth={1.2} />, caption: t('package_caption') },
    { title: t('car'), value: "Voiture", icon: <Car size={25} strokeWidth={1.2} />, caption: t('car_caption') },
    { title: t('restaurant'), value: "Restaurant", icon: <Utensils size={25} strokeWidth={1.2} />, caption: t('restaurant_caption') },
    { title: t('events'), value: "Événements", icon: <CalendarCheck2 size={25} strokeWidth={1.2} />, caption: t('events_caption') },
  ];

  return (
    <div className="w-100">
      {showComponent ? (
        <Loader />
      ) :

        <>
          <TopBanner step={1} />
          <section className="container position-relative form-transition w-100 h-100 pb-5 mb-5">
            <div>
              <Row className="justify-content-center">
                <Col lg="10" xl="8" className="my-3">
                  <div className="bg-white">
                    <h2 className="fs-3 lh-base fw-medium text-dark">{t('product_type_question')}</h2>
                    <p className="fs-5 fw-light">{t('product_type_question_caption')}</p>
                    <SelectedChoice data={data} handleChoice={handleRadioChange} selectedChoice={state.product} />
                  </div>
                </Col>
              </Row>
            </div>
          </section>

          {showComponent && (
            <Loader />
          )}

          <div className={`bg-white w-100 border-top`} style={{ position: "fixed", bottom: "0px", zIndex: "4" }}>
            <div className="container py-3">
              <Row className='justify-content-between align-items-center'>
                <Col xs="5">
                  <button type="button" className="bg-white fs-5 fw-bold text-decoration-underline text-dark">
                    {t('back')}
                  </button>
                </Col>
                <Col xs="5" sm="4" md="3" lg="2" className='d-flex justify-content-end'>
                  <button type="button" className="fs-6 btn-blue py-3 w-100 rounded-5 text-white" onClick={handleNextClick}>
                    {t('next')}
                  </button>
                </Col>
              </Row>
            </div>
          </div>
        </>

      }

    </div>
  );
};

export default Structure;
