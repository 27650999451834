import { Soup, Wine, Cast, Plug, Usb, LucideBaggageClaim, AirVent, Wifi, Accessibility, ThermometerSun, Bath, Gamepad2, PawPrint, VenetianMask, Library, BluetoothConnected } from "lucide-react";
import { Row, Col } from "react-bootstrap";
import Checkbox from "../../../components/forms/Checkbox";
import { useState } from "react";
import { PiFirstAidKitLight } from "react-icons/pi";

const Attributes = ({ t }) => {
  const [checkboxes, setCheckboxes] = useState([
    { id: 1, title: t('wifi'), isChecked: false, value: "Connexion Wi-Fi", icon: <Wifi strokeWidth={1.2} size={30} /> },
    { id: 2, title: t('screen'), isChecked: false, value: "Écrans", icon: <Cast strokeWidth={1.2} size={30} /> },
    { id: 3, title: t('plug'), isChecked: false, value: "Prises électriques", icon: <Plug strokeWidth={1.2} size={30} /> },
    { id: 4, title: t('usb'), isChecked: false, value: "Ports USB", icon: <Usb strokeWidth={1.2} size={30} /> },
    { id: 5, title: t('bluetooth'), isChecked: false, value: "Bluetooth", icon: <BluetoothConnected strokeWidth={1.2} size={30} /> },
    { id: 6, title: t('air_conditioning'), isChecked: false, value: "Climatisation", icon: <AirVent strokeWidth={1.2} size={30} /> },
    { id: 7, title: t('heating'), isChecked: false, value: "Chauffage", icon: <ThermometerSun strokeWidth={1.2} size={30} /> },
    { id: 8, title: t('bath'), isChecked: false, value: "Salle de bain", icon: <Bath strokeWidth={1.2} size={30} /> },

    { id: 9, title: t('luggage_space'), isChecked: false, value: "Compartiments à bagages", icon: <LucideBaggageClaim strokeWidth={1.2} size={30} /> },
    { id: 10, title: t('food'), isChecked: false, value: "Nourriture", icon: <Soup strokeWidth={1.2} size={30} /> },
    { id: 11, title: t('drinks'), isChecked: false, value: "Boissons", icon: <Wine strokeWidth={1.2} size={30} /> },

    { id: 12, title: t('animals_accepted'), isChecked: false, value: "Animaux acceptés", icon: <PawPrint strokeWidth={1.2} size={30} /> },
    { id: 13, title: t('wheelchair_accessible'), isChecked: false, value: "Accessible fauteuil roulant", icon: <Accessibility strokeWidth={1.2} size={30} /> },
    { id: 14, title: t('health_kit'), isChecked: false, value: "Kit de premiers secours", icon: <PiFirstAidKitLight strokeWidth={1.2} size={30} /> },
    { id: 15, title: t('sleep_mask'), isChecked: false, value: "Masques de sommeil", icon: <VenetianMask strokeWidth={1.2} size={30} /> },
    { id: 16, title: t('books'), isChecked: false, value: "Livres", icon: <Library strokeWidth={1.2} size={30} /> },
    { id: 17, title: t('entertainment'), isChecked: false, value: "Divertissement", icon: <Gamepad2 strokeWidth={1.2} size={30} /> },

  ]);

  const toggleCheckbox = (id) => {
    setCheckboxes((prevState) => prevState.map((checkbox) => (checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox)));
  };

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="8" xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('give_product_equip')}</h2>
              <p className="fs-5 fw-light">{t('product_equip_text')}</p>

              <div className="my-3">
                <Row>
                  {checkboxes.map((checkbox, index) => (
                    <Col key={index} xs="6" sm="4" md="4" className="my-2">
                      <Checkbox key={checkbox.id} id={checkbox.id} isChecked={checkbox.isChecked} value={checkbox.value} onChange={toggleCheckbox} title={checkbox.title} icon={checkbox.icon} />
                    </Col>
                  ))}
                </Row>
                {/* <div>
                  {getCheckedValues().map((value) => (
                    <p key={value}>{value}</p>
                  ))}
                </div> */}
              </div>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Attributes;
