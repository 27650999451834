import { changeLanguage } from "../lang/i18n";
import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Col, Row } from "react-bootstrap";
import { Languages } from "lucide-react";

const LangChanger = ({ location, t }) => {


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const lang = [
    { title: "English (US)", value: "us" },
    { title: "English (UK)", value: "gb" },
    { title: "Français", value: "fr" },
    { title: "Español", value: "es" },
    { title: "العربية", value: "sa" },
    { title: "Deutsch", value: "de" },
    { title: "Italiano", value: "it" },
    { title: "Polski", value: "pl" },
    { title: "Português", value: "pt" },
    { title: "简体中文", value: "cn" },
    { title: "Türkçe", value: "tr" },
    { title: "Nederlands", value: "nl" },
    { title: "日本語", value: "jp" },
    { title: "한국어", value: "kr" },
    { title: "Svenska", value: "se" },
    { title: "Русский", value: "ru" },
    { title: "Română", value: "ro" },
    { title: "shqiptare", value: "al" },
    { title: "Español (AR)", value: "ar" },
    { title: "Ελληνικά", value: "gr" },
    { title: "हिन्दी", value: "in" },
    { title: "Português (BR)", value: "br" },
    { title: "Čeština", value: "cz" },
    { title: "Dansk", value: "dk" },
    { title: "Suomi", value: "fi" },
    { title: "Hrvatski", value: "hr" },
    { title: "Íslenska", value: "is" },
    { title: "Norsk", value: "no" },
    { title: "Slovenščina", value: "si" },
    { title: "Slovenčina", value: "sk" },
    { title: "Tiếng Việt", value: "vn" },
    { title: "Lietuvių", value: "lt" },
    { title: "Eesti", value: "ee" },
    { title: "Українська", value: "ua" },
  ];

  const renderIconButton = (props, ref) => {
    return (
      <div onClick={handleShow} {...props} ref={ref} role="button">
        <Languages size={20} strokeWidth={1.3}/>
      </div>
    );
  };

  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
  };

  return (

    <>
      {renderIconButton()}
      <Modal size="xl" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><div className="text-dark fw-medium">{t('select_lang')}</div></Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="px-3">
          <Row>
            {lang.map((lang, index) => (
              <Col xs="6" sm="4" lg="3" xl="2" className="my-1">
                <a key={index} className="d-flex align-items-center p-3 text-dark lang-item rounded" href={location.pathname} onClick={() => handleLanguageChange(lang.value)}>
                  <img src={require(`../assets/flags/${lang.value}.svg`)} width="20%" height="20%" style={{ minHeight: "11px", minWidth: "16px", maxHeight: "11px", maxWidth: "16px" }} alt={`${lang.country} Flag `} />
                  <span className="mx-2 fs-6 fw-medium">
                    {lang.title}
                  </span>
                </a>
              </Col>
            ))}
          </Row>
        </div></Modal.Body>
      </Modal>
    </>
  )
}

export default LangChanger
