import StepComponent from "../../../components/StepComponent";
import Title from '../Title'
import Description from '../Description'
import Photos from '../Photos'
import Informations from '../Informations'
import Type from '../Type'
import Pricing from "../Pricing";
import Review from "../Review";
import Discount from "../Discount";
import Location from "../Location";
import HandleSteps from "../../../components/HandleSteps";
import Meals from "../restaurantComonents/Meals";
import Services from "../restaurantComonents/Services";
import WorkingHours from "../restaurantComonents/WorkingHours";

const Restaurant = ({ step, t, isLoading, handlePrevStep, handleNextStep }) => {

    return (

        <section className="position-relative py-5 mb-5 h-100">
            <form action="/">
                {step === 1 && <StepComponent step={1} />}

                {step === 2 && <Title />}
                {step === 3 && <Type t={t} />}
                {step === 4 && <Location t={t} />}
                {step === 5 && <Informations />}

                {step === 6 && <StepComponent step={2} />}

                {step === 7 && <Description />}
                {step === 8 && <Meals t={t} />}
                {step === 9 && <Services t={t} />}
                {step === 10 && <Photos />}

                {step === 11 && <StepComponent step={3} />}

                {step === 12 && <WorkingHours t={t} />}
                {step === 13 && <Pricing t={t} />}
                {step === 14 && <Discount t={t} />}
                {step === 15 && <Review t={t} />}
            </form>
            <HandleSteps isLoading={isLoading} totalSteps={15} step={step} prev={handlePrevStep} next={handleNextStep} sepratorOne={5} SeparatorTwo={10} />
        </section>
    );
};

export default Restaurant;
