import { Row, Col } from "react-bootstrap";
import { message, Upload } from 'antd';
import { PackagePlus } from "lucide-react";
const { Dragger } = Upload;

const props = {
    name: 'file',
    customRequest: async ({ file, onSuccess, onError }) => {
        const reader = new FileReader();

        reader.onload = () => {
            const base64 = reader.result;
            if (typeof base64 === 'string') {
                console.log('Image base64:', base64);
                localStorage.setItem('image', base64);
                message.success(`${file.name} file uploaded successfully.`);
                onSuccess();
            } else {
                message.error(`${file.name} file upload failed.`);
                onError();
            }
        };

        reader.onerror = () => {
            message.error(`${file.name} file upload failed.`);
            onError();
        };

        reader.readAsDataURL(file);
    },
};

const Booking = ({ t }) => {

    return (
        <section className="bg-white form-transition">
            <div className="container">
                <Row className="justify-content-center">
                    <Col lg="7" xl="6" className="my-3">
                        <h2 className="fs-3 lh-base fw-medium text-dark">{t('confirmation_booking')}</h2>
                        <p className="fs-5 fw-light">{t('confirmation_booking_caption')}</p>
                        <div className="my-4">
                            <Dragger {...props}>
                                <div
                                    style={{
                                        height: 450,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}>
                                    <div className="text-dark d-flex flex-column align-items-center">
                                        <PackagePlus size={60} strokeWidth={1.5} />
                                        <div className="fs-4 fw-bold my-2">{t('drag_file')}</div>
                                        <p className="fs-6">{t('drag_file_caption')}</p>
                                        <p className="fs-6 text-decoration-underline">{t('download_from_device')}</p>
                                    </div>
                                </div>
                            </Dragger>
                        </div>
                    </Col>
                </Row>

            </div>
        </section>
    );
};

export default Booking;

