import Informations from '../Informations'
import HandleSteps from "../../../components/HandleSteps";
import Class from '../flightComponents/Class';
import Attributes from '../flightComponents/FlightAtt';
import Type from '../Type';
import Process from '../flightComponents/Process';
import Pricing from '../Pricing';
import Booking from '../flightComponents/Booking';
import Discount from '../Discount';

const Flight = ({ step, t, isLoading, handlePrevStep, handleNextStep }) => {
    return (
        <section className="position-relative py-5 mb-5 h-100">
            <form action="/">
                {step === 1 && <Class />}
                {step === 2 && <Type t={t} />}
                {step === 3 && <Informations />}
                {step === 4 && <Attributes t={t} />}
                {step === 5 && <Process t={t} />}
                {step === 6 && <Pricing />}
                {step === 7 && <Discount t={t} />}
                {step === 8 && <Booking t={t} />}
            </form>
            <HandleSteps isLoading={isLoading} totalSteps={8} step={step} prev={handlePrevStep} next={handleNextStep} sepratorOne={-1} SeparatorTwo={-1} />
        </section>
    )
}

export default Flight
