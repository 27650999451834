import React from 'react'
import { useTranslation } from "react-i18next";
import Stats from './Stats';
import { Col, Row } from 'react-bootstrap';
import Countries from './Countries';
import Traffic from './Traffic';
import Devices from './Devices';
import Sessions from './Sessions';

const Clients = ({mode}) => {

    const { t } = useTranslation();

    return (
        <>
            <Stats mode={mode} t={t} />
            <Row>
                <Col lg="4" className='my-2'>
                    <Countries mode={mode} t={t} />
                </Col>
                <Col lg="8" className='my-2'>
                    <Traffic mode={mode} t={t} />
                </Col>
                <Col lg="6" className='my-2'>
                    <Sessions mode={mode} t={t} />
                </Col>
                <Col lg="6" className='my-2'>
                    <Devices mode={mode} t={t} />
                </Col>
            </Row>
        </>
    )
}

export default Clients
