import { Row, Col } from "react-bootstrap";
import TextArea from "../../../components/forms/Textarea";
import { useState } from "react";
import { Info } from "lucide-react";
import { useTranslation } from "react-i18next";
import React from 'react';
import Select from "../../../components/forms/Select";

const Class = () => {

    const { t } = useTranslation();

    const [title, setTitle] = useState("");

    const handleTitle = (event) => {
        setTitle(event.target.value);
    };

    const data = [
        { title: t('economy'), value: "Économie" },
        { title: t('business_class'), value: "Affaire" },
        { title: t('first_class'), value: "Premiére" },
    ]

    return (
        <section className="bg-white position-relative form-transition">
            <div className="container">
                <Row className="justify-content-center">
                    <Col lg="7" xl="6" className="my-3">
                        <h2 className="fs-3 fw-medium text-dark">{t('flight_number_title')}</h2>
                        <p className="fs-5 fw-light">{t('flight_number_caption')}</p>
                        <div className="mt-5">
                            <div className="text-dark">{t('class')}</div>
                            <div className="my-1">
                                <Select data={data} />
                            </div>
                            <div className="my-3">

                            </div>

                            <div className="my-3">
                                <TextArea value={title} handleChange={handleTitle} rows={8} maxlength={250} placeholder={t('additionnal_informations')} />
                            </div>
                        </div>
                        <div className="text-secondary fw-medium">{title.length}/250</div>
                        {title.length > 31 && (
                            <div className="mt-3 text-danger d-flex align-items-center">
                                <Info size={20} />
                                <span className="mx-2">32 {t('max_chars')}</span>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Class;
