import { CopyPlus, Dot, EyeOff, Trash2 } from "lucide-react";
import { Col, Row } from "react-bootstrap"
import { HiOutlineEllipsisVertical } from "react-icons/hi2";
import NavDropdown from 'react-bootstrap/NavDropdown';
import Pagination from "../../../components/Pagination";
import { Progress } from 'antd';

const ListingFlex = ({mode, t, data }) => {
    const renderItem = (item, index) => (
        <a key={index} href={item.type === "hotel" ? "/myhotel" : `/myactivity/${item.id}`} className={`my-3 rounded d-block px-3 py-2 ${mode ? "bg-dark text-white" : "border bg-white text-dark"}`}>
            <Row className="justify-content-between align-items-center">
                <Col xs="4" md="1" className="my-2">
                    <img className="rounded-circle" src={item.avatar} alt="Illustration du client" width="45" height="45" />
                </Col>
                <Col xs="4" md="3" className="my-2">
                    <div className='fs-6'>{item.title}</div>
                </Col>
                <Col xs="4" md="2" className="my-2">
                    <div className='fs-6 fw-light'>{item.date}</div>
                </Col>

                <Col md="2" className="my-2 d-none d-md-block">
                    <Progress percent={item.progress} size="small" strokeColor="#8077EC" />
                </Col>

                <Col xs="4" md="1" className="my-2">
                    <div role="button" title={item.status}>
                        <span className={`${item.status === "Réservable" ? "text-green" : item.status === "En cours" ? "text-orange" : "text-red"}`}><Dot size={45} /></span>
                    </div>
                </Col>
                <Col xs="4" md="1" className="my-2">
                    <div className='fs-6 fw-light'>{item.amount}€</div>
                </Col>
                <Col xs="4" md="1" className="product-dropdown d-flex justify-content-end my-2">
                    <NavDropdown

                        title={<div role="button"><HiOutlineEllipsisVertical size={25} /></div>}
                        menuVariant="light"
                    >
                        <div className="d-flex align-items-center px-3 py-2">
                            <span><CopyPlus size={15} strokeWidth={1.5} /> </span><span className="mx-2">{t('clone')}</span>
                        </div>
                        <div className="d-flex align-items-center px-3 py-2">
                            <span><EyeOff size={15} strokeWidth={1.5} /> </span><span className="mx-2">{t('disable')}</span>
                        </div>
                        <NavDropdown.Divider />
                        <div className="d-flex align-items-center px-3 py-2">
                            <span className="text-red"><Trash2 size={15} strokeWidth={1.5} /> </span><span className="mx-2">{t('delete')}</span>
                        </div>
                    </NavDropdown>
                </Col>
            </Row>
        </a>
    );

    return (
        <Pagination data={data} renderItems={(items) => (<>{items.map(renderItem)}</>)} itemsPerPage={7} />
    );
};

export default ListingFlex;

