import Select from "../../../components/forms/Select";
import Input from "../../../components/forms/Input";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const MeetingPoint = (props) => {

  const { t } = useTranslation();

  const [infos, setInfos] = useState({
    dropOff: "",
  });

  const handleDropOff = (e) => {
    setInfos({ ...infos, dropOff: e.target.value });
  };

  const time = [
    { title: t('not_relevant_for_activity'), value: "0" },
    { title: "5 minutes", value: "5" },
    { title: "10 minutes", value: "10" },
    { title: "15 minutes", value: "15" },
    { title: "20 minutes", value: "20" },
    { title: "25 minutes", value: "25" },
    { title: "30 minutes", value: "30" },
  ];

  const dropOffData = [
    { title: t('not_relevant_for_activity'), value: "AuMêmeEndroit" },
    { title: t('in_different_place'), value: "ÀunEndroitDifférent" },
    { title: t('without_depot_service'), value: "PasDeServiceDeDépose" },
  ];

  return (

    <>
      <div className="my-4">
        <>
          <div className="fs-5 text-dark">{t('meeting_point')}</div>
          <p className="fs-6 fw-light">{t('activityrdvP')}</p>
          <div className="mt-3">
            <Input type="text" placeholder={t('meeting_point_address')} />
          </div>
        </>
      </div>

      <div className="my-4">
        <>
          <div className="fs-5 text-dark">{t('activityarrivees')}</div>
          <p className="fs-6 fw-light">{t('activityarriveesp')}</p>
          <div className="mt-3 ">
            <Select data={time} />
          </div>
        </>
      </div>

      <div className="my-4">
        <>
          <div className="fs-5 text-dark">{t('depot_place')}</div>
          <p className="fs-6 fw-light">{t('depot_place_caption')}</p>
          <div className="mt-3 ">
            <Select data={dropOffData} handleChange={handleDropOff} />
          </div>
          {infos.dropOff === "ÀunEndroitDifférent" && (
            <div className="my-3 fs-6">
              <span className="fs-5 text-dark mt-3">{t('depot_adress')}</span>
              <p className="fs-6 fw-light">{t('customers_depot_place')}</p>
              <Input placeholder={t('add_depot_place')} />
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default MeetingPoint;
