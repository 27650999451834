import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import { CheckCircle2 } from "lucide-react";
import CheckInput from "../../../components/forms/CheckInput";

const Schedule = ({ t }) => {
  const daysOfWeek = [t('mon'), t('tue'), t('wed'), t('thur'), t('fri'), t('sat'), t('sun')];

  const [inputs, setInputs] = useState(
    daysOfWeek.map(() => [{ open: 0, close: 0, Ouvert24heures: false, RestaurantFermé: false }])
  );

  const handleInputChange = (dayIndex, inputIndex, field, event) => {
    const newInputs = [...inputs];
    newInputs[dayIndex][inputIndex][field] = parseInt(event.target.value);
    setInputs(newInputs);
  };

  const handleCheckboxChange = (dayIndex, inputIndex, checkbox) => {
    const newInputs = [...inputs];
    newInputs[dayIndex][inputIndex][checkbox] = !newInputs[dayIndex][inputIndex][checkbox];
    setInputs(newInputs);
  };

  const applyToAllDays = (dayIndex) => {
    const newInputs = inputs.map(() => inputs[dayIndex].map((hour) => ({ ...hour })));
    setInputs(newInputs);
  };

  const selectRender = ({ value, onChange, disabled }) => (
    <select className="p-4 input-blue border w-100" value={value} onChange={onChange} disabled={disabled}>
      {Array.from({ length: 24 }, (_, i) => i).map((hourOption) => (
        <option key={hourOption} value={hourOption}>
          {hourOption}:00
        </option>
      ))}
    </select>
  );
  return (
    <section className="bg-white form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="10" xl="8" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('RestauHeures')}</h2>
              <p className="fs-5 fw-light">{t('RestauHeuresP')}</p>

              {daysOfWeek.map((day, dayIndex) => (
                <div className="border-bottom py-3" key={dayIndex}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-dark fw-bold mt-3 fs-5">{day}</div>
                  </div>
                  <div className="fs-6">
                    {inputs[dayIndex].map((hour, inputIndex) => {
                      const isDisabled = hour.Ouvert24heures || hour.RestaurantFermé;
                      return (
                        <div key={inputIndex}>
                          <Row className="my-3">
                            <Col md="4" className="my-3">
                              <div className="text-dark">{t('RestauHO')}</div>
                              <div className="mt-1">
                                {selectRender({
                                  value: hour.open,
                                  onChange: (event) => handleInputChange(dayIndex, inputIndex, "open", event),
                                  disabled: isDisabled,
                                })}
                              </div>
                            </Col>

                            <Col md="4" className="my-3">
                              <div className="text-dark">{t('RestauHF')}</div>
                              <div className="mt-1">
                                {selectRender({
                                  value: hour.close,
                                  onChange: (event) => handleInputChange(dayIndex, inputIndex, "close", event),
                                  disabled: isDisabled,
                                })}
                              </div>
                            </Col>

                            <Col md="4" className="my-3 d-flex flex-column justify-content-end">
                              <div className="my-2">
                                <CheckInput
                                  id={`Ouvert24heures${dayIndex}${inputIndex}`}
                                  label={t('RestauO24')}
                                  checked={hour.Ouvert24heures}
                                  onChange={() => handleCheckboxChange(dayIndex, inputIndex, "Ouvert24heures")}
                                />
                              </div>
                              <div className="mt-2">
                                <CheckInput
                                  id={`RestaurantFermé${dayIndex}${inputIndex}`}
                                  label={t('closed')}
                                  checked={hour.RestaurantFermé}
                                  onChange={() => handleCheckboxChange(dayIndex, inputIndex, "RestaurantFermé")}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                    <div className="my-3 text-blue">
                      <span role="button" className="text-blue fs-6" onClick={() => applyToAllDays(dayIndex)}>
                        <CheckCircle2 size={25} strokeWidth={1.3} /> {t('activityheuresAPP')}
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Schedule;
