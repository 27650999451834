import { useEffect, useState } from "react";
import RatesRestaurant from "../restaurantComonents/Rates";
import RatesPerDemand from "./RatesPerDemand";
import RatesPerAge from './RatesPerAge'
import Days from "./Days";
import Seasons from "../packageComponents/Seasons";

const RatesOption = ({ t, price }) => {

    const [product, setproduct] = useState("");

    useEffect(() => {
        const savedType = localStorage.getItem("product");
        if (savedType) {
            setproduct(savedType);
        }
    }, []);

    const configurations = {
        Restaurant: [
            { content: <RatesRestaurant price={price} t={t} /> },
        ],

        Maison: [
            { content: <RatesPerDemand price={price} t={t} /> },
        ],

        Activité: [
            { content: <RatesPerAge t={t} content={<Days t={t}/>} /> },
        ],

        Transport: [
            { content: <RatesPerAge t={t} content={<Days t={t}/>} /> },
        ],

        Voiture: [
            { content: <RatesPerDemand price={price} t={t} /> },
        ],

        Package: [
            { content: <RatesPerAge t={t} content={<Seasons t={t}/>} /> },
        ]
    };

    const data = configurations[product] || [];

    return (
        <div className="w-100">
            {data.map((choice, index) => (

                <div key={index}>{choice.content}</div>
            ))}
        </div>
    );
};

export default RatesOption;
