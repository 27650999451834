import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { Facebook, Instagram, Linkedin, Youtube } from 'lucide-react'
import ChartItem from '../components/ChartItem'

const Stats = ({ mode, t }) => {
    const data = [
        { title: t('facebook'), count: "279.9k", icon: <Facebook size={25} strokeWidth={1.5} />, data: [45, 54, 38, 56, 24, 65, 31], color: "#0966FF", status: "up", growth: 6 },
        { title: t('instagram'), count: "223.4k", icon: <Instagram size={25} strokeWidth={1.5} />, data: [12, 15, 13, 16, 18, 19, 11], color: "#F60C61", status: "up", growth: 3 },
        { title: t('linkedin'), count: "67.3k", icon: <Linkedin size={25} strokeWidth={1.5} />, data: [42, 64, 48, 26, 44, 55, 41], color: "#0B66C2", status: "down", growth: 2 },
        { title: t('youtube'), count: "124.7k", icon: <Youtube size={25} strokeWidth={1.5} />, data: [131, 102, 223, 45, 145, 148, 166], color: "#FF0600", status: "up", growth: 16 },

    ]

    return (
        <Row>
            {data.map(item => (
                <Col md="6" lg="3" xl="3" className='my-2'>
                    <ChartItem mode={mode} icon={item.icon} title={item.title} count={item.count} color={item.color} data={item.data} status={item.status} growth={item.growth} />
                </Col>
            ))}
        </Row>
    )
}

export default Stats
