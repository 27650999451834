import { DatePicker } from 'antd';



const DateOneInput = ({ placeholder, value, onChange,mode }) => {

  const disabledDate = current => {
    return current && current < new Date(new Date().setHours(0, 0, 0, 0));
  };

  return (
    <DatePicker className='input-blue w-100 p-4 border rounded-0' mode={mode} disabledDate={disabledDate} value={value} onChange={onChange} placeholder={placeholder} />
  );
};

export default DateOneInput;
