import { Loader } from 'rsuite';
import { useTranslation } from "react-i18next";
import { Col, Row } from 'react-bootstrap';

const HandleSteps = ({ isLoading, step, prev, next, totalSteps, sepratorOne, SeparatorTwo }) => {
  const { t } = useTranslation();
  const progress = (step / totalSteps) * 100;
  const left = (sepratorOne / totalSteps) * 100;
  const right = (SeparatorTwo / totalSteps) * 100;

  return (
    <div className={`bg-white w-100 ${step === 0 && "border-top"}`} style={{ position: "fixed", bottom: "0px", zIndex: "4" }}>
      <div className={`progress-bar-container w-100 ${step === 0 && "d-none"}`} style={{ height: "5px", overflow: "hidden", background: "#DDDDDD" }}>
        <div className="bg-blue h-100" style={{ width: `${progress}%`, transition: "width 0.6s ease" }}>
          <div style={{ position: "absolute", left: `${left}%`, width: "10px", height: "10%", backgroundColor: "white" }} />
          <div style={{ position: "absolute", left: `${right}%`, width: "10px", height: "10%", backgroundColor: "white" }} />
        </div>
      </div>
      <div className="container py-3">
        <Row className='justify-content-between align-items-center'>
          <Col xs="5">
            <button type="button" onClick={prev} className="bg-white fs-5 fw-bold text-decoration-underline text-dark" disabled={step < 2}>
              {t('back')}
            </button>
          </Col>
          <Col xs="5" sm="4" md="3" lg="2" className='d-flex justify-content-end'>
            {step < totalSteps && (
              <button onClick={next} type="button" className="fs-6 btn-blue py-3 w-100 rounded-5" style={{ opacity: isLoading ? "0.6" : "" }} disabled={isLoading}>
                {isLoading ? <Loader size="xs" /> : t('next')}
              </button>
            )}

            {step === totalSteps && (
              <a href='/' type="submit" className="fs-6 btn-blue p-3 w-100 rounded-5 text-white" style={{ opacity: isLoading ? "0.6" : "" }} disabled={isLoading}>
                {isLoading ? <Loader size="xs" /> : t('confirm')}
              </a>
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default HandleSteps;
