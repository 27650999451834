import { Row, Col } from 'react-bootstrap'
import Select from '../../../components/forms/Select'
import { useState } from 'react'
import RadioInput from '../../../components/forms/RadioInput'
import CheckInput from '../../../components/forms/CheckInput'
import Schedules from '../../../components/Schedules'
import { useTranslation } from "react-i18next";

const Reception = () => {
    const { t } = useTranslation();

    const [infos, setInfos] = useState({
        opening: false,
        reception: "everyday",
    });

    const handleReception = (value) => {
        setInfos({ ...infos, reception: value });
    };

    const handleOpening = () => {
        setInfos({ ...infos, opening: !infos.opening });
    };

    const week = [
        { title: t('saturday'), value: "Samedi" },
        { title: t('sunday'), value: "Dimanche" },
        { title: t('monday'), value: "Lundi" },
        { title: t('tuesday'), value: "Mardi" },
        { title: t('wednesday'), value: "Mercredi" },
        { title: t('thursday'), value: "Jeudi" },
        { title: t('friday'), value: "Vendredi" }
    ]

    return (
        <>
            <Row className='fs-6 align-items-center'>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <span className='fs-6 fw-medium text-dark'>{t('recption_schudles')}</span>
                </Col>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <div className='mx-3'>
                        <RadioInput label={t('every_day')} id="every_day" name="reception" value="everyday" onChange={() => handleReception('everyday')} />
                    </div>

                    <div className='mx-3'>
                        <RadioInput label={t('some_days')} id="some_days" name="reception" value="somedays" onChange={() => handleReception('somedays')} />
                    </div>
                </Col>
            </Row>

            {infos.reception === "somedays" && (
                <Row className='fs-6 align-items-center'>
                    <Col md="6" className='d-flex align-items-center my-3'>
                        <div className='d-flex flex-column w-100'>
                            <span className='fs-6'>{t('start_day')}</span>
                            <div className='my-1'>
                                <Select data={week} />
                            </div>
                        </div>
                    </Col>
                    <Col md="6" className='d-flex align-items-center my-3'>
                        <div className='d-flex flex-column w-100'>
                            <span className='fs-6'>{t('end_day')}</span>
                            <div className='my-1'>
                                <Select data={week} />
                            </div>
                        </div>
                    </Col>
                </Row>
            )}

            {!infos.opening && (
                <Schedules />
            )}

            <div className='my-3'>
                <CheckInput label={t('open_24h_aday')} id="recepetion24h" value={infos.opening} onChange={handleOpening} />
            </div>
        </>
    )
}

export default Reception