import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const Chart = ({ t }) => {
    const [series] = useState([
        {
            name: t('sales'),
            group: 'sales',
            data: [423, 1451, 211, 2334, 627]
        },
        {
            name: t('earnings'),
            group: 'sales',
            data: [88, 522, 36, 989,131]
        },
    ]);

    const [options] = useState({
        chart: {
            type: 'bar',
            height: 280,
            stacked: true,
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        dataLabels: {
            formatter: (val) => {
                return val + '€'
            }
        },
        plotOptions: {
            bar: {
                horizontal: false
            }
        },
        xaxis: {
            categories: [
                '00:00 - 08-00',
                '08:00 - 12:00',
                '12:00 - 17:00',
                '17:00 - 20:00',
                '20:00 - 00:00'
            ]
        },
        fill: {
            opacity: 1
        },
        colors: ['#8077ec', '#16A571'],
        yaxis: {
            labels: {
                formatter: (val) => {
                    return val + '€'
                }
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left'
        }
    });

    return (
        <ReactApexChart options={options} series={series} type="bar" height={360} />
    );
}

export default Chart;
