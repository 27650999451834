import { Col, Row } from 'react-bootstrap';
import Select from './forms/Select';
import supplierData from '../data/globaldata.json'
import { useTranslation } from "react-i18next";

const Schedules = () => {
    const { t } = useTranslation();
    return (
        <Row className='fs-6 align-items-center'>
            <Col md="6" className='d-flex align-items-center my-3'>
                <div className='d-flex flex-column w-100'>
                    <span className='fs-6'>{t('from')}</span>
                    <div className='my-1'>
                        <Select data={supplierData.schedules} />
                    </div>
                </div>
            </Col>
            <Col md="6" className='d-flex align-items-center my-3'>
                <div className='d-flex flex-column w-100'>
                    <span className='fs-6'>{t('to')}</span>
                    <div className='my-1'>
                        <Select data={supplierData.schedules} />
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default Schedules