import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { AppWindow, Coins } from "lucide-react";
import Logo from "../../components/Logo";

const Main = ({ handleShow}) => {

    const { t } = useTranslation();
    return (
        <main>
            <Row className="align-items-center justify-content-between py-3">
                <Col xs="4" sm="3" md="2">
                    <Logo width={180} />
                </Col>
                <Col xs="8" md="6" lg="5" xl="4">
                    <Row>
                        <Col xs="6" className="my-3">
                            <button className="btn-outline-blue border-blue p-3 w-100" onClick={() => handleShow(true)}>
                                {t('log_in')}
                            </button>
                        </Col>
                        <Col xs="6" className="my-3">
                            <button className="btn-blue border-blue p-3 w-100" onClick={() => handleShow(true)}>
                                {t('register')}
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row className="align-items-center pt-5">
                <Col lg="6">
                    <div>
                        <h1 className="fs-0" dangerouslySetInnerHTML={{ __html: t("main_title") }}></h1>
                        <div className="fs-3 fw-medium my-3">{t("subtitle")}</div>
                        <p className="fs-6 fw-light my-4">{t("intro")}</p>

                        <div className="my-5 fs-6 d-flex align-items-start">
                            <span className="p-3 rounded-circle bg-secondary-blue text-blue">
                                <AppWindow strokeWidth={1.5} />
                            </span>
                            <span className="mx-3">
                                <div className="fw-medium fs-5">{t("create_product")}</div>
                                <p className="fw-light mt-2">{t("create_product_text")}</p>
                            </span>
                        </div>
                        <div className="my-5 fs-6 d-flex align-items-start">
                            <span className="p-3 rounded-circle bg-secondary-blue text-blue">
                                <Coins strokeWidth={1.5} />
                            </span>
                            <span className="mx-3">
                                <div className="fw-medium fs-5">{t("make_benefits")}</div>
                                <p className="fw-light mt-2">{t("make_benefits_text")}</p>
                            </span>
                        </div>
                    </div>
                </Col>
                <Col lg="6">
                    <Row>
                        <Col xl="6" className="my-2">
                            <img className="rounded" width="100%" height={500} alt="Illusatration de logement" src="https://creativelayers.net/themes/gotrip-html/img/masthead/2/1.png" />
                        </Col>

                        <Col xl="6">
                            <Row>
                                <Col xl="12" className="my-2 d-none d-xl-block">
                                    <img className="rounded" width="100%" height={240} alt="Illusatration de logement" src="https://dtkudil.wpenginepowered.com/wp-content/uploads/2014/01/gallery1-640x480.jpg" />
                                </Col>
                                <Col xl="12" className="my-2 d-none d-xl-block">
                                    <img className="rounded" width="100%" height={240} alt="Illusatration de logement" src="https://creativelayers.net/themes/gotrip-html/img/masthead/2/3.png" />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </main>
    );
};

export default Main;
