import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Autocomplete from '../../../components/forms/Autocomplete'
import airports from '../../../data/airports.json'
import airlines from '../../../data/airlines.json'
import DateOneInput from '../../../components/forms/DateOneInput'
import TimeInput from '../../../components/forms/TimeInput'
import Input from '../../../components/forms/Input'

const Trip = ({ t }) => {
    return (
        <>
            <Row className="fs-6 ">
                <Col md="6" className="my-3">
                    <div className="fs-6 text-dark">{t('airline_name')}</div>
                    <div className="my-1">
                        <Autocomplete data={airlines.airlines} labelKey="name" id="FlightAIrline" />
                    </div>
                </Col>
                <Col md="6" className="my-3">
                    <div className="fs-6 text-dark">{t('flight_number')}</div>
                    <div className="my-1">
                        <Input type="text" />
                    </div>
                </Col>
                <Col md="6" className="my-3">
                    <div className="fs-6 text-dark">{t('departing_airport')}</div>
                    <div className="my-1">
                        <Autocomplete data={airports.airports} id="DepartAirport" labelKey="name" />
                    </div>
                </Col>
                <Col md="6" className="my-3">
                    <div className="text-dark">{t('arrival_airport')}</div>
                    <div className="my-1">
                        <Autocomplete data={airports.airports} id="ArrivalAirport" labelKey="name" />
                    </div>
                </Col>
            </Row>

            <Row className="fs-6 ">
                <Col md="6" className="my-3">
                    <div className="fs-6 text-dark">{t('departure_date')}</div>
                    <Row>
                        <Col md="6" className='my-1'>
                            <DateOneInput placeholder={t('date')} />
                        </Col>
                        <Col md="6" className='my-1'>
                            <TimeInput placeholder={t('hour')} />
                        </Col>
                    </Row>
                </Col>
                <Col md="6" className="my-3">
                    <div className="text-dark">{t('ho_act_reservation_det_date_arrive')}</div>
                    <Row>
                        <Col md="6" className='my-1'>
                            <DateOneInput placeholder={t('date')} />
                        </Col>
                        <Col md="6" className='my-1'>
                            <TimeInput placeholder={t('hour')} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Trip
