import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ChevronDown, Clock, X } from "lucide-react";
import CheckInput from "../../../components/forms/CheckInput";

const Schedule = () => {
    const { t } = useTranslation();

    const daysOfWeek = [t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('sunday'), t('saturday')];
    const [inputs, setInputs] = useState(Array(daysOfWeek.length).fill([]));
    const [activeDays, setActiveDays] = useState(Array(daysOfWeek.length).fill(false));

    const toggleDay = (dayIndex) => {
        setActiveDays(prevActiveDays => {
            const newActiveDays = [...prevActiveDays];
            newActiveDays[dayIndex] = !newActiveDays[dayIndex];
            return newActiveDays;
        });
    };

    const addInput = (dayIndex) => {
        setInputs(prevInputs => {
            const newInputs = [...prevInputs];
            newInputs[dayIndex] = [...newInputs[dayIndex], 0];
            return newInputs;
        });
    };

    const removeInput = (dayIndex, inputIndex) => {
        setInputs(prevInputs => {
            const newInputs = [...prevInputs];
            newInputs[dayIndex].splice(inputIndex, 1);
            return newInputs;
        });
    };

    const handleInputChange = (dayIndex, inputIndex, event) => {
        const newValue = parseInt(event.target.value, 10);
        setInputs(prevInputs => {
            const newInputs = [...prevInputs];
            newInputs[dayIndex][inputIndex] = newValue;
            return newInputs;
        });
    };

    const copyToAll = (dayIndex) => {
        setInputs(prevInputs => {
            const copiedInputs = [...prevInputs];
            const inputToCopy = copiedInputs[dayIndex];
            copiedInputs.forEach((_, index) => {
                if (index !== dayIndex) {
                    copiedInputs[index] = [...inputToCopy];
                }
            });
            return copiedInputs;
        });
    };

    return (
        <div className="my-4">
            <h2 className="fs-5 fw-medium text-dark">{t('activityheures')}</h2>
            <p className="fs-6 fw-light">{t('activityheuresp')}</p>
            <div className="my-3">
                {daysOfWeek.map((day, dayIndex) => (
                    <div key={dayIndex} className="d-flex align-items-center my-3">
                        <CheckInput checked={activeDays[dayIndex]} onChange={() => toggleDay(dayIndex)} id={`day-${dayIndex}`} label={day} />
                    </div>
                ))}

                {daysOfWeek.map((day, dayIndex) => (
                    activeDays[dayIndex] && (
                        <div className="border-bottom py-3" key={dayIndex}>
                            <div className="text-dark fw-medium mt-3 fs-6">{day}</div>
                            <div className="d-flex flex-wrap align-items-center fs-6">
                                {inputs[dayIndex].map((hour, inputIndex) => (
                                    <div className="my-3 me-3" key={inputIndex}>
                                        <select
                                            className="p-3 input-blue"
                                            value={hour}
                                            onChange={(event) => handleInputChange(dayIndex, inputIndex, event)}
                                        >
                                            <option value={0}>Choisir une heure</option>
                                            {Array.from({ length: 24 }, (_, i) => i).map(hourOption => (
                                                <option key={hourOption} value={hourOption}>
                                                    {hourOption}:00
                                                </option>
                                            ))}
                                        </select>
                                        <button
                                            className="mx-2 text-danger bg-white"
                                            onClick={() => removeInput(dayIndex, inputIndex)}
                                            type="button"
                                        >
                                            <X />
                                        </button>
                                    </div>
                                ))}
                                <div className="my-3 text-blue">
                                    <button
                                        className="mx-2 bg-white"
                                        onClick={() => addInput(dayIndex)}
                                        type="button"
                                    >
                                        <Clock strokeWidth={1.3} /> {t('activityheuresAdd')}
                                    </button>
                                    <button
                                        className="mx-2 bg-white"
                                        onClick={() => copyToAll(dayIndex)}
                                        type="button"
                                    >
                                        <ChevronDown strokeWidth={1.3} /> {t('activityheuresAPP')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                ))}
            </div>

        </div>
    );
};

export default Schedule;
