import Home from './Home'

function Main(props) {

  const components = {
    home: <Home />,
  };

  const Component = components[props.component];

  return Component || null;
}

export default Main;

