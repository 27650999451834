import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = ({ t, mode }) => {
  const series = [
    {
      name: t('costumers'),
      data: [331, 20, 428, 241, 212, 18],
    },
    {
      name: t('bookings'),
      data: [313, 478, 349, 563, 433, 107],
    },
    {
      name: t('networks'),
      data: [428, 411, 389, 521, 687, 97],
    },
  ];

  const options = {
    chart: {
      type: 'line',
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: 'straight',
      width: 2
    },
    tooltip: {
      enabled: true,
      shared: false,
      followCursor: true,
    },
    xaxis: {
      categories: [t('january'), t('february'), t('march'), t('april'), t('may'), t('june'), t('july'), t('august')],
      labels: {
        show: true,
        style: {
          colors: mode ? "white" : "#222222",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    grid: {
      show: false
    },
    dataLabels: {
      enabled: false,
    },
    colors: ['#FF5733', "#16A571", "#8077EC"],
  };

  return (

    <div className={`rounded h-100 d-flex flex-column justify-content-between ${mode ? 'bg-dark text-white' : 'border bg-white text-dark'}`} style={{ overflow: "hidden" }}>
      <div className="p-3">
        <div className="fs-5 fw-medium">{t("overview")}</div>
        <p className="fs-6 text-secondary">{t('trimester_performances')}</p>
        <Chart options={options} series={series} type="line" height={360} />
      </div>

    </div>
  );
};

export default BarChart;
