import { Col, Row } from "react-bootstrap";
import Select from '../../../components/forms/Select'
import { useState } from "react";
import RadioInput from '../../../components/forms/RadioInput'
import { useTranslation } from "react-i18next";

const DepositAge = () => {
    const { t } = useTranslation();
    const [infos, setInfos] = useState({
        age: "AllAges",
    });

    const handleAge = (value) => {
        setInfos({ ...infos, age: value });
    };

    const ageLength = Array.from({ length: 30 }, (_, index) => ({
        title: `${index + 1}`,
        value: index + 1,
    }));

    return (
        <div className="my-3">
            <span className="fs-6">{t('deposit_apply_to_travelers')}</span>
            <div className="d-flex fs-6">
                <div className="mx-2">
                    <RadioInput name="DepositAge" label={t('of_all_age')} id="AllAges" value="AllAges" onChange={() => handleAge("AllAges")} />
                </div>
                <div className="mx-2">
                    <RadioInput name="DepositAge" label={t('under_a_certain_age')} id="CertainAge" value="CertainAge" onChange={() => handleAge("CertainAge")} />
                </div>
            </div>

            {infos.age === "CertainAge" && (
                <Row className='fs-5 align-items-center'>
                    <Col md="6" className='my-3'>
                        <span className='fs-6 text-dark'>{t('only_for_travelers')}</span>
                    </Col>
                    <Col md="6" className='my-3'>
                        <Select data={ageLength} />
                    </Col>
                </Row>
            )}

        </div>
    )
}

export default DepositAge