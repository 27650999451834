import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Select from '../../../components/forms/Select';
import { useState } from 'react';
import RoomOption from './RoomOption'
import CheckInput from '../../../components/forms/CheckInput';
import ExtraCharge from "../../../components/ExtraCharge"
import { useTranslation } from "react-i18next";

const BathroomFacilities = () => {

    const { t } = useTranslation();

    const [infos, setInfos] = useState({
        shower: 'BaignoireSeule',
        fees:false
    });

    const handleFees = () => {
        setInfos({ ...infos, fees: !infos.fees });
    };

    const handleShower = (value) => {
        setInfos({ ...infos, shower: value });
    };

    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    const data = [
        { title: t('bathrobes'), value: "Peignoirs" },
        { title: t('bathrobes_for_childs'), value: "PeignoirsPourEnfants" },
        { title: t('bath_essentials'), value: "EssentielsSalleDeBain" },
        { title: t('hair_dryer'), value: "SècheCheveux" },
        { title: t('bidet'), value: "Bidet" },
        { title: t('outdoor_bath'), value: "DoucheExtérieure" }
    ]

    const essentials = [
        { title: t('free_toiletries'), value: "ArticlesToiletteGratuits" },
        { title: t('soap'), value: "Savon" },
        { title: t('shampoo'), value: "Shampoing" },
        { title: t('toilet_paper'), value: "PapierToilette" }
    ]

    const bath = [
        { title: t('single_bathtub'), value: "BaignoireSeule" },
        { title: t('bathtub_or_shower'), value: "BaignoireOuDouche" },
        { title: t('separated_bathtub_and_shower'), value: "DoucheEtBaignoireSéparées" },
        { title: t('shower_only'), value: "DoucheSeule" },
        { title: t('bathtub_shower_combination'), value: "CombinaisonDoucheBaignoire" }
    ]

    const bathtub = [
        { title: t('standard_bathtub'), value: "BaignoireStandard" },
        { title: t('deep_bathtub'), value: "BaignoireRelaxanteProfonde" },
        { title: t('jetted_tub'), value: "BaignoireAjets" },
        { title: t('spring_water_bath'), value: "BainEauDeSource" }
    ]

    const availability = [
        { title: t('available'), value: "Disponible" },
        { title: t('non_available'), value: "NonDisponible" },
    ]

    const fees = [
        { title: t('per_person'), value: "ParPersonne" },
        { title: t('pet_stay'), value: "ParHébérgement" },
    ]


    return (
        <div className='fs-6'>
            <div className='my-3'>
                <span className='fs-6'>{t('availability_of_towels')}</span>
                <RoomOption />
                <div className='px-3'>
                    <CheckInput id="FraisServiettes" label={t('towels_fees')} onChange={handleFees} />
                </div>

                {infos.fees && (
                    <Row className='align-items-center'>
                        <Col md="9" className='my-3'>
                            <ExtraCharge />
                        </Col>
                        <Col md="3" className='my-3'>
                            <Select data={fees} />
                        </Col>
                    </Row>
                )}
                
            </div>

            {data.map((check, index) => (
                <div key={index} className='my-3'>
                    <>
                        <CheckInput id={check.value} label={check.title} value={check.value} checked={checkedItems[check.value] || false} onChange={() => handleCheckboxChange(check.value)} />
                    </>

                    {checkedItems[check.value] && (
                        <>
                            {["Peignoirs", "PeignoirsPourEnfants", "ChauffageAuSol", "SècheCheveux", "Bidet", "DoucheExtérieure"].includes(check.value) && (
                                <RoomOption />
                            )}

                            {check.value === 'EssentielsSalleDeBain' && (
                                <>
                                    <RoomOption />
                                    {essentials.map((check, index) => (
                                        <div key={index} className='px-3 my-3'>
                                            <CheckInput id={check.value} label={check.title} value={check.value} />
                                        </div>
                                    ))}
                                </>
                            )}

                        </>
                    )}


                </div>
            ))}

            <Row className='align-items-center'>
                <Col md="6" className='my-2'>
                    <span className='fs-6 text-dark'>{t('toothbrush_and_toothpaste')}</span>
                </Col>
                <Col md="6" className='my-2'>
                    <Select data={availability} />
                </Col>
            </Row>

            <Row className='align-items-center'>
                <Col md="6" className='my-2'>
                    <span className='fs-6 text-dark'>{t('bath_configuration')} </span>
                </Col>
                <Col md="6" className='my-2'>
                    <Select value={infos.shower} handleChange={(e) => handleShower(e.target.value)} data={bath} />
                </Col>
            </Row>

            <RoomOption />

            {infos.shower === "DoucheSeule" || infos.shower === "BaignoireOuDouche" || infos.shower === "DoucheEtBaignoireSéparées" || infos.shower === "CombinaisonDoucheBaignoire" ? 
                <>
                    <span className='fs-5 text-blue'>{t('showers')}</span>

                    <div className='my-3'>
                        <CheckInput id="hydromassage" label={t('hydro_shower_head')} />
                    </div>

                    <div className='my-3'>
                        <CheckInput id="raineffect" label={t('rain_effect_shower_head')} />
                    </div>

                </> : ""
            }

            {infos.shower === "BaignoireSeule" || infos.shower === "BaignoireOuDouche" || infos.shower === "DoucheEtBaignoireSéparées" || infos.shower === "CombinaisonDoucheBaignoire" ? 
                <>
                    <Row className='align-items-center'>
                        <Col md="6" className='my-2'>
                            <span className='fs-6 text-dark'>{t('bathtub_type')}</span>
                        </Col>
                        <Col md="6" className='my-2'>
                            <Select data={bathtub} />
                        </Col>
                    </Row>
                </> : ''

            }

        </div>
    )
}

export default BathroomFacilities