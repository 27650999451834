import { ArrowLeftCircle, ArrowRightCircle, ChevronFirst, ChevronLast } from "lucide-react";
import { Col, Row } from "react-bootstrap";
import { useState } from "react";

const Pagination = ({ data, itemsPerPage, renderItems }) => {

    const buttonsToShow = 3;

    const [currentPage, setCurrentPage] = useState(0);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const startIndex = currentPage * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const displayedItems = data.slice(startIndex, endIndex);

    const pageCount = Math.ceil(data.length / itemsPerPage);

    let startPage = currentPage - Math.floor(buttonsToShow / 2);
    startPage = Math.max(startPage, 0);
    let endPage = startPage + buttonsToShow;
    endPage = Math.min(endPage, pageCount);

    const paginationButtons = [];
    for (let i = startPage; i < endPage; i++) {
        paginationButtons.push(
            <button
                key={i}
                onClick={() => handlePageChange(i)}
                className={`rounded p-3 px-4 ${i === currentPage ? "border-blue bg-blue text-white" : "border bg-white text-dark"}`}
            >
                {i + 1}
            </button>
        );
    }

    const goToPrevPage = () => {
        if (currentPage > 0) {
            handlePageChange(currentPage - 1);
        }
    };

    const goToFirstPage = () => {
        handlePageChange(0);
    };

    const goToLastPage = () => {
        handlePageChange(pageCount - 1);
    };

    const goToNextPage = () => {
        if (currentPage < pageCount - 1) {
            handlePageChange(currentPage + 1);
        }
    };

    return (
        <div className="w-100">
            {renderItems(displayedItems)}

            <Row className="d-flex justify-content-center my-5">
                <Col className="d-flex justify-content-between" xl="8">
                    <button className='rounded d-flex align-items-center justify-content-center border bg-white p-3 px-4' onClick={goToFirstPage} disabled={currentPage === 0}>
                        <ChevronFirst size={15} />
                    </button>
                    <button disabled={currentPage === 0} className='d-none rounded border bg-white d-sm-flex align-items-center justify-content-center p-3' onClick={goToPrevPage}><span className="text-blue"><ArrowLeftCircle size={20} strokeWidth={1.5} /></span><span className="mx-1">Previous</span></button>
                    {paginationButtons}
                    <button className='d-none rounded border bg-white d-sm-flex align-items-center justify-content-center p-3' onClick={goToNextPage} disabled={currentPage === pageCount - 1}><span className="text-blue"><ArrowRightCircle size={20} strokeWidth={1.5} /></span><span className="mx-1">Suivant</span></button>
                    <button className='rounded d-flex align-items-center justify-content-center border bg-white p-3 px-4' onClick={goToLastPage} disabled={currentPage === pageCount - 1}>
                        <ChevronLast size={15} />
                    </button>
                </Col>
            </Row>
        </div>
    )
}

export default Pagination