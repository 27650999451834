import React from 'react';
import DetailsRoom from './DetailsRoom';
import { ChevronsDown, ChevronsUp } from 'lucide-react';
import { PanelGroup } from 'rsuite';

const RoomConfig = ({ displayedPanels, panels, togglePanels, t }) => {
    return (
        <>
            <div className="my-4">
                <span className="text-blue fs-5 d-flex align-items-center">{t('room_information')}</span>
                <p className="fs-6 mt-1 fw-light">{t('room_information_text')}</p>
                <DetailsRoom />
            </div>

            <hr className="border-secondary my-5"/>

            <PanelGroup accordion defaultActiveKey={1} className="my-4 rounded-0">
                <div className="py-3">
                    <span className="text-blue fs-5 d-flex align-items-center">{t('room_services_and_equipments')}</span>
                    <p className="fs-6 fw-light mt-1">{t('room_services_text')}</p>
                </div>

                <div>
                    {displayedPanels
                        ? panels.map((element, index) => (
                            <div className='border my-3' key={index}>
                                {element}
                            </div>
                        ))
                        : panels.slice(0, 3).map((element, index) => (
                            <div className='border my-3' key={index}>
                                {element}
                            </div>
                        ))}
                    {panels.length > 3 && (
                        <div className="fs-5 text-blue m-3" type="button" onClick={togglePanels}>
                            {displayedPanels ? (
                                <span>
                                    {t('show_less')} <ChevronsUp size={20} />
                                </span>
                            ) : (
                                <span>
                                    {t('show_more')}
                                    <ChevronsDown size={20} />
                                </span>
                            )}
                        </div>
                    )}
                </div>
            </PanelGroup>
        </>
    );
};

export default RoomConfig;
