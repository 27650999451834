import { useState } from "react";
import { useTranslation } from "react-i18next";
import TaxesDetails from "./TaxesDetails";
import TagInput from "../../../components/forms/TagInput";
import supplierData from "../../../data/globaldata.json";
import CheckInput from "../../../components/forms/CheckInput";


const Taxes = () => {

  const { t } = useTranslation();


  const [infos, setInfo] = useState({
    taxe: "no",
  });

  const handleCreditCard = () => {
    setInfo({ ...infos, creditCard: !infos.creditCard });
  };

  const handleCash = () => {
    setInfo({ ...infos, cash: !infos.cash });
  };

  const handleOnlyCash = () => {
    setInfo({ ...infos, onlyCash: !infos.onlyCash });
  };

  const cards = [
    { title: "Cartes de débit", value: "CartesDébit" },
    { title: "JCB International", value: "JCBInternational" },
    { title: "Visa", value: "Visa" },
    { title: "Perspectives", value: "Perspectives" },
    { title: "Mastercard", value: "Mastercard" },
    { title: "Carte Blanche", value: "CarteBlanche" },
    { title: "American Express", value: "AmericanExpress" },
    { title: "UnionPay", value: "UnionPay" },
    { title: "Diners Club", value: "DinersClub" },
  ];



  return (

    <>
      <div className="my-4">
        <span className="fs-5 text-dark">{t('lang_spoken_in_hotel')}</span>
        <p className="fs-6 fw-light">{t('add_lang')}</p>
        <div className="mt-2">
          <TagInput list="hotelLang" data={supplierData.lang} />
        </div>
      </div>

      <div className="my-4">
        <span className="fs-5 text-dark">{t('payment_methods_accepted_in_hotel')}</span>
        <div className="fs-6">
          <div className="my-3">
            <CheckInput id="creditcard" checked={infos.creditCard} label={t('credit_cards')} onChange={handleCreditCard} disabled={infos.onlyCash} />
          </div>

          {infos.creditCard && (
            <div className="px-3">
              <>
                <span>{t('cards_accepted')}</span>
                {cards.map((check, index) => (
                  <div key={index} className="my-3">
                    <CheckInput id={check.value} label={check.title} />
                  </div>
                ))}
              </>
              <>
                <span>{t('other_options')}</span>
                <div className="my-3">
                  <CheckInput id="PaiementsMensualités" label={t('payment_of_monthly_installments')} />
                </div>
              </>
            </div>
          )}

          <div className="my-3">
            <CheckInput id="species" checked={infos.cash} label={t('species')} onChange={handleCash} />
          </div>

          {infos.cash && (
            <div className="px-3">
              <CheckInput id="onlycash" checked={infos.onlyCash} label={t('only_caash_accepted')} onChange={handleOnlyCash} disabled={infos.creditCard} />
            </div>
          )}
        </div>
      </div>

      <TaxesDetails />
    </>
  );
};

export default Taxes;
