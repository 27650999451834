import React from 'react'
import Input from '../../../components/forms/Input'
import { Monitor, Smartphone } from 'lucide-react'
import { Col, Row } from 'react-bootstrap'

const Security = ({ t, InputSection }) => {
    return (
        <>
            <InputSection
                title={t('password')}
                description={t('current_password')}
                input={
                    <Input type="password" />
                }
            />

            <hr className="border-secondary my-4" />

            <InputSection
                title={t('new_password')}
                description={t('set_new_password')}
                input={
                    <Input type="password" />
                }
            />

            <hr className="border-secondary my-4" />

            <InputSection
                title={t('password')}
                description={t('passwod_confirmation')}
                input={
                    <Input type="password" />
                }
            />

            <hr className="border-secondary my-4" />

            <>
                <div className='fs-5 fw-bold text-dark'>{t('where_youre_log_in')}</div>
                <p>{t('where_youre_log_in_caption')}</p>
                <Row>
                    <Col sm="6" className='my-3'>
                        <div className='d-flex align-items-center'>
                            <span><Smartphone /></span>
                            <span className='mx-3'>
                                <div className='fs-6 fw-medium text-dark'>Redmi note 11 Pro max</div>
                                <div className='fw-light'>Paris, France 22 Jan at 4:20pm</div>
                            </span>
                        </div>
                    </Col>
                    <Col sm="6" className='d-flex justify-content-end my-3'>
                        <span role='button' className='text-green text-end w-100'>Déconnecter l'appareil</span>
                    </Col>
                </Row>

                <Row>
                    <Col sm="6" className='my-3'>
                        <div className='d-flex align-items-center'>
                            <span><Monitor/></span>
                            <span className='mx-3'>
                                <div className='fs-6 fw-medium text-dark'>2018 Macbook Pro 15-inch</div>
                                <div className='fw-light'>Madrid, Spain 24 Mar at 8:15am</div>
                            </span>
                        </div>
                    </Col>
                    <Col sm="6" className='d-flex justify-content-end my-3'>
                        <span role='button' className='text-green text-end w-100'>Déconnecter l'appareil</span>
                    </Col>
                </Row>

            </>
        </>

    )
}

export default Security
