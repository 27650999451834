import { Row, Col } from "react-bootstrap";
import { CalendarCheck2, FileCheck, PencilLine } from "lucide-react";
import React, { useState } from 'react';
import { Rate } from 'antd';
import { CiHeart, CiZoomIn, CiRepeat } from "react-icons/ci";

const Review = ({ t }) => {


  const title = sessionStorage.getItem('title');
  const price = parseFloat(sessionStorage.getItem('price')) || 10;
  const image = localStorage.getItem('image');


  const data = [
    { id: 1, title: t('confirm_your_informations'), content: t('confirm_your_informations_text'), icon: <FileCheck size={30} strokeWidth={1.3} /> },
    { id: 2, title: t('configure_availability'), content: t('configure_availability_text'), icon: <CalendarCheck2 size={30} strokeWidth={1.3} /> },
    { id: 3, title: t('set_parameters'), content: t('set_parameters_text'), icon: <PencilLine size={30} strokeWidth={1.3} /> },
  ];

  const [isHovered, setIsHovered] = useState(false);

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col xl="9" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('review_title')}</h2>

              <p className="fs-5 fw-light">{t('review_caption')}</p>

              <div className="mt-4">
                <Row className="justify-content-between">
                  <Col md="5" lg="5" className="my-3">
                    <div
                      className='border position-relative rounded-3' style={{ overflow: "hidden" }}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      <img src={image ? image : "https://a0.muscache.com/im/pictures/miso/Hosting-705583331237049946/original/9100977f-bd36-431c-be8c-c7ff3e0a2fb4.jpeg?im_w=720"} alt="Illustration du produit" width="100%" height="260" />
                      <div className='p-3 d-flex flex-column align-items-center'>
                        <div className='text-dark fs-5'>{title ? title : "Titre de votre annonce"}</div>
                        <div className='fw-light d-flex align-items-center'>From <span className='fs-5 secondary-text fw-medium mx-2'>{price}€</span></div>
                        <div className='mt-2'>
                          <Rate disabled allowHalf defaultValue={5} />
                        </div>
                      </div>
                      <div className='w-100 h-100 position-absolute top-0 left-0 d-flex flex-column justify-content-between align-items-center' style={{ background: "#FFFFFF80", transition: "0.5s", opacity: isHovered ? "1" : "0" }}>
                        <div className='d-flex justify-content-center w-100 mt-5 py-5'>
                          <span className='bg-white rounded-circle d-flex justify-content-center align-items-center m-2' style={{ width: "50px", height: "50px" }}><CiRepeat size={20} /></span>
                          <span className='bg-white rounded-circle d-flex justify-content-center align-items-center m-2' style={{ width: "50px", height: "50px" }}><CiHeart size={20} /></span>
                          <span className='bg-white rounded-circle d-flex justify-content-center align-items-center m-2' style={{ width: "50px", height: "50px" }}><CiZoomIn size={20} /></span>
                        </div>

                        <div className='pb-3'>
                          <button className="btn-orange p-3 px-5 rounded-5">{t('view_details')}</button>
                        </div>
                      </div>

                    </div>

                  </Col>
                  <Col md="7" lg="7" className="my-3">
                    <div className="text-dark px-1 px-lg-5">
                      <span className="fw-medium fs-4">{t('and_next')}</span>
                      <div className="d-flex flex-column justify-content-between mt-3">
                        {data.map((item) => (
                          <div key={item.id} className="my-3 d-flex">
                            <span>{item.icon}</span>
                            <span className="mx-3">
                              <div className="fs-5">{item.title}</div>
                              <p className="fs-6 fw-light">{item.content}</p>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Review;
