const Select = ({ id, name, value, handleChange, data }) => {
  return (
    <>
      <select name={name} value={value} id={id} onChange={handleChange} className="input-blue w-100 p-4 border bg-white">
        {data.map((option, index) => (
          <option value={option.value} key={index} className="fw-light">
            {option.title}
          </option>
        ))}
      </select>
    </>
  );
};

export default Select;
