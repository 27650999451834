
const Autocomplete = ({ data, id, placeholder, labelKey }) => {
    return (
        <>
            <input className="border p-4 input-blue w-100 fw-light" list={id} placeholder={placeholder} />
            <datalist id={id}>
                {data.map(item => (
                    <option value={item[labelKey]} />
                ))}
            </datalist>
        </>
    )
}

export default Autocomplete
