import Choice from "../../../components/Choice";
import { useState } from "react";
import Reception from "./Reception";
import Communication from "./Communication";
import Arrival from "./Arrival";
import Depart from "./Depart";
import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const DetailsArrival = () => {
  const { t } = useTranslation();

  const [infos, setInfos] = useState({
    host: "no",
    reception: "no",
    sign: "no",
    mobile: "",
    age: false,
  });

  const handleReception = (value) => {
    setInfos({ ...infos, reception: value });
  };

  const handleHost = (value) => {
    setInfos({ ...infos, host: value });
  };

  const age = Array.from({ length: 11 }, (_, index) => ({
    title: `${15 + index}`,
    value: 15 + index,
  }));

  return (
    <>
      <>
        <span className="fw-light fs-6 d-flex align-items-center">{t('arrival_options')}</span>
        <div className="my-3 fs-6">
          <Choice text={t('reception_in_your_stay')} onChange={handleReception} selectedChoice={infos.reception} />
          {infos.reception === "yes" && <Reception />}

          {infos.reception !== "yes" && (
            <div className="my-3">
              <CheckInput id="RéceptionVertuelle" label={t('vertual_reception')} info={t('virtual_assistance')} />
            </div>
          )}
        </div>
      </>

      <div className="fs-6 px-3">
        <Choice text={t('host_welcoming_travelers')} onChange={handleHost} selectedChoice={infos.host} />
      </div>

      <div className="my-3 fs-6 px-3">
        <Communication />
      </div>

      <hr className="border-secondary my-3" />

      <div className="my-3 fs-6 px-3">
        <Arrival />
      </div>

      <hr className="border-secondary my-3" />

      <div className="my-3 fs-6 px-3">
        <Depart />
      </div>

      <hr className="border-secondary my-3" />

      <div className="my-3 fs-6 p-3">
        <>
          <span className="text-dark">{t('age_restrictions')}</span>
          <Row className="align-items-center">
            <Col md="6" className="d-flex align-items-center my-3">
              <span className="text-dark">{t('min_age_for_register')}</span>
            </Col>
            <Col md="6" className="d-flex align-items-center my-3">
              <Select data={age} />
            </Col>
          </Row>
        </>
      </div>
    </>
  );
};

export default DetailsArrival;
