import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import Choice from "../../../components/Choice";
import Deposit from "../hotelComponents/Deposit";
import Cleaning from "./Cleaning";
import Cancel from "../components/Cancel";
import { useTranslation } from "react-i18next";
import Taxes from "../hotelComponents/Taxes";

const Policy = () => {
  const { t } = useTranslation();

  const [infos, setInfo] = useState({
    creditCard: false,
    cash: false,
    onlyCash: false,
    deposit: "no",
    cleaning: "no",
  });


  const handleDeposit = (value) => {
    setInfo({ ...infos, deposit: value });
  };

  const handleCleaning = (value) => {
    setInfo({ ...infos, cleaning: value });
  };


  return (
    <section className="container form-transition">
      <Row className="justify-content-center">
        <Col lg="10" xl="8" className="my-3">
          <>
            <div className="fs-3 text-dark">{t('activityperso')}</div>
            <p className="fs-5 fw-light">{t('activitypersoP')}</p>

            <div className="my-4">
              <Choice text={t('ask_for_deposit')} onChange={handleDeposit} selectedChoice={infos.deposit} />

              {infos.deposit === "yes" && <Deposit />}
            </div>

            <div className="my-4">
              <Choice text={t('charge_cleaning_fees')} onChange={handleCleaning} selectedChoice={infos.cleaning} />

              {infos.cleaning === "yes" && <Cleaning />}
            </div>

            <Cancel />
            <Taxes/>
          </>
        </Col>
      </Row>
    </section>
  );
};

export default Policy;
