import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CloudDrizzle, CloudFog, CloudHail, CloudLightning, CloudRain, CloudSnow, CloudSun, CloudSunRain, Cloudy, Haze, Snowflake, Sun, Thermometer, Zap } from 'lucide-react';

const WeatherComponent = ({ t, mode }) => {

  const [weather, setWeather] = useState(null);
  const [error, setError] = useState(null);

  const getWeatherInfo = (code) => {
    switch (code) {
      case 0:
        return { title: "Dégagé", description: "Ciel clair, beau temps.", icon: <Sun size={40} strokeWidth={1.5} /> };
      case 1:
        return { title: "Partiellement nuageux", description: "Nuages épars, soleil présent.", icon: <CloudSun size={40} strokeWidth={1.5} /> };
      case 2:
        return { title: "Nuageux", description: "Ciel couvert, lumière tamisée.", icon: <Cloudy size={40} strokeWidth={1.5} /> };
      case 3:
        return { title: "Brumeux", description: "Visibilité réduite, atmosphère douce.", icon: <Haze size={40} strokeWidth={1.5} /> };
      case 45:
        return { title: "Brouillard", description: "Brouillard dense, prudence recommandée.", icon: <CloudFog size={40} strokeWidth={1.5} /> };
      case 47:
        return { title: "Orage", description: "Orages en cours, risque de foudre.", icon: <Zap size={40} strokeWidth={1.5} /> };
      case 61:
        return { title: "Pluie faible", description: "Petites pluies, parapluie conseillé.", icon: <CloudDrizzle size={40} strokeWidth={1.5} /> };
      case 63:
        return { title: "Pluie modérée", description: "Pluie continue, ambiance humide.", icon: <CloudHail size={40} strokeWidth={1.5} /> };
      case 65:
        return { title: "Pluie forte", description: "Fortes pluies, conditions difficiles.", icon: <CloudRain size={40} strokeWidth={1.5} /> };
      case 66:
        return { title: "Neige faible", description: "Petites chutes de neige, paysage doux.", icon: <Snowflake size={40} strokeWidth={1.5} /> };
      case 67:
        return { title: "Neige forte", description: "Fortes chutes de neige, route glissante.", icon: <Snowflake size={40} strokeWidth={1.5} /> };
      case 71:
        return { title: "Averses de pluie", description: "Pluie intermittente, préparez-vous à être mouillé.", icon: <CloudSunRain size={40} strokeWidth={1.5} /> };
      case 73:
        return { title: "Averses de neige", description: "Neige intermittente, précautions à prendre.", icon: <CloudSnow size={40} strokeWidth={1.5} /> };
      case 80:
        return { title: "Orages violents", description: "Orages intenses, risque élevé de foudre.", icon: <CloudLightning size={40} strokeWidth={1.5} /> };
      case 81:
        return { title: "Orages isolés", description: "Orages localisés, attention recommandée.", icon: <CloudLightning size={40} strokeWidth={1.5} /> };
      case 82:
        return { title: "Orages violents", description: "Orages intenses, conditions extrêmes.", icon: <CloudLightning size={40} strokeWidth={1.5} /> };
      case 95:
        return { title: "Orages avec pluie", description: "Orages accompagnés de pluie, visibilité réduite.", icon: <CloudLightning size={40} strokeWidth={1.5} /> };
      case 96:
        return { title: "Orages avec neige", description: "Orages neigeux, conditions extrêmes.", icon: <Zap size={40} strokeWidth={1.5} /> };
      case 99:
        return { title: "Orages avec grêle", description: "Orages avec grêle, protection nécessaire.", icon: <CloudLightning size={40} strokeWidth={1.5} /> };
      default:
        return { title: "Conditions inconnues", description: "Aucune donnée disponible", icon: <Thermometer size={40} strokeWidth={1.5} /> };
    }
  };

  useEffect(() => {
    const fetchWeather = async () => {
      try {
        const response = await axios.get(
          'https://api.open-meteo.com/v1/forecast?latitude=48.8566&longitude=2.3522&current_weather=true'
        );
        const currentWeather = response.data.current_weather;
        const weatherInfo = getWeatherInfo(currentWeather.weathercode);
        setWeather({ ...currentWeather, ...weatherInfo });
      } catch (err) {
        setError(err.message);
      }
    };

    fetchWeather();
  }, []);

  if (error) {
    return <div className='text-danger'>Erreur : {error}</div>;
  }

  if (!weather) {
    return <div>Chargement...</div>;
  }

  return (
    <div className={`p-3 py-4 h-100 rounded ${mode ? 'bg-dark text-white' : 'border bg-white text-dark'}`}>
      <div className='fs-6 fw-light text-secondary'>{t('weather')}</div>
      <div className='d-flex align-items-center'>
        <span className='text-blue'>{weather.icon}</span>
        <span className='fs-1 fw-bold mx-2'>{weather.temperature}°C</span>
      </div>
      <div className='fs-5 mt-3 fw-medium'>{weather.title}</div>
      <div className='fs-6 fw-light text-secondary'>{weather.description}</div>
    </div>
  );
};

export default WeatherComponent;
