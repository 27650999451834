import { Col, Modal, Row } from "react-bootstrap";
import Chart from "./overview/Chart";
import BestProduct from "./overview/BestProduct";
import Stats from "./overview/Stats";
import products from '../../data/products.json'
import CountriesBooks from "./overview/CountriesBooks";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import TopInfos from "./overview/TopInfos";
import Goals from "./components/Goals";


const Board = ({ boutonActif, handleClick, mode }) => {

    const [deleteProductModal, setDeleteProductModal] = useState(false);
    const { t } = useTranslation();


    return (
        <>

            <TopInfos mode={mode} />
            <Stats products={products.products} boutonActif={boutonActif} handleClick={handleClick} mode={mode} />

            <Row>
                <Col lg="6" xl="8" className="my-2" >
                    <Chart mode={mode} t={t} />
                </Col>
                <Col lg="6" xl="4" className="my-2">
                    <BestProduct mode={mode} products={products.products} />
                </Col>

                <Col xl="6" className="my-2">
                    <CountriesBooks mode={mode} />
                </Col>
                <Col xl="6" className="my-2">
                    <Goals mode={mode} t={t} />
                </Col>
            </Row>

            <Modal show={deleteProductModal} onHide={() => (setDeleteProductModal(false))}>
                <Modal.Body className="text-dark"><p className="text-dark fs-5 fw-light py-4">{t('delete_product')}</p></Modal.Body>
                <Modal.Footer>
                    <button className="p-3 btn-outline-blue" onClick={() => (setDeleteProductModal(false))}>
                        {t('cancel')}
                    </button>
                    <button className="p-3 btn-blue" onClick={() => (setDeleteProductModal(false))}>
                        {t('confirm')}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Board
