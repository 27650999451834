import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Personnal from "./Personnal";
import Security from "./Security";
import { Col, Row } from "react-bootstrap";
import Billing from "./Billing";

const Details = ({ mode }) => {
  const { t } = useTranslation();

  const [ActiveButton, setActiveButton] = useState('personnal')

  const handleActiveButton = (value) => {
    setActiveButton(value)
  }

  const InputSection = ({ title, description, input }) => (
    <Row className="align-items-center my-3">
      <Col lg="6" className="my-2">
        <div className="fs-6 text-dark fw-medium">{title}</div>
        <p className="fw-light">{description}</p>
      </Col>
      <Col lg="6" className="my-2">{input}</Col>
    </Row>
  );


  const buttons = [
    { title: t('personnal_informations'), value: "personnal", component: <Personnal InputSection={InputSection} /> },
    { title: t('connection_and_security'), value: "security", component: <Security InputSection={InputSection} t={t} /> },
    { title: t('billing_and_payment'), value: "billing", component: <Billing InputSection={InputSection} t={t} /> },
  ]

  return (
    <div className={`py-3 ${mode ? "text-white" : "text-dark"}`}>
      <div className="fs-3 fw-bold">{t("settings")}</div>
      <p className="my-1 fs-6 tetx-secondary fw-light">{t("settings_text")}</p>

      <div className="my-4">

        {buttons.map(button => (
          <span role="button" style={{ borderBottom: `${ActiveButton === button.value ? "2px solid black" : ""}` }} className="py-3 fs-6 me-5" onClick={() => { handleActiveButton(button.value) }}>{button.title}</span>
        ))}

        <div className="mt-4">
          {buttons.map(button => (
            <div className="py-1">
              {ActiveButton === button.value && (
                button.component
              )}
            </div>
          ))}
        </div>

      </div>
    </div>
  );
};

export default Details;
