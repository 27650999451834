import { Sidebar, Sidenav, Nav } from "rsuite";
import { useState } from "react";
import Header from "./Header";
import Logo from "../components/Logo"
import React from 'react'

const Home = ({ separator, data, boutonActif, handleClick,mode }) => {
    const [expand] = useState(false);

    return (
        <div className="p-3 d-flex">
            <Sidebar style={{ display: "flex", flexDirection: "column", height: "96vh" }} width={expand ? 260 : 56} collapsible className={`rounded-3 ${mode ? 'bg-dark text-white' : 'border bg-white text-dark'}`}>
                <Sidenav expanded={expand} defaultOpenKeys={["3"]} appearance="white" className="h-100">
                    <Sidenav.Body className="h-100">
                        <div className="d-flex flex-column justify-content-between h-100">

                            <Nav>
                                <div className='w-100 p-3'>
                                    <Logo width={100} />
                                    <hr className="border-secondary " />
                                </div>

                                {data.slice(0, separator).map((item) => (
                                    <>
                                        {item.type === "button" ? (
                                            <Nav.Item key={item.id} className={`fs-6 fw-light border-0 ${boutonActif === item.value ? "text-blue" : ""}`} eventKey={item.id} icon={item.icon} onClick={() => handleClick(item.value)}>
                                                {item.title}
                                            </Nav.Item>
                                        ) : (
                                            <Nav.Menu eventKey={item.id} trigger="hover" title={item.title} icon={item.icon} placement="rightStart" className="fs-6 fw-light text-dark border-0">
                                                {item.buttons.map((button) => (
                                                    <Nav.Item key={button.id} className={`${boutonActif === button.value ? "text-blue" : ""}`} eventKey={`${item.id} - ${button.id}`} onClick={() => handleClick(button.value)}>
                                                        {button.title}
                                                    </Nav.Item>
                                                ))}
                                            </Nav.Menu>
                                        )}
                                    </>
                                ))}
                            </Nav>

                            <Nav>
                                {data.slice(separator, data.length).map((item) => (
                                    <>
                                        {item.type === "button" ? (
                                            <Nav.Item className={`fs-6 fw-light ${boutonActif === item.value ? "text-blue" : ""}`} eventKey={item.id} icon={item.icon} onClick={() => handleClick(item.value)}>
                                                {item.title}
                                            </Nav.Item>
                                        ) : (
                                            <Nav.Menu eventKey={item.id} trigger="hover" title={item.title} icon={item.icon} placement="rightStart" className="fs-6 fw-light">
                                                {item.buttons.map((button) => (
                                                    <Nav.Item key={button.id} className={`${boutonActif === button.value ? "text-blue" : ""}`} eventKey={`${item.id} - ${button.id}`} onClick={() => handleClick(button.value)}>
                                                        {button.title}
                                                    </Nav.Item>
                                                ))}
                                            </Nav.Menu>
                                        )}
                                    </>
                                ))}
                            </Nav>
                        </div>
                    </Sidenav.Body>
                </Sidenav>
            </Sidebar>

            <div className="container">
                <Header darkMode={mode} />
                <div className="mt-3">

                    {data.map(item => (
                        <React.Fragment key={item.id}>
                            {item.type === "accordion" && item.buttons ? (
                                item.buttons.map(button => (
                                    <React.Fragment key={button.value}>
                                        {boutonActif === button.value && button.component}
                                    </React.Fragment>
                                ))
                            ) : (

                                boutonActif === item.value && item.component
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Home;
