import { Col, Row } from 'react-bootstrap';
import Trip from './Trip';
import { useState } from 'react';
import Stopover from './Stopover';
import { MinusCircle, PlusCircle } from 'lucide-react';

const Itenerary = ({ t }) => {
    const [outboundStopovers, setOutboundStopovers] = useState([]);
    const [outboundIdCounter, setOutboundIdCounter] = useState(1);

    const addOutboundStopover = () => {
        setOutboundStopovers([...outboundStopovers, { id: outboundIdCounter }]);
        setOutboundIdCounter(outboundIdCounter + 1);
    };

    const removeOutboundStopover = (id) => {
        const newStopovers = outboundStopovers.filter(stopover => stopover.id !== id);
        const reindexedStopovers = newStopovers.map((stopover, index) => ({
            id: index + 1,
        }));
        setOutboundStopovers(reindexedStopovers);
        setOutboundIdCounter(reindexedStopovers.length + 1);
    };

    return (
        <section className="bg-white position-relative form-transition">
            <div className="container">
                <Row className="justify-content-center">
                    <Col lg="10" xl="8" className="my-3">
                        <>
                            <h2 className="fs-3 lh-base fw-medium text-dark">{t('create_trip')}</h2>
                            <p className="fs-5 fw-light">{t('trip_caption_transport')}</p>
                            <div className='mt-4'>
                                <Trip t={t} />
                                {outboundStopovers.map(stopover => (
                                    <div key={stopover.id} className='d-flex flex-column my-1'>
                                        <div className='text-blue'>{t('transport_stop')} {stopover.id} :</div>
                                        <Stopover t={t} />
                                        <button className="bg-transparent text-danger align-self-end" type="button" onClick={() => removeOutboundStopover(stopover.id)}>
                                            <MinusCircle strokeWidth={1.5} /> {t('remove')}
                                        </button>
                                    </div>
                                ))}
                            </div>

                            {outboundStopovers.length <= 4 && <button className="bg-transparent text-blue mt-1" type="button" onClick={addOutboundStopover}>
                                <PlusCircle strokeWidth={1.5} /> {t('add_stop')}
                            </button>}
                        </>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Itenerary;
