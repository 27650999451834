import { Square } from 'lucide-react';
import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const ApexChart = ({mode, t }) => {

  const dates = Array.from({ length: 20 }, (_, i) => {
    const date = new Date();
    date.setDate(date.getDate() - i);
    return date.toLocaleDateString('fr-FR', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  }).reverse();

  const [series] = useState([
    {
      name: t('conversions'),
      data: [20, 65, 35, 88, 51, 21, 46, 40, 67, 32, 81, 34, 27, 24, 16, 22, 36, 78, 21, 15]
    },
    {
      name: t('duration_'),
      data: [-20, -5, -6, -18, -40, -22, -85, -80, -15, -13, -18, -33, -62, -54, -41, -18, -25, -22, -8, -46],
    },
  ]);

  const options = {
    chart: {
      type: 'bar',
      height: 260,
      stacked: true,
      toolbar: { show: false },
    },
    colors: ['#8077ec', '#16A571'],
    plotOptions: {
      bar: {
        borderRadius: 5,
        borderRadiusApplication: 'end',
        borderRadiusWhenStacked: 'all',
        horizontal: false,
        barHeight: '80%',
        columnWidth: '40%',
      },
    },
    dataLabels: { enabled: false },
    stroke: { width: 1, colors: ["#fff"] },
    grid: {
      show: false,
    },
    yaxis: {
      labels: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    tooltip: {
      shared: false,
      followCursor: true,
      x: { formatter: (val) => val },
      y: [{ formatter: (val) => Math.abs(val) + "%" }, { formatter: (val) => Math.abs(val) + " Minutes" }]
    },
    xaxis: {
      categories: dates,
      labels: {
        show: false,
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
    },
    legend: {
      show: false
    },
  };

  return (

    <div className={`p-3 rounded h-100  ${mode ? "bg-dark text-white" : "border bg-white text-dark"}`}>
      <div className="fs-5 fw-medium">{t("convesion_rate_title")}</div>
      <p className="fs-6 text-secondary">{t('convesion_rate_caption')}</p>
      <ReactApexChart options={options} series={series} type="bar" height={290} />

      <div className='d-flex justify-content-around'>
        <span className='d-flex flex-column align-items-center' key="Taux de conversions">
          <div className='d-flex align-items-center'>
            <Square size={10} fill="#8077ec" strokeWidth={0} color="#8077ec" />
            <span className='mx-1'>{t('convesion_rate')}</span>
          </div>
          <div className='fs-5 text-dark mt-1'>33.5%</div>
        </span>
        <span className='d-flex flex-column align-items-center' key="Durée moyenne des sessions">
          <div className='d-flex align-items-center'>
            <Square size={10} fill="#16A571" strokeWidth={0} color="#16A571" />
            <span className='mx-1'>{t('sessions_duration')}</span>
          </div>
          <div className='fs-5 text-dark mt-1'>24.4 {t('minutes')}</div>
        </span>
      </div>
    </div>
  );
};

export default ApexChart

