import StepComponent from "../../../components/StepComponent";
import Title from '../Title'
import Description from '../Description'
import Photos from '../Photos'
import Informations from '../Informations'
import Type from '../Type'
import Attributes from "../carComponents/Attributes";
import Details from "../carComponents/Details";
import Pricing from "../Pricing";
import Review from "../Review";
import Booking from "../Booking";
import Discount from "../Discount";
import Location from "../Location";
import Keywords from "../Keywords";
import HandleSteps from "../../../components/HandleSteps";
import Color from "../carComponents/Color";
import Availability from "../carComponents/Availability";

const Car = ({ step, t, isLoading, handlePrevStep, handleNextStep }) => {

    return (

        <section className="position-relative py-5 mb-5 h-100">
            <form action="/">
                {step === 1 && <StepComponent step={1} />}

                {step === 2 && <Title />}
                {step === 3 && <Type t={t} />}
                {step === 4 && <Location t={t} />}
                {step === 5 && <Informations />}
                {step === 6 && <Details t={t} />}

                {step === 7 && <StepComponent step={2} />}

                {step === 8 && <Description />}
                {step === 9 && <Attributes t={t} />}
                {step === 11 && <Color t={t} />}
                {step === 10 && <Keywords t={t} />}
                {step === 12 && <Photos />}

                {step === 13 && <StepComponent step={3} />}

                {step === 14 && <Availability t={t} />}
                {step === 15 && <Booking t={t} />}
                {step === 16 && <Pricing t={t} />}
                {step === 17 && <Discount t={t} />}
                {step === 18 && <Review t={t} />}
            </form>
            <HandleSteps isLoading={isLoading} totalSteps={18} step={step} prev={handlePrevStep} next={handleNextStep} sepratorOne={6} SeparatorTwo={12} />
        </section>
    );
};

export default Car;
