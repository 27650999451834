import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Input from "../../../components/forms/Input";
import Size from "../../../components/Size";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const DetailsBusiness = ({ checked }) => {
  const { t } = useTranslation();
  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxChange = (value) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [value]: !prevCheckedItems[value],
    }));
  };


  const installations = [
    { title: t("conference_spaces"), value: "EspaceDeConférences" },
    { title: t("conference_center"), value: "CentreDeConférences" },
  ];

  return (
    <div className="my-3 fs-6">
      {checked === "NombreDeSalles" && (
        <>
          <Row className="fs-6 align-items-center">
            <Col md="6" className="my-3">
              <span className="text-dark">
                {t("meeting_rooms_count")} :
              </span>
            </Col>
            <Col md="6" className="my-3">
              <Input type="number" />
            </Col>
          </Row>
        </>
      )}

      {checked === "Installations" && (
        <>
          {installations.map((item, index) => (
            <div key={index} className="px-3">
              <div className="my-3">
                <CheckInput
                  id={item.value}
                  label={item.title}
                  checked={checkedItems[item.value] || false}
                  onChange={() => handleCheckboxChange(item.value)}
                />
              </div>

              {checkedItems[item.value] && (
                <>
                  {item.value === "EspaceDeConférences" && (
                    <Size label={t("room_size")} />
                  )}
                </>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default DetailsBusiness;
