import React from "react";
import { Col, Row } from "react-bootstrap";
import { Uploader, Loader } from "rsuite";
import Input from "../../../components/forms/Input";
import { useTranslation } from "react-i18next";
import { UploadCloud } from "lucide-react";

function previewFile(file, callback) {
    const reader = new FileReader();
    reader.onloadend = () => {
        callback(reader.result);
    };
    reader.readAsDataURL(file);
}

const Personnal = ({ InputSection }) => {
    const [uploading, setUploading] = React.useState(false);
    const [fileInfo, setFileInfo] = React.useState(null);
    const { t } = useTranslation();

    return (
        <>

            <InputSection
                title="Nom complet"
                description="Vérifiez que le nom correspond à celui de votre pièce d'identité."
                input={
                    <Row>
                        <Col sm="6">
                            <Input placeholder={t("first_name")} />
                        </Col>
                        <Col sm="6">
                            <Input placeholder={t("last_name")} />
                        </Col>
                    </Row>
                }
            />

            <hr className="border-secondary my-4" />

            <InputSection
                title={t('email')}
                description="Utilisez une adresse à laquelle vous aurez toujours accès."
                input={<Input placeholder={t("email")} />}
            />

            <hr className="border-secondary my-4" />

            <InputSection
                title={t('phone')}
                description="Ajoutez un numéro sur lequel les voyageurs peuvent vous contacter."
                input={<Input placeholder={t("phone_number")} />}
            />

            <hr className="border-secondary my-4" />

            <InputSection
                title={t('photo')}
                description="This will be displayed on your profile."
                input={
                    <Uploader
                        fileListVisible={false}
                        listType="picture"
                        action="//jsonplaceholder.typicode.com/posts/"
                        onUpload={(file) => {
                            setUploading(true);
                            previewFile(file.blobFile, (value) => {
                                setFileInfo(value);
                            });
                        }}
                        onSuccess={() => setUploading(false)}
                        onError={() => {
                            setFileInfo(null);
                            setUploading(false);
                        }}>
                        <button style={{ width: 150, height: 150 }}>
                            {uploading && <Loader backdrop center />}
                            {fileInfo ? (
                                <img src={fileInfo} width="100%" height="100%" alt="user profile" />
                            ) : (
                                <div className="text-dark">
                                    <UploadCloud size={30} strokeWidth={1.5} />
                                    <p className="fw-light mt-1">Drop or select file</p>
                                </div>
                            )}
                        </button>
                    </Uploader>
                }
            />

            <hr className="border-secondary my-4" />
                
            <InputSection
                title={t('address')}
                description="Donnez des informations correctes."
                input={
                    <Row>
                        <Col sm="6">
                            <Input placeholder={t("country")} />
                        </Col>
                        <Col sm="6">
                            <Input placeholder={t("city")} />
                        </Col>
                    </Row>
                }
            />
        </>
    );
};

export default Personnal;
