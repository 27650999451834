import { useState } from "react";
import Input from "./Input";
import { MinusCircle, PlusCircle } from "lucide-react";
import { useTranslation } from "react-i18next";

const Highlights = () => {

  const { t } = useTranslation();

  const [forms, setForms] = useState([{ id: 1, value: "" }]);

  const addForm = () => {
    if (forms.length < 5) {
      const newId = forms.length + 1;
      setForms([...forms, { id: newId, value: "" }]);
    }
  };

  const removeForm = (id) => {
    const updatedForms = forms.filter((form) => form.id !== id);
    setForms(updatedForms);
  };

  const handleInputChange = (id, value) => {
    const updatedForms = forms.map((form) => (form.id === id ? { ...form, value } : form));
    setForms(updatedForms);
  };

  return (
    <div className="mt-1">
      <div className="my-3 d-flex flex-column"></div>
      {forms.map((form) => (
        <div className="my-3 d-flex flex-column" key={form.id}>
          <Input type="text" handleChange={(id, value) => handleInputChange(form.id, value)} value={form.value} />
          {forms.length > 1 && (
            <button className="bg-transparent text-danger mt-3 align-self-end" type="button" onClick={() => removeForm(form.id)}>
              <MinusCircle strokeWidth={1.5} /> {t('remove')}
            </button>
          )}
        </div>
      ))}
      {forms.length < 5 && (
        <button className="bg-transparent text-blue" type="button" onClick={addForm}>
          <PlusCircle strokeWidth={1.5} /> {t('add')}
        </button>
      )}
    </div>
  );
};

export default Highlights;
