import { Row, Col } from "react-bootstrap";
import RadioInput from "./RadioInput";

const SelectedChoice = ({ data, handleChoice, selectedChoice }) => {
    return (
        <div className="my-5 fs-6 text-dark">
            {data.map(check => (
                <div
                    key={check.id}
                    className={`my-3 rounded-4 py-2 px-3 ${check.value === selectedChoice ? "border-blue" : "border"}`}
                    onClick={() => handleChoice(check.value)}
                    style={{ cursor: 'pointer' }}
                >
                    <Row className='align-items-center'>
                        <Col sm='1' className='my-3'>
                            <RadioInput
                                id={check.id}
                                value={check.value}
                                checked={check.value === selectedChoice}
                                onChange={() => handleChoice(check.value)}
                                name="SelectedChoice"
                            />
                        </Col>
                        <Col xs="10" className='my-3'>
                            <div className='fs-6 text-dark'>{check.title}</div>
                            <div className="fw-light">{check.caption}</div>
                        </Col>
                        <Col xs="1" className='my-3'>
                            <span className='text-blue'>{check.icon}</span>
                        </Col>
                    </Row>
                </div>
            ))}
        </div>
    );
}

export default SelectedChoice;
