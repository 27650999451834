import { Col, Row } from "react-bootstrap";
import Input from "../../../components/forms/Input";
import Size from "../../../components/Size";
import CheckInput from '../../../components/forms/CheckInput'
import { useTranslation } from "react-i18next";

const DetailsCasino = ({ checked }) => {
    const { t } = useTranslation();

    return (
        <div className="my-3 fs-6">
            {checked === 'Casino' && (
                <>
                    <Row className='fs-6 align-items-center'>
                        <Col md="6" className='my-3'>
                            <span className='text-dark'>{t('slot_machines')}</span>
                        </Col>
                        <Col md="6" className='my-3'>
                            <Input type="number" />
                        </Col>
                    </Row>

                    <Row className='fs-6 align-items-center'>
                        <Col md="6" className='my-3'>
                            <span className='text-dark'>{t('games_tables')}</span>
                        </Col>
                        <Col md="6" className='my-3'>
                            <Input type="number" />
                        </Col>
                    </Row>

                    <Row className='fs-6 align-items-center'>
                        <Col md="6" className='my-3'>
                            <span className='text-dark'>{t('vip_game_rooms')}</span>
                        </Col>
                        <Col md="6" className='my-3'>
                            <Input type="number" />
                        </Col>
                    </Row>

                    <Size label="Taille du casino" />

                    <div className="my-3 px-3">
                        <CheckInput id="BureauDeParisSportifs" label={t('betting_office')} />
                    </div></>
            )}

        </div>


    )
}

export default DetailsCasino