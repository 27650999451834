import React from 'react';
import Chart from 'react-apexcharts';

const Traffic = ({ mode, t }) => {
    const options = {
        chart: {
            type: 'area',
            toolbar: {
                show: false,
            }
        },

        stroke: {
            curve: 'straight',
            width: 1,
        },
        colors: ["#8077ec"],
        tooltip: {
            enabled: true,
            shared: false,
            followCursor: true,
        },
        xaxis: {
            categories: [t('saturday'), t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday')],
            labels: {
                show: true,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        grid: {
            show: false,
        },
        fill: {
            opacity: 0.5,
            colors: ["#8077ec"],
            type: 'gradient',
            gradient: {
                shade: 'light',
                type: 'vertical',
                gradientToColors: ["#e9e7fd"],
                stops: [0, 100],
            },
        },
        dataLabels: {
            enabled: false,
        },
    };

    const series = [
        {
            name: t('visits'),
            data: [497, 384, 411, 247, 321, 694, 581],
        },
    ];

    return (
        <div className={`p-3 rounded h-100  ${mode ? "bg-dark text-white" : "border bg-white text-dark"}`}>
            <div className="fs-5 fw-medium">{t("web_trafic")}</div>
            <p className="fs-6 text-secondary">{t('web_traffic_caption')}</p>
            <div className='my-3 fs-6 fw-light'>{t('total_visits')} :</div>
            <Chart options={{
                ...options,
                chart: {
                    ...options.chart,
                    offsetX: 0,
                    offsetY: 0,
                },
            }}
                series={series}
                type="area"
                width="100%"
                height={290} />
        </div>

    );
};

export default Traffic;
