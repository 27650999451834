import React from 'react'
import { Col, Row } from 'react-bootstrap'
import BarChart from './BarChart'
import Increase from './Increase'
import Decrease from './Decrease'
import { useTranslation } from "react-i18next";

const ChartItem = ({ mode, icon, count, title, color, data, status, growth }) => {

    const { t } = useTranslation();

    return (
        <div className={`p-4 rounded h-100 ${mode ? 'bg-dark text-white' : 'border bg-white text-dark'}`}>
            <Row className='justify-content-between align-items-center'>
                <Col xl="6">
                    <div className='d-flex align-items-center'>
                        <span style={{ color: color }}>{icon}</span>
                        <span className='ms-3'>
                            <div className='fs-6 fw-light'>{title}</div>
                            <div className='fs-4 fw-bold'>{count}</div>
                        </span>
                    </div>
                </Col>
                <Col xs="5" sm="4" md="6" lg="9" xl="5">
                    <BarChart color={color} data={data} />
                </Col>
            </Row>
            <hr className='border-secondary' />
            <>
                {status === "up" ?
                    <div className='d-flex align-items-center text-green'>
                        <Increase /> <span>+{growth}%</span> <span className='fw-light mx-2'>{t('from_last_year')}</span>
                    </div>
                    :
                    <div className='d-flex align-items-center text-red'>
                        <Decrease /> <span>-{growth}%</span> <span className='fw-light mx-2'>{t('from_last_year')}</span>
                    </div>
                }
            </>
        </div>
    )
}

export default ChartItem
