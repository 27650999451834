import { Accessibility, Briefcase, Cable, Dices, Leaf, ParkingCircle, PawPrint, Shirt, SprayCan, Users2, Waves, Wifi } from "lucide-react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Choice from "../../../components/Choice";
import Services from "./Services";


const EquipmentItem = ({ title, value, onChange, icon, content }) => (

  <div className="my-4">
    <Choice
      text={<div className="d-flex align-items-start"><span>{icon}</span><span className="mx-2">{title}</span></div>}
      onChange={onChange}
      selectedChoice={value}
    />
    {value === "yes" && content.map((element, index) => (
      <div key={index} className="py-3 my-3">
        <div className="text-blue fs-6">{element.title}</div>
        <div>{element.content}</div>
      </div>
    ))}
  </div>
);

const Equipments = () => {

  const { t } = useTranslation();

  const [infos, setInfos] = useState({
    wifi: "no",
    parking: "no",
    pool: "no",
    animals: "no",
    accessibility: "no",
    laundry: "no",
    facilities: "no",
    hobbies: "no",
    relaxation: "no",
    needs: "no",
    family: "no",
    nature: "no"
  });

  const handleChange = (key) => (value) => {
    setInfos(prev => ({ ...prev, [key]: value }));
  };

  const data = [
    {
      title: t('do_you_offer_internet_connexion'),
      value: infos.wifi,
      onChange: handleChange('wifi'),
      icon: <Wifi size={20} strokeWidth={1.5} />,
      content: [
        { title: t('wifi'), content: <Services serviceKey="wifi" /> },
      ]
    },
    {
      title: t('do_you_offer_parking_access'),
      value: infos.parking,
      onChange: handleChange('parking'),
      icon: <ParkingCircle size={20} strokeWidth={1.5} />,
      content: [
        { title: t('parking'), content: <Services serviceKey="parking" /> },
      ]
    },
    {
      title: t('do_you_offer_parking_access'),
      value: infos.pool,
      onChange: handleChange('pool'),
      icon: <Waves size={20} strokeWidth={1.5} />,
      content: [
        { title: t('pool'), content: <Services serviceKey="pool" /> },
      ]
    },
    {
      title: t('do_you_accept_animals'),
      value: infos.animals,
      onChange: handleChange('animals'),
      icon: <PawPrint size={20} strokeWidth={1.5} />,
      content: [
        { title: t('animals'), content: <Services serviceKey="animals" /> },
      ]
    },
    {
      title: t('do_you_have_accessibility_features'),
      value: infos.accessibility,
      onChange: handleChange('accessibility'),
      icon: <Accessibility size={20} strokeWidth={1.5} />,
      content: [
        { title: t('accessibility'), content: <Services serviceKey="accessibility" /> },
      ]
    },
    {
      title: t('do_you_offer_landry_services'),
      value: infos.laundry,
      onChange: handleChange('laundry'),
      icon: <Shirt size={20} strokeWidth={1.5} />,
      content: [
        { title: t('laundry'), content: <Services serviceKey="laundry" /> },
      ]
    },
    {
      title: t('do_you_offer_inroom_amenties'),
      value: infos.facilities,
      onChange: handleChange('facilities'),
      icon: <Cable size={20} strokeWidth={1.5} />,
      content: [
        { title: t('facilities'), content: <Services serviceKey="facilities" /> },
      ]
    },
    {
      title: t('spot_facilities_title'),
      value: infos.hobbies,
      onChange: handleChange('hobbies'),
      icon: <Dices size={20} strokeWidth={1.5} />,
      content: [
        { title: t('adventure_sport'), content: <Services serviceKey="aventure" /> },
        { title: t('casino'), content: <Services serviceKey="casino" /> },
        { title: t('golf'), content: <Services serviceKey="golf" /> },
        { title: t('ski'), content: <Services serviceKey="ski" /> },
      ]
    },
    {
      title: t('car_services_title'),
      value: infos.relaxation,
      onChange: handleChange('relaxation'),
      icon: <SprayCan size={20} strokeWidth={1.5} />,
      content: [
        { title: t('hot_springs'), content: <Services serviceKey="spring" /> },
        { title: t('spa'), content: <Services serviceKey="spa" /> },
        { title: t('beach'), content: <Services serviceKey="beach" /> },
      ]
    },
    {
      title: t('personel_needs_title'),
      value: infos.needs,
      onChange: handleChange('needs'),
      icon: <Briefcase size={20} strokeWidth={1.5} />,
      content: [
        { title: t('business'), content: <Services serviceKey="business" /> },
        { title: t('shopping'), content: <Services serviceKey="shop" /> },
      ]
    },
    {
      title: t('familty_services_title'),
      value: infos.family,
      onChange: handleChange('family'),
      icon: <Users2 size={20} strokeWidth={1.5} />,
      content: [
        { title: t('family'), content: <Services serviceKey="family" /> },
        { title: t('romantic'), content: <Services serviceKey="romantic" /> },
        { title: t('cellar'), content: <Services serviceKey="wine" /> },
      ]
    },
    {
      title: t('nature_facilities_title'),
      value: infos.nature,
      onChange: handleChange('nature'),
      icon: <Leaf size={20} strokeWidth={1.5} />,
      content: [
        { title: t('city'), content: <Services serviceKey="city" /> },
        { title: t('nature'), content: <Services serviceKey="nature" /> },
      ]
    },
  ];

  return (
    <div className="my-4">
      {data.map((item, index) => (
        <>
          <hr className="border-secondary my-5" />
          <EquipmentItem
            key={index}
            title={item.title}
            value={item.value}
            onChange={item.onChange}
            icon={item.icon}
            content={item.content}
          />

        </>
      ))}
    </div>
  );
};

export default Equipments;
