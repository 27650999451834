import React from 'react';
import Chart from 'react-apexcharts';

const BarChart = ({ color, data }) => {
    const options = {
        chart: {
            type: 'bar',
            height: 60,
            toolbar: {
                show: false,
            },
            sparkline: {
                enabled: true,
            },
        },
        colors: [color],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 1,
        },
        grid: {
            show: false,
        },
        xaxis: {
            categories: [],
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
        },
        plotOptions: {
            bar: {
                borderRadius: 1,
                horizontal: false,
                columnWidth: '40%',
            },
        },
    }

    const series = [
        {
            name: 'Ventes',
            data: data,
        },
    ]

    return (
        <Chart options={options} series={series} type="bar" width="100%" height={40} />
    );
};

export default BarChart;
