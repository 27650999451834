import { Row, Col } from "react-bootstrap"; 
import { CalendarCheck2, CalendarClock, CalendarMinus } from "lucide-react";
import { useState } from "react";
import Cancel from "../components/Cancel";
import SelectedChoice from "../../../components/forms/SelectedChoice";

const Availability = ({ t }) => {
  const [infos, setInfos] = useState({
    choice: "",
  });

  const handleChoice = (value) => {
    setInfos({ ...infos, choice: value });
  };

  const data = [
    {
      title: t('CarsDetailsWeek1'),
      icon: <CalendarCheck2 strokeWidth={1.2} size={25} />,
      value: "TousLesWeekEnds",
      caption: t('CarsDetailsWeek1p'),
    },
    {
      title: t('CarsDetailsWeek2'),
      icon: <CalendarClock strokeWidth={1.2} size={25} />,
      value: "2/4Week-endEnMoyenne",
      caption: t('CarsDetailsWeek2p'),
    },
    {
      title: t('CarsDetailsWeek3'),
      icon: <CalendarMinus strokeWidth={1.2} size={25} />,
      value: "0/1WeekEndMoyenne",
      caption: t('CarsDetailsWeek3p'),
    },
  ];

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="9" xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('activityperso')}</h2>
              <p className="fs-5 fw-light">{t('activitypersoP')}</p>

              <SelectedChoice data={data} handleChoice={handleChoice} selectedChoice={infos.choice} />

              <Cancel />
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Availability;
