import TextArea from "../../../components/forms/Textarea";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Inclusions = (props) => {

  const { t } = useTranslation();

  const [included, setIncluded] = useState("");
  const [excluded, setExcluded] = useState("");

  const handleIncluded = (event) => {
    setIncluded(event.target.value);
  };

  const handleExcluded = (event) => {
    setExcluded(event.target.value);
  };

  return (

    <>
      <div className="my-4">
        <>
          <div className="fs-5 fw-medium text-dark">{t('included')}</div>
          <p className="fs-6 fw-light">{t('included_caption')}</p>
          <div className="mt-3">
            <TextArea value={included} handleChange={handleIncluded} rows={8} maxlength={1000} placeholder={t('list_destination_per_line')} />
          </div>
          <div className="text-secondary">{t('min_of')} {1000 - included.length} {t('chars_left')}</div>
        </>
      </div>

      <div className="my-4">
        <>
          <div className="fs-5 fw-medium text-dark">{t('excluded')}</div>
          <p className="fs-6 fw-light">{t('excluded_caption')}</p>
          <div className="mt-3">
            <TextArea value={excluded} handleChange={handleExcluded} rows={8} maxlength={1000} placeholder={t('list_destination_per_line')} />
          </div>
          <div className="text-secondary">{t('min_of')} {1000 - excluded.length} {t('chars_left')}</div>
        </>
      </div>
    </>

  );
};

export default Inclusions;
