import "./App.css";
import Routes from "./Routes";
import "rsuite/dist/rsuite.min.css";
import { useEffect } from "react";
import Landing from "./pages/landing/Home";
import Loader from "./layout/Loader";
import { useDispatch, useSelector } from 'react-redux';
import { login, setLoading } from './global/actions';
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const dispatch = useDispatch();
  const { isLoggedIn, isLoading } = useSelector(state => state);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem("isLoggedIn");
    if (loggedInStatus === "true") {
      dispatch(login());
    }
  }, [dispatch]);

  const handleLogin = () => {
    dispatch(setLoading(true));
    setTimeout(() => {
      dispatch(login());
      localStorage.setItem("isLoggedIn", "true");
      dispatch(setLoading(false));
    }, 3000);
  };

  return (
    <div className="roboto-thin">
      {isLoading ? (
        <Loader />
      ) : isLoggedIn ? (
        <Routes />
      ) : (
        <Landing handleLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;
