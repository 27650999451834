import React from 'react'

const RadioInput = ({ id, name, label, value, onChange,checked }) => {

    return (

        <div className="radio-wrapper-7 my-3">
            <label className="radio-wrapper-7" htmlFor={id}>
                <input type="radio" name={name} id={id} value={value} onChange={onChange} checked={checked} />
                <span className='fw-light'>{label}</span>
            </label>
        </div>

    )
}

export default RadioInput