import TopBanner from "../../components/TopBanner";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Hotel from "./steps/Hotel";
import Flight from "./steps/Flight";
import Transport from "./steps/Transport";
import Rental from "./steps/Rental";
import Package from "./steps/Package";
import Car from "./steps/Car";
import Events from "./steps/Events";
import Restaurant from "./steps/Restaurant";
import Activity from "./steps/Activity";

const Operations = () => {

    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [step, setStep] = useState(1);

    const handleNextStep = () => {
        setIsLoading(true);
        setTimeout(() => {
            setStep(step + 1);
            window.scrollTo(0, 0);
            setIsLoading(false);
        }, 1000);
    };

    const handlePrevStep = () => {
        setStep(step - 1);
        window.scrollTo(0, 0);
    };

    const [product, setproduct] = useState("");

    useEffect(() => {
        const savedType = localStorage.getItem("product");
        if (savedType) {
            setproduct(savedType);
        }
    }, []);


    const configurations = {

        Hotel: [
            { content: <Hotel step={step} t={t} isLoading={isLoading} handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} /> },
        ],
        Maison: [
            { content: <Rental step={step} t={t} isLoading={isLoading} handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} /> },
        ],
        Vol: [
            { content: <Flight step={step} t={t} isLoading={isLoading} handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} /> },
        ],
        Transport: [
            { content: <Transport step={step} t={t} isLoading={isLoading} handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} /> },
        ],
        Activité: [
            { content: <Activity step={step} t={t} isLoading={isLoading} handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} /> },
        ],
        Package: [
            { content: <Package step={step} t={t} isLoading={isLoading} handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} /> },
        ],

        Voiture: [
            { content: <Car step={step} t={t} isLoading={isLoading} handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} /> },
        ],

        Événements: [
            { content: <Events step={step} t={t} isLoading={isLoading} handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} /> },
        ],

        Restaurant: [
            { content: <Restaurant step={step} t={t} isLoading={isLoading} handleNextStep={handleNextStep} handlePrevStep={handlePrevStep} /> },
        ],
    };

    const data = configurations[product] || [];

    return (
        <>
            <TopBanner step={step} />

            {data.map((choice, index) => (
                <div key={index}>{choice.content}</div>
            ))}
        </>
    );
};

export default Operations;
