import { Col, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import DateAndNoteApp from './DateAndNote';
import Meteo from './Meteo';
import { ArrowUpCircle } from 'lucide-react';

const TopInfos = ({ mode }) => {

    const { t } = useTranslation();

    const bgBlue = {
        background: "linear-gradient(180deg, #8077ec 0%, #B8B2F8 100%)",
        overflow: "hidden",
    };

    return (
        <Row>
            <Col xl="6" className='my-2'>
                <div style={mode ? {} : bgBlue} className={`p-3 text-white rounded h-100 ${mode ? "bg-dark" : ""}`}>
                    <Row>
                        <Col md="8" className="my-2">
                            <div className="fs-4 fw-bold">{t("good_morning")}, Asmane 👋</div>
                            <div className='my-2'>
                                <p className='fs-6'>{t("dashboard_text")}</p>
                                <a href='/structure' className='text-dark rounded d-block mt-2 fs-6'>
                                    <div className='d-flex align-items-center text-orange'>
                                        <ArrowUpCircle /> <span className='mx-2'>{t('add_your_product')}</span>
                                    </div>
                                </a>
                            </div>
                        </Col>
                        <Col md="3" className="my-2 d-none d-md-block">
                            <div className='w-100'>
                                <img width="100%" height="100%" className='w-100' src="https://isomorphic-furyroad.vercel.app/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fupgrade-plan.deb629fe.webp&w=1920&q=75" alt="" />
                            </div>
                        </Col>
                    </Row>

                </div>

            </Col>
            <Col md="6" xl="3" className='my-2'>
                <DateAndNoteApp mode={mode} t={t} />
            </Col>
            <Col md="6" xl="3" className='my-2'>
                <Meteo mode={mode} t={t} />
            </Col>
        </Row>
    )
}

export default TopInfos
