import React from 'react'
import RadioInput from '../../../components/forms/RadioInput'

const RoomRates = ({ optionSelectionnee, setTarification, t, handleModifier, handleSave }) => {
    return (
        <>
            <div className="fs-3 fw-medium text-dark">{t('rooms_and_rates')}</div>
            <p className="fs-5 fw-light">{t('add_room_text')}</p>

            <div className="my-4">
                <div className="text-blue fs-5">{t('pricing_type')}</div>
                <p className="fs-6 my-1 fw-light">{t('pricing_type_text')}</p>
                {!optionSelectionnee ? (
                    <>
                        <RadioInput name="Hoteltarification" id="daily" label={t('pricing_on_daily_basis')} onChange={() => setTarification("daily")} />

                        <RadioInput name="Hoteltarification" id="occupancy" label={t('pricing_by_occupation')} onChange={() => setTarification("occupancy")} />
                    </>
                ) : (
                    ""
                )}

                <div className="my-3">
                    {optionSelectionnee && (
                        <>
                            <div className="fs-6 fw-medium text-dark">
                                {optionSelectionnee.label} -{" "}
                                <span className="text-blue" role="button" onClick={handleModifier}>
                                    {t('edit')}
                                </span>
                            </div>
                            <p className="fw-light mt-1">{optionSelectionnee.description}</p>
                        </>
                    )}
                </div>

                {!optionSelectionnee ? (
                    <button type="button" className="btn-blue p-3 px-4 rounded-5" onClick={handleSave}>
                        {t('save')}
                    </button>
                ) : (
                    ""
                )}
            </div>
        </>
    )
}

export default RoomRates
