import { Square } from 'lucide-react';
import React from 'react';
import Chart from 'react-apexcharts';

const Countries = ({mode, t }) => {
    const data = [
        { title: "France", value: 28, color: "#16A571" },
        { title: "Germany", value: 16, color: "#FF712F" },
        { title: "Espagne", value: 11, color: "#8077ec" },
        { title: "Others", value: 45, color: "#222222" },
    ];

    const options = {
        chart: {
            type: 'donut',
            height: '350',
            offsetY: 0,
            offsetX: 0,
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
                customScale: 0.8,
                dataLabels: {
                    offset: -5,
                },
            }
        },
        stroke: {
            lineCap: "round",
            width: 10,
        },
        dataLabels: {
            enabled: false
        },
        fill: {
            colors: data.map(item => item.color),
        },
        legend: {
            show: false,
        },
        tooltip: {
            enabled: false,
        },
    };

    const series = data.map(item => item.value);

    return (
        <div className={`p-3 rounded h-100 ${mode ? "bg-dark text-white" : "border bg-white"}`}>
            <div className="fs-5 fw-medium">{t("gowth_summary")}</div>
            <p className="fs-6 text-secondary">{t('returning_rates')}</p>
            <div className='position-relative'>
                <Chart options={options} series={series} type="donut" height={310} />
                <div className='position-absolute d-flex flex-column align-items-center' style={{top:"45%",left:"43%"}}>
                    <div className='fw-light'>{t('costumers')}</div>
                    <div className='fs-5 fw-medium'>2.321</div>
                </div>
            </div>
            <div className='d-flex justify-content-between mt-3'>
                {data.map(item => (
                    <span className='d-flex flex-column align-items-center' key={item.title}>
                        <div className='d-flex align-items-center'>
                            <Square size={10} fill={item.color} strokeWidth={0} color={item.color} />
                            <span className='mx-1'>{item.title}</span>
                        </div>
                        <div className='fs-4 fw-medium mt-1'>{item.value}%</div>
                    </span>
                ))}
            </div>
        </div>
    );
};

export default Countries;
