import { Col, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next";
import ChartItem from '../components/ChartItem';
import SettingIcon from '@rsuite/icons/Setting';
import PeoplesIcon from '@rsuite/icons/Peoples';
import PeopleBranchIcon from '@rsuite/icons/PeopleBranch';
import CalendarIcon from '@rsuite/icons/Calendar';

const Stats = ({ products, mode, handleClick }) => {

    const { t } = useTranslation();

    let bookings = 0;

    products.forEach(element => {
        bookings += element.bookings;
    });

    const data = [
        { title: t('products'), value: "products", count: products.length, icon: <SettingIcon style={{ fontSize: "20px" }} />, data: [45, 54, 38, 56, 24, 65, 31], color: "#8077EC", status: "up", growth: 2 },
        { title: t('custumers'), value: "clients", count: 2.321, icon: <PeoplesIcon style={{ fontSize: "20px" }} />, data: [131, 102, 223, 45, 145, 148, 166], color: "#FF5733", status: "up", growth: 3 },
        { title: t('bookings'), value: "bookings", count: bookings, icon: <CalendarIcon style={{ fontSize: "20px" }} />, data: [42, 64, 48, 26, 44, 55, 41], color: "#16A571", status: "up", growth: 3 },
        { title: t('networks'), value: "networks", count: `695.3k`, icon: <PeopleBranchIcon style={{ fontSize: "20px" }} />, data: [12, 15, 13, 16, 18, 19, 11], color: "#0966FF", status: "down", growth: 1 },
    ]

    return (
        <Row>
            {data.map(item => (
                <Col md="6" lg="3" xl="3" className='my-2'>
                    <div role='button' onClick={() => handleClick(item.value)}>
                        <ChartItem mode={mode} icon={item.icon} title={item.title} count={item.count} color={item.color} data={item.data} status={item.status} growth={item.growth} />
                    </div>
                </Col>
            ))}
        </Row>
    )
}

export default Stats
