import { Row, Col } from "react-bootstrap";
import { useEffect, useState } from "react";
import CheckInput from "../../components/forms/CheckInput";

const Discount = ({ t }) => {
  const [product, setProduct] = useState("");
  const [dataItems, setDataItems] = useState([]);

  useEffect(() => {
    const savedType = localStorage.getItem("product");
    if (savedType) {
      setProduct(savedType);
    }
  }, []);

  const configurations = {
    Maison: [
      { id: 1, title: t('promo_new_adds'), content: t('promo_new_adds_caption'), defaultValue: 20, isChecked: false },
      { id: 2, title: t('weekly_discount'), content: t('promo_week_caption'), defaultValue: 10, isChecked: false },
      { id: 3, title: t('long_stays'), content: t('long_stays_caption'), defaultValue: 15, isChecked: false },
    ],

    Vol: [
      { id: 1, title: t('promo_new_adds'), content: t('promo_new_adds_caption'), defaultValue: 15, isChecked: false },
      { id: 2, title: t('last_minutes_promo'), content: t('last_minutes_promo_caption'), defaultValue: 20, isChecked: false },
      { id: 3, title: t('saisons_promo'), content: t('saisons_promo_caption'), defaultValue: 30, isChecked: false },
    ],

    Activité: [
      { id: 1, title: t('promo_new_adds'), content: t('promo_new_adds_caption'), defaultValue: 15, isChecked: false },
      { id: 2, title: t('last_minutes_promo'), content: t('last_minutes_promo_caption'), defaultValue: 20, isChecked: false },
      { id: 3, title: t('saisons_promo'), content: t('saisons_promo_caption'), defaultValue: 30, isChecked: false },
    ],

    Transport: [
      { id: 1, title: t('promo_new_adds'), content: t('promo_new_adds_caption'), defaultValue: 15, isChecked: false },
      { id: 2, title: t('last_minutes_promo'), content: t('last_minutes_promo_caption'), defaultValue: 20, isChecked: false },
      { id: 3, title: t('weekend_promo'), content: t('weekend_promo_caption'), defaultValue: 10, isChecked: false },
    ],

    Package: [
      { id: 1, title: t('promo_new_adds'), content: t('promo_new_adds_caption'), defaultValue: 15, isChecked: false },
      { id: 2, title: t('weekly_discount'), content: t('promo_week_caption'), defaultValue: 20, isChecked: false },
      { id: 3, title: t('saisons_promo'), content: t('saisons_promo_caption'), defaultValue: 30, isChecked: false },
    ],

    Voiture: [
      { id: 1, title: t('promo_new_adds'), content: t('promo_new_adds_caption'), defaultValue: 15, isChecked: false },
      { id: 2, title: t('weekly_discount'), content: t('week_car_caption'), defaultValue: 10, isChecked: false },
      { id: 3, title: t('car_promo_long'), content: t('car_promo_long_caption'), defaultValue: 20, isChecked: false },
    ],

    Restaurant: [
      { id: 1, title: t('promo_new_adds'), content: t('promo_new_adds_caption'), defaultValue: 15, isChecked: false },
      { id: 2, title: t('loyalty_promo'), content: t('loyalty_promo_caption'), defaultValue: 10, isChecked: false },
      { id: 3, title: t('group_promo'), content: t('group_promo_caption'), defaultValue: 20, isChecked: false },
    ],


    Test: [
      { id: 1, title: t('promo_new_adds'), content: t('promo_new_adds_caption'), defaultValue: 20, isChecked: false },
      { id: 2, title: t('weekly_discount'), content: t('weekly_discount_caption'), defaultValue: 10, isChecked: false },
      { id: 3, title: t('monthly_discount'), content: t('monthly_discount_caption'), defaultValue: 15, isChecked: false },
    ],
  };

  useEffect(() => {
    setDataItems(configurations[product] || []);
  }, [product]);

  const handleCheckboxChange = (id) => {
    setDataItems((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, isChecked: !item.isChecked } : item
      )
    );
  };

  const handleInputChange = (id, value) => {
    setDataItems((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, defaultValue: value } : item
      )
    );
  };

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="7" xl="6" className="my-3">
            <h2 className="fs-3 lh-base fw-medium text-dark">{t('reductions_title')}</h2>
            <p className="fs-5 fw-light">{t('reductions_caption')}</p>
            <div className="mt-4 text-dark">
              {dataItems.map((item) => (
                <Row key={item.id} className="m-0 my-3 border rounded-4 p-3 py-4 align-items-center bg-light">
                  <Col xl="2" className="my-3">
                    <input
                      style={{ width: "40px" }}
                      type="number"
                      className="border-0 fw-medium text-center bg-light"
                      value={item.defaultValue}
                      onChange={(e) => handleInputChange(item.id, Number(e.target.value))}
                      disabled={!item.isChecked}
                    />%
                  </Col>
                  <Col xl="9" className="my-3">
                    <div>
                      <div className="fs-6 fw-medium">{item.title}</div>
                      <div className="fs-6 fw-light">{item.content}</div>
                    </div>
                  </Col>
                  <Col xl="1" className="my-3">
                    <CheckInput checked={item.isChecked} onChange={() => handleCheckboxChange(item.id)} />
                  </Col>
                </Row>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Discount;
