import { HeartHandshake, ShoppingBag, Users2 } from 'lucide-react';
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from "react-i18next";

const Reasons = () => {

  const { t } = useTranslation();

  const data = [
    { title: t('happy_clients'), info: "2K", icon: <HeartHandshake size={60} strokeWidth={1} /> },
    { title: t('products_added'), info: "3M", icon: <ShoppingBag size={60} strokeWidth={1} /> },
    { title: t('travelers_around_the_world'), info: "15K", icon: <Users2 size={60} strokeWidth={1} /> },
  ]

  return (
    <div className='bg-dark my-5 py-4'>
      <Row>
        {data.map((item, index) => (
          <Col key={index} xl="4" className='my-5 d-flex justify-content-center'>
            <div className='d-flex text-white'>
              <span>{item.icon}</span>
              <span className='mx-3'>
                <div className='fs-1 fw-bold text-blue'>{item.info}</div>
                <p className='fs-6 fw-light'>{item.title}</p>
              </span>
            </div>
          </Col>
        ))}

      </Row>
    </div>
  )
}

export default Reasons
