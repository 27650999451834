import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import {
  Accessibility, AirVent, Bath, BedIcon, ChefHat, Coffee,
  Gamepad2, Refrigerator, Ruler, ShowerHead, View
} from "lucide-react";
import { Panel } from "rsuite";
import DetailsBathroom from "./DetailsBathroom";
import DetailsKitchen from "./Detailskitchen";
import DetailsViews from "./DetailsViews";
import Bed from "./Bed";
import DetailsPricing from "./DetailsPricing";
import KitchenFeatures from "./KitchenFeatures";
import DetailsLayout from "./DetailsLayout";
import BathroomFacilities from "./BathroomFacilities";
import DetailsBedding from "./DetailsBedding";
import DetailsAccessibility from "./DetailsAccessibility";
import DetailsDrinks from "./DetailsDrinks";
import DetailsEntertainment from "./DetailsEntertainment";
import DetailsConditioning from "./DetailsConditioning";
import { useTranslation } from "react-i18next";
import RoomConfiguration from "./RoomConfiguration";
import RoomRates from "./RoomRates";

const Informations = () => {
  const { t } = useTranslation();
  const [tarification, setTarification] = useState(null);
  const [optionSelectionnee, setOptionSelectionnee] = useState(null);
  const [roomDisplayed, setRoomDisplayed] = useState(false);
  const [displayedPanels, setDisplayedPanels] = useState(false);

  const handleSave = () => {
    if (tarification) {
      const optionDetails = {
        label: tarification === "daily" ? t('pricing_on_daily_basis') : t('pricing_by_occupation'),
        description: tarification === "daily" ? t('pricing_daily_text') : t('pricing_occupation_text'),
      };
      setOptionSelectionnee(optionDetails);
    }
  };

  const handleModifier = () => {
    setTarification(null);
    setOptionSelectionnee(null);
  };

  const handleAddRoom = () => {
    setRoomDisplayed(true);
  };

  const togglePanels = () => {
    setDisplayedPanels(!displayedPanels);
  };

  const panelsData = [
    { icon: ShowerHead, label: 'bathroom_details', component: <DetailsBathroom /> },
    { icon: ChefHat, label: 'kitchen', component: <DetailsKitchen /> },
    { icon: Refrigerator, label: 'kitchen_features', component: <KitchenFeatures /> },
    { icon: View, label: 'room_views', component: <DetailsViews /> },
    { icon: Ruler, label: 'bedroom_layout', component: <DetailsLayout /> },
    { icon: Bath, label: 'bathroom_equipment', component: <BathroomFacilities /> },
    { icon: BedIcon, label: 'bedding', component: <DetailsBedding /> },
    { icon: Accessibility, label: 'accessibility', component: <DetailsAccessibility /> },
    { icon: Coffee, label: 'drinks_in_the_room', component: <DetailsDrinks /> },
    { icon: Gamepad2, label: 'inroom_entertainment', component: <DetailsEntertainment /> },
    { icon: AirVent, label: 'adjustable_air_conditionning', component: <DetailsConditioning /> },
  ];

  const panels = panelsData.map((panel, index) => (
    <>
      <Panel
        header={
          <div className="fw-light fs-5 d-flex align-items-center">
            <panel.icon className="mx-3" size={20} strokeWidth={1.5} /> {t(panel.label)}
          </div>
        }
        eventKey={index + 1}
        key={index}
      >
        {panel.component}
      </Panel></>
  ));

  return (
    <section className="container form-transition">
      <Row className="justify-content-center">
        <Col lg="10" xl="8" className="my-3">


          <RoomRates t={t} optionSelectionnee={optionSelectionnee} setTarification={setTarification} handleModifier={handleModifier} handleSave={handleSave} />

          <hr className="border-secondary my-5" />

          {!roomDisplayed ? (
            <div className="my-4">
              <div className="text-blue fs-5 d-flex align-items-center">{t('add_room_types')}</div>
              <p className="fs-6 fw-light mt-1">{t('new_room_text')}</p>

              <button type="button" onClick={handleAddRoom} className="btn-outline-blue my-3 p-3 px-5 rounded-5">
                {t('add_room_type')}
              </button>
            </div>
          ) : (
            <>
              <RoomConfiguration t={t} togglePanels={togglePanels} panels={panels} displayedPanels={displayedPanels} />

              <hr className="border-secondary my-5" />

              <div className="my-4">
                <span className="text-blue fs-5 d-flex align-items-center">{t('beds_and_occupancy')}</span>
                <p className="fs-6 fw-light my-1">{t('beds_and_occupancy_text')}</p>
                <Bed />
              </div>

              <hr className="border-secondary my-5" />

              <div className="my-4">
                <span className="text-blue fs-5 d-flex align-items-center">{t('base_rate')}</span>
                <p className="fs-6 fw-light my-1">{t('base_rate_text')}</p>
                <DetailsPricing />
              </div>
            </>
          )}
        </Col>
      </Row>
    </section>
  );
};

export default Informations;
