import { useState } from "react";
import CheckInput from '../../../components/forms/CheckInput'
import { useTranslation } from "react-i18next";
import Input from "../../../components/forms/Input";
import { Col, Row } from "react-bootstrap";
import Select from "../../../components/forms/Select";

const Services = ({ serviceKey }) => {

    const { t } = useTranslation();
    const [checkedItems, setCheckedItems] = useState({});
    const [showAll, setShowAll] = useState(false);

    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    const data = {
        wifi: [
            { title: t('extra_fees'), value: "Frais supplémentaires" },
        ],
        food: [
            { title: t('breakfast'), value: "Petit déjeuner" },
            { title: t('hot_meal'), value: "Repas chaud" },
            { title: t('cold_meal'), value: "Repas froid" },
            { title: t('restauAtt1'), value: "Menus végétariens" },
            { title: t('restauAtt2'), value: "Menus enfants" },
            { title: t('snacks'), value: "Collations" },
            { title: t('drinks'), value: "Boissons" },
            { title: t('other'), value: "Autre" },
        ],
        entertaining: [
            { title: t('movies'), value: "Films" },
            { title: t('music'), value: "Musique" },
            { title: t('games'), value: "Jeux" },
            { title: t('magzines'), value: "Magazines/Revues" },
            { title: t('other'), value: "Autre" },

        ]
    };

    const itemsToShow = showAll ? data[serviceKey] : data[serviceKey].slice(0, 5);

    const wifi = [
        { title: t('unlimited'), value: "Illimité" },
        { title: t('debit_limited'), value: "Limité en bande passante" },
        { title: t('per_hour'), value: "Par heure" },
    ]

    return (
        <div className='my-3 fs-6'>
            <>
                {itemsToShow.map((check, index) => (
                    <div key={index} className='my-3'>

                        <div className='d-flex align-items-center'>
                            <CheckInput id={check.value} label={check.title} checked={checkedItems[check.value] || false} onChange={() => handleCheckboxChange(check.value)} info={check.info} />
                        </div>

                        {checkedItems[check.value] && check.value === "Frais supplémentaires" && (
                            <Row>
                                <Col md="6" className="my-3">
                                    <Input placeholder={t('amount')} />
                                </Col>
                                <Col md="6" className="my-3">
                                    <Select data={wifi} />
                                </Col>
                            </Row>
                        )}

                        {checkedItems[check.value] && check.value === "Autre" && (
                            <div className="my-3">
                                <Input placeholder={t('pricise')} />
                            </div>
                        )}
                    </div>
                ))}
            </>
            {data[serviceKey].length > 5 && <button type="button" className="bg-white text-blue fs-6 fw-light" onClick={() => setShowAll(!showAll)}>
                {showAll ? t('show_less') : t('show_more')}
            </button>}
        </div>
    )
}

export default Services