import DashboardIcon from "@rsuite/icons/Dashboard";
import CalendarIcon from "@rsuite/icons/Calendar";
import TagIcon from "@rsuite/icons/Tag";
import MessageIcon from "@rsuite/icons/Message";
import PeoplesIcon from "@rsuite/icons/Peoples";
import GrowthIcon from "@rsuite/icons/Growth";
import ListIcon from "@rsuite/icons/List";
import BarChartIcon from "@rsuite/icons/BarChart";
import OffIcon from "@rsuite/icons/Off";
import HotelDetails from "./HotelDetails";
import Booking from "./Booking";
import Facture from "./Facture";
import RoomType from "./RoomType";
import Availability from "./Availability";
import Rates from "./Rates";
import Restrictions from "./Restrictions";
import Cancel from "./Cancel";
import Rateadj from "./Rateadj";
import PersonOccu from "./PersonOccu";
import Promotions from "./Promotions";
import Messages from "./Messages";
import Paymentglobal from "./Paymentglobal";
import Paymentinfos from "./Paymentinfos";
import Administration from "./Administration";
import Photos from "./Photos";
import Services from "./Services";
import Settings from "./Settings";
import Renovations from "./Renovations";
import { useTranslation } from "react-i18next";
import LeftMenu from "../../layout/LeftMenu"
import { useState } from "react";

const Home = () => {

  const { t } = useTranslation();

  const [boutonActif, setBoutonActif] = useState("home");

  const handleClick = (bouton) => {
      setBoutonActif(bouton);
  };

  const data = [
    { id: 1, type: "button", title: "Accueil", value: "home", icon: <DashboardIcon />, component: <HotelDetails t={t} /> },
    {
      id: 2,
      type: "accordion",
      title: t('rooms_and_rates'),
      icon: <PeoplesIcon />,
      buttons: [
        { id: 1, title: t('ho_act_type'), value: "roomtype", component: <RoomType t={t} /> },
        { id: 2, title: t('ho_act_Opt_Tarif'), value: "rates", component: <Rates t={t} /> },
        { id: 3, title: t('avl_man_segment'), value: "availability", component: <Availability t={t} /> },
        { id: 4, title: t('rates_and_rest'), value: "restrictions", component: <Restrictions t={t} /> },
        { id: 5, title: t('ho_act_cancellation'), value: "cancel", component: <Cancel t={t} /> },
        { id: 6, title: t('ho_act_frais_ind'), value: "occupancy", component: <PersonOccu t={t} /> },
        { id: 7, title: t('ho_act_tarification_auto'), value: "rateadj", component: <Rateadj t={t} /> },
      ],
    },
    { id: 3, type: "button", title: t('bookings'), value: "booking", icon: <CalendarIcon />, component: <Booking t={t} /> },
    { id: 4, type: "button", title: t('promotions'), value: "promotions", icon: <TagIcon />, component: <Promotions t={t} /> },
    { id: 5, type: "button", title: t('messages'), value: "messages", icon: <MessageIcon />, component: <Messages t={t} /> },
    {
      id: 6,
      type: "accordion",
      title: "Paiements",
      icon: <GrowthIcon />,
      buttons: [
        { id: 1, title: t('billing'), value: "billing", component: <Paymentglobal t={t} /> },
        { id: 2, title: t('ho_act_paiement'), value: "paymentReception", component: <Facture t={t} /> },
        { id: 3, title: t('pay_infos'), value: "Paymentinfos", component: <Paymentinfos t={t} /> },
      ],
    },

    { id: 7, type: "button", title: t('performances'), value: "", icon: <BarChartIcon /> },
    {
      id: 8,
      type: "accordion",
      title: "Détails de l'hôtel",
      icon: <ListIcon />,
      buttons: [
        { id: 1, title: t('admin'), value: "admin", component: <Administration /> },
        { id: 2, title: t('photos'), value: "photos", component: <Photos /> },
        { id: 3, title: t('services'), value: "services", component: <Services /> },
        { id: 4, title: t('settings'), value: "settings", component: <Settings /> },
        { id: 5, title: t('ren_closure'), value: "renovations", component: <Renovations /> },
      ],
    },
    { id: 9, type: "button", title: t('log_out'), value: "", icon: <OffIcon /> },
  ];

  return (
    <LeftMenu data={data} separator={7} boutonActif={boutonActif} handleClick={handleClick}/>
  );
};

export default Home;
