import React from 'react'

const Logo = ({width}) => {
  return (
    <a href='/' className='text-decoration-none'>
      <svg width={width} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 155 28"><rect width="10.16" height="19.93" fill="#8077ec" rx="5.08" transform="rotate(29.49 -5.18 20.77) skewX(.85)"></rect><rect width="10.16" height="25.62" fill="#8077ec" rx="5.08" transform="matrix(.87 .492 -.48 .878 27.17 0)"></rect><rect width="10.16" height="10.25" fill="#ffdb80" rx="5.08" transform="rotate(29.49 -8.24 75.34) skewX(.85)"></rect> </svg>
    </a>
  )
}

export default Logo
