import { Col, Row } from "react-bootstrap";
import CheckInput from "../../../components/forms/CheckInput"
import { useState } from "react";
import Select from '../../../components/forms/Select'
import Input from '../../../components/forms/Input'
import ExtraCharge from '../../../components/ExtraCharge'
import DepositAge from "./Age";
import Period from "./Period";
import DateYear from "../../../components/DateYear";
import { useTranslation } from "react-i18next";

const Deposit = () => {
    const { t } = useTranslation();
    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    const data = [
        { title: t('general_deposit'), value: "DépôtGénéral" },
        { title: t('clean_deposit'), value: "DépôtCaisse" },
        { title: t('vacation_deposit'), value: "DépôtVacance" },
        { title: t('collected_deposit'), value: "CautionCollectee" },
        { title: t('deposit_payment'), value: "PaiementAlternatif" },
    ]

    const deposit = [
        { title: t('per_person'), value: "ParPersonne" },
        { title: t('per_stay'), value: "ParHébérgement" },
    ]

    const payment = [
        { title: "PayPal", value: "PayPal" },
        { title: t('bank_transfer'), value: "TransfertBancaire" },
        { title: t('bank_wire'), value: "VirementBancaire" },
    ]

    const time = [
        { title: t('24_hours'), value: "24heures" },
        { title: t('48_hours'), value: "48heures" },
        { title: t('72_hours'), value: "72heures" },
    ]

    const mode = [
        { title: t('cash_only'), value: "EspècesUniquement" },
        { title: t('credit_card_only'), value: "CartesCrédit" },
        { title: t('debit_card_only'), value: "CartesDébit" },
        { title: t('cash_credit_only'), value: "EspècesCrédit" },
        { title: t('cash_credit_debit'), value: "EspècesCréditDébit" },
    ]

    return (
        <>
            {data.map((check, index) => (
                <div key={index} className='my-3'>

                    <div className="fs-6">
                        <CheckInput id={check.value} label={check.title} checked={checkedItems[check.value] || false} onChange={() => handleCheckboxChange(check.value)} />
                    </div>

                    {checkedItems[check.value] && (
                        <div className="px-3">
                            {check.value === 'DépôtGénéral' && (
                                <>

                                    <Period />
                                    <div className="my-3">
                                        <DepositAge />

                                        <Row className="align-items-center">
                                            <Col xl="8">
                                                <ExtraCharge />
                                            </Col>
                                            <Col xl="4" className="my-3">
                                                <Select data={deposit} />
                                            </Col>
                                        </Row>

                                        <CheckInput id="EspècesUniquement" label={t('cash_only')} />
                                    </div>
                                </>
                            )}

                            {check.value === 'DépôtCaisse' && (
                                <>

                                    <Period />
                                    <DepositAge />
                                    <ExtraCharge />
                                    <CheckInput id="EspècesUniquement" label={t('cash_only')} />
                                </>
                            )}

                            {check.value === 'DépôtVacance' && (
                                <>

                                    <DateYear />
                                    <DepositAge />
                                    <ExtraCharge />
                                    <div className="my-3">
                                        <CheckInput id="EspècesUniquement" label={t('cash_only')} />
                                    </div>
                                </>
                            )}

                            {check.value === 'CautionCollectee' && (
                                <>
                                    <Row className='fs-6 align-items-center'>
                                        <Col md="6" className='my-3'>
                                            <span className='text-dark'>{t('cost')}</span>
                                        </Col>
                                        <Col md="6" className='my-3'>
                                            <Input placeholder={t('amount_and_taxes')} />
                                        </Col>
                                    </Row>
                                </>
                            )}

                            {check.value === 'PaiementAlternatif' && (
                                <>
                                    <Row className='fs-6 align-items-center'>
                                        <Col md="6" className='d-flex align-items-center my-3'>
                                            <div className='d-flex flex-column w-100'>
                                                <span className='fs-6'>{t('payment_type')}</span>
                                                <div className="mt-2">
                                                    <Select data={payment} />
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md="6" className='d-flex align-items-center my-3'>
                                            <div className='d-flex flex-column w-100'>
                                                <span className='fs-6'>{t('payment_after_booking')}</span>
                                                <div className="mt-2">
                                                    <Select data={time} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}

                        </div>
                    )}

                </div>
            ))}
            <Row className='fs-6 align-items-center'>
                <Col md="6" className='my-3'>
                    <span className='fw-medium text-dark'>{t('payment_methods_for_fees')}</span>
                </Col>
                <Col md="6" className='my-3'>
                    <Select data={mode} />
                </Col>
            </Row>
        </>
    )
}

export default Deposit