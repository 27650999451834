
import { Col, Row } from 'react-bootstrap';
import { ArchiveRestore, Info, PhoneCall, Trash2 } from 'lucide-react';

const ChatHeader = ({ selectedUser, t }) => {
    return (
        <Row className='align-items-center'>
            <Col md="4" lg="4" xl="3" className='d-none d-md-block'>
                <div className='fs-5 fw-medium'>{t('inbox')}</div>
            </Col>
            <Col md="8" lg="8" xl="9">
                <div className='d-flex align-items-center justify-content-between py-2'>
                    <span className='d-flex align-items-center'>
                        <img className='rounded-circle' src={selectedUser.image} width={40} height={40} alt={selectedUser.first_name} />
                        <span className='mx-2'>
                            <div className='fs-6'>{selectedUser.first_name} {selectedUser.last_name}</div>
                            <p className='fw-light'>{selectedUser.gender}</p>
                        </span>
                    </span>
                    <span className='d-flex'>
                        <span role='button' title={t('call')} className='mx-3'><PhoneCall size={20} strokeWidth={1.5} /></span>
                        <span role='button' title={t('clients_info')} className='mx-3'><Info size={20} strokeWidth={1.5} /></span>
                        <span role='button' title={t('archive')} className='mx-3'><ArchiveRestore size={20} strokeWidth={1.5} /></span>
                        <span role='button' title={t('remove_chat')} className='mx-3 text-red'><Trash2 size={20} strokeWidth={1.5} /></span>
                    </span>
                </div>
            </Col>
        </Row>
    )
}

export default ChatHeader
