import { useState } from "react";
import { Row, Col } from "react-bootstrap";
import Choice from "../../../components/Choice";
import { BookOpenIcon, Headphones, User2 } from "lucide-react";
import CheckInput from "../../../components/forms/CheckInput";
import { useTranslation } from "react-i18next";

const Host = () => {

  const { t } = useTranslation();

  const lang = [
    { title: "Français", value: "Français" },
    { title: "Anglais", value: "Anglais" },
    { title: "Espagnol", value: "Espagnol" },
    { title: "Chinois", value: "chinois" },
    { title: "Arabe", value: "arabe" },
    { title: "Allemand", value: "allemand" },
    { title: "Russe", value: "russe" },
    { title: "Japonais", value: "japonais" },
    { title: "Portugais", value: "portugais" },
    { title: "Hindi", value: "hindi" },
    { title: "Italien", value: "italien" },
    { title: "Turc", value: "turc" },
    { title: "Néerlandais", value: "néerlandais" },
    { title: "Suédois", value: "suédois" },
    { title: "Polonais", value: "polonais" },
    { title: "Grec", value: "grec" },
    { title: "Tchèque", value: "tchèque" },
  ];

  const [infos, setInfos] = useState({
    host: "yes",
    restrictions: false,
    elements: lang.slice(0, 3),
    options: lang.slice(3),
    selected: "",
  });

  const handleHost = (value) => {
    setInfos({ ...infos, host: value });
  };

  const handleSelectChange = (event) => {
    const value = event.target.value;

    if (!infos.elements.map((opt) => opt.value).includes(value)) {
      const selectedOption = infos.options.find((option) => option.value === value);
      setInfos((prevState) => ({
        ...prevState,
        elements: [...prevState.elements, selectedOption],
        options: prevState.options.filter((option) => option.value !== value),
      }));
    }
  };

  const handleDelete = (value) => {
    setInfos((prevState) => ({
      ...prevState,
      elements: prevState.elements.filter((item) => item.value !== value.value),
      options: [...prevState.options, value],
    }));
  };

  return (
    <section className="container form-transition">
      <Row className="justify-content-center">
        <Col lg="7" xl="9" className="my-3">
          <>
            <h2 className="fs-3 lh-base fw-medium text-dark">{t('host_title')}</h2>
            <p className="fs-5 fw-light">{t('host_caption')}</p>

            <div className="my-4">
              <Choice text={<span className="fs-5 text-dark">{t('TransportGuide')}</span>} selectedChoice={infos.host} onChange={handleHost} />
              {infos.host === "yes" && (
                <div className="fs-6 my-4">
                  <Row className="align-items-center border-bottom py-3">
                    <Col xl="6">{t('TransportLangue')}</Col>
                    <Col xl="2" className="d-flex justify-content-center">
                      <div className="d-flex flex-column align-items-center" title="P. ex., guide-conférencier, instructeur, accompagnateur, etc." role="button">
                        <div className="text-blue">
                          <User2 size={30} strokeWidth={1.5} />
                        </div>
                        <div className="fs-6 fw-light mt-2">{t('TransportPers')}</div>
                      </div>
                    </Col>
                    <Col xl="2" className="d-flex justify-content-center">
                      <div className="d-flex flex-column align-items-center" role="button">
                        <div className="text-blue">
                          <Headphones size={30} strokeWidth={1.5} />
                        </div>
                        <div className="fs-6 fw-light mt-2">{t('TransportAudio')}</div>
                      </div>
                    </Col>
                    <Col xl="2" className="d-flex justify-content-center">
                      <div className="d-flex flex-column align-items-center" role="button">
                        <div className="text-blue">
                          <BookOpenIcon size={30} strokeWidth={1.5} />
                        </div>
                        <div className="fs-6 fw-light mt-2">{t('informations')}</div>
                      </div>
                    </Col>
                  </Row>

                  {infos.elements.map((item, index) => (
                    <>
                      <Row key={item.value} className="my-3 align-items-center">
                        <Col xl="6">{item.title}</Col>
                        <Col xl="2" className="d-flex justify-content-center">
                          <CheckInput />
                        </Col>
                        <Col xl="2" className="d-flex justify-content-center">
                          <CheckInput />
                        </Col>
                        <Col xl="2" className="d-flex justify-content-center">
                          <CheckInput />
                        </Col>
                      </Row>
                      {index >= 3 && (
                        <span role="button" onClick={() => handleDelete(item)} className="text-danger">
                          {t('remove')}
                        </span>
                      )}
                      <hr className="border-secondary" />
                    </>
                  ))}

                  <>
                    <select value={infos.selected} onChange={handleSelectChange} className="input-blue w-100 p-4 border my-3">
                      <option value="">-- {t('add')} --</option>
                      {infos.options.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.title}
                        </option>
                      ))}
                    </select>
                  </>
                </div>
              )}
            </div>
          </>
        </Col>
      </Row>
    </section>
  );
};

export default Host;
