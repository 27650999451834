import { Row, Col } from "react-bootstrap";
import Select from "../../../components/forms/Select";
import { useState } from "react";

const Mileage = ({ t }) => {

  const CurrentDate = new Date();
  const CurrentYear = CurrentDate.getFullYear();
  const prevYears = [];

  for (let i = 0; i <= 14; i++) {
    const year = CurrentYear - i;
    prevYears[`${year}`] = {
      title: `${year}`,
      valeur: year,
    };
  }

  const kilometers = [
    { title: "0-15 000 km", value: "0-15 000 km" },
    { title: "15-50 000 km", value: "15-50 000 km" },
    { title: "50-100 000 km", value: "50-100 000 km" },
    { title: "100-150 000 km", value: "100-150 000 km" },
    { title: "150-200 000 km", value: "150-200 000 km" },
    { title: "+200 000 km", value: "+200 000 km" },
  ];

  const transmission = [
    { title: t('auto'), value: "Automatique" },
    { title: t('manual'), value: "Manuelle" },
  ];

  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedModel, setSelectedModel] = useState("");

  const brands = [
    { title: "Toyota", value: "Toyota" },
    { title: "Honda", value: "Honda" },
    { title: "Ford", value: "Ford" },
  ];

  const models = {
    Toyota: [
      { title: "Camry", value: "Camry" },
      { title: "Corolla", value: "Corolla" },
      { title: "Rav4", value: "Rav4" },
    ],
    Honda: [
      { title: "Civic", value: "Civic" },
      { title: "Accord", value: "Accord" },
      { title: "CR-V", value: "CR-V" },
    ],
    Ford: [
      { title: "F-150", value: "F-150" },
      { title: "Mustang", value: "Mustang" },
      { title: "Escape", value: "Escape" },
    ],
  };

  const state = [
    { title: t('broken_down'), value: "En panne" },
    { title: t('degraded'), value: "Dégradé" },
    { title: t('normal'), value: "Normal" },
    { title: t('regular_maint'), value: "Entretien régulier" },
    { title: t('newe'), value: "Neuve" },
  ];

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
    setSelectedModel("");
  };

  const handleModelChange = (event) => {
    setSelectedModel(event.target.value);
  };

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="9" xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('CarsDetails')}</h2>
              <p className="fs-5 fw-light">{t('CarsDetailsP')} </p>

              <div className="my-4">
                <Row className="fs-6">
                  <Col md="6" className="my-3">
                    <div className="text-dark">{t('CarsAddMArque')}</div>
                    <div className="my-1">
                      <Select value={selectedBrand} data={brands} handleChange={handleBrandChange} />
                    </div>
                  </Col>
                  <Col md="6" className="my-3">
                    <div className="text-dark">{t('CarsAddModel')}</div>
                    <div className="my-1">
                      <Select value={selectedModel} data={selectedBrand ? models[selectedBrand] : models["Toyota"]} handleChange={handleModelChange} />
                    </div>
                  </Col>
                </Row>

                <div className="my-3 fs-6">
                  <div className="text-dark">{t('CarsAddEtat')}</div>
                  <div className="my-1">
                    <Select data={state} />
                  </div>
                </div>

              </div>

              <div className="my-4">
                <div className="my-3 fs-6">
                  <div className="text-dark">{t('CarsDetailsKilométrage')}</div>
                  <div className="my-1">
                    <Select data={kilometers} />
                  </div>
                </div>

                <Row className="fs-6 ">
                  <Col md="6" className="my-3">
                    <div className="fs-6 text-dark">{t('CarsDetailsVit')}</div>
                    <div className="my-1">
                      <Select data={transmission} />
                    </div>
                  </Col>
                  <Col md="6" className="my-3">
                    <div className="text-dark">{t('CarsDetailsYear')}</div>
                    <div className="my-1">
                      <Select data={prevYears} />
                    </div>
                  </Col>
                </Row>
              </div>
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Mileage;
