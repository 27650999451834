import DashboardIcon from "@rsuite/icons/Dashboard";
import { useTranslation } from "react-i18next";
import Board from "./Board";
import PeoplesIcon from '@rsuite/icons/Peoples';
import MessageIcon from '@rsuite/icons/Message';
import SettingIcon from '@rsuite/icons/Setting';
import Clients from "./clients/Details";
import Networks from "./networks/Details";
import Products from "./products/Details";
import Settings from "./settings/Details";
import Chat from "./chat/Details";
import GearIcon from '@rsuite/icons/Gear';
import LeftMenu from "../../layout/LeftMenu"
import PeopleBranchIcon from '@rsuite/icons/PeopleBranch';
import { useEffect, useState } from "react";
import ExitIcon from '@rsuite/icons/Exit';
import CalendarIcon from '@rsuite/icons/Calendar';
import Bookings from "./bookings/Details";

const Home = () => {

  const { t } = useTranslation();

  const [mode, setMode] = useState(false);

  useEffect(() => {
    const newMode = localStorage.getItem("mode");
    if (newMode) {
      setMode(newMode === "true");
    }
  }, []);

  const [boutonActif, setBoutonActif] = useState("home");

  const handleClick = (bouton) => {
    setBoutonActif(bouton);
  };

  const data = [
    { id: 1, type: "button", title: t('dashboard'), value: "home", icon: <DashboardIcon />, component: <Board mode={mode} boutonActif={boutonActif} handleClick={handleClick} /> },
    { id: 2, type: "button", title: t('products'), value: "products", icon: <SettingIcon />, component: <Products mode={mode} /> },
    { id: 3, type: "button", title: t('bookings'), value: "bookings", icon: <CalendarIcon />, component: <Bookings mode={mode} /> },
    { id: 4, type: "button", title: t('costumers'), value: "clients", icon: <PeoplesIcon />, component: <Clients mode={mode} /> },
    { id: 5, type: "button", title: t('networks'), value: "networks", icon: <PeopleBranchIcon />, component: <Networks mode={mode} /> },
    { id: 6, type: "button", title: t('chat'), value: "chat", icon: <MessageIcon />, component: <Chat mode={mode} /> },
    { id: 7, type: "button", title: t('settings'), value: "settings", icon: <GearIcon />, component: <Settings mode={mode} /> },
    { id: 8, type: "button", title: t('quit'), value: "", icon: <ExitIcon />, component: <div></div> },
  ];

  return (
    <div style={{ background: mode ? "#2D2D30" : "#F7F9FB" }}>
      <LeftMenu mode={mode} data={data} separator={6} boutonActif={boutonActif} handleClick={handleClick} />
    </div>
  );
};

export default Home;
