import StepComponent from "../../../components/StepComponent";
import Title from '../Title'
import Description from '../Description'
import Photos from '../Photos'
import Type from '../Type'
import Attributes from "../eventsComponents/Attributes";
import Policy from "../eventsComponents/Policy";
import Pricing from "../Pricing";
import Review from "../Review";
import Booking from "../Booking";
import Location from "../Location";
import HandleSteps from "../../../components/HandleSteps";

const Events = ({ step, t, isLoading, handlePrevStep, handleNextStep }) => {

    return (

        <section className="position-relative py-5 mb-5 h-100">
            <form action="/">
                {step === 1 && <StepComponent step={1} />}

                {step === 2 && <Title />}
                {step === 3 && <Type t={t} />}
                {step === 4 && <Location t={t} />}

                {step === 5 && <StepComponent step={2} />}

                {step === 6 && <Description />}
                {step === 7 && <Attributes t={t} />}
                {step === 8 && <Photos />}

                {step === 9 && <StepComponent step={3} />}

                {step === 10 && <Policy t={t} />}
                {step === 11 && <Booking t={t} />}
                {step === 12 && <Pricing t={t} />}

                {step === 13 && <Review t={t} />}
            </form>
            <HandleSteps isLoading={isLoading} totalSteps={13} step={step} prev={handlePrevStep} next={handleNextStep} sepratorOne={4} SeparatorTwo={8} />
        </section>
    );
};

export default Events;
