import { Row, Col } from "react-bootstrap";
import { Bed, Home, Users2 } from "lucide-react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SelectedChoice from "../../../components/forms/SelectedChoice";

const Privacy = () => {

  const { t } = useTranslation();
  const [infos, setInfos] = useState({
    choice: "",
  });

  const handleChoice = (value) => {
    setInfos({ ...infos, choice: value });
  };

  const data = [
    {
      title: t('entire_rental'),
      icon: <Home strokeWidth={1.2} size={25} />,
      value: "Un logement entier",
      caption: t('entire_rental_text'),
    },
    {
      title: t('single_room'),
      icon: <Bed strokeWidth={1.2} size={25} />,
      value: "Une chambre",
      caption: t('signle_room_text'),
    },
    {
      title: t('shared_room'),
      icon: <Users2 strokeWidth={1.2} size={25} />,
      value: "Chambre partagée",
      caption: t('shared_room_text'),
    },
  ];
  

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="9" xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('rental_type_for_travelers')}</h2>
              <p className="fs-5 fw-light">{t('rental_type_for_travelers_caption')}</p>
              <SelectedChoice data={data} handleChoice={handleChoice} selectedChoice={infos.choice} />
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Privacy;
