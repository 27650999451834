import React, { useState } from 'react'
import RoomOption from './RoomOption'
import { Col, Row } from 'react-bootstrap'
import Select from '../../../components/forms/Select'
import RadioInput from '../../../components/forms/RadioInput'
import CheckInput from '../../../components/forms/CheckInput'
import { useTranslation } from "react-i18next";

const DetailsBathroom = () => {
    
    const { t } = useTranslation();

    const [infos, setInfos] = useState({
        bathroom: "",
    });

    const handleBathroom = (value) => {
        setInfos({ ...infos, bathroom: value });
    };

    const length = Array.from({ length: 20 }, (_, index) => ({
        title: (index + 1).toString(),
        value: index + 1,
    }));

    const data = [
        { title: t('private_bathroom'), value: "SallePrivée" },
        { title: t('open_bathroom'), value: "SalleBainPartiellementOuverte" },
        { title: t('private_bathroom_not_inroom'), value: "SallePasDanLaChambre)" },
    ]

    return (
        <>

            <RoomOption />

            <Row className='my-3 align-items-center'>
                <Col md="6" className='my-2'>
                    <span className='fs-6 text-dark'>{t('how_many_bathrooms')}</span>
                </Col>
                <Col md="6" className='my-2'>
                    <Select data={length} />
                </Col>
            </Row>

            <Row className='fs-6 align-items-center'>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <span className='text-dark'>{t('is_there_aprivate_or_shared_bathroom')}</span>
                </Col>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <div className='mx-3'>
                        <RadioInput label={t('private')} id='private' value="private" onChange={() => handleBathroom('private')} name="bathroom" />
                    </div>

                    <div className='mx-3'>
                        <RadioInput label={t('shared')} id='shared' value="shared" onChange={() => handleBathroom('shared')} name="bathroom" />
                    </div>
                </Col>

            </Row>

            {infos.bathroom === 'private' ?
                <Row className='my-3 align-items-center'>
                    <Col md="6" className='my-2'>
                        <span className='fs-6 text-dark'>{t('what_type_of_private_bathroom')}</span>
                    </Col>
                    <Col md="6" className='my-2'>
                        <Select data={data} />
                    </Col>

                </Row>
                :
                <div className='fs-6'>
                    <div className='my-3'>
                        <CheckInput id="SalleDeBainCommune" label={t('common_bathroom')} />
                    </div>

                    <div className='my-3'>
                        <CheckInput id="SalleDebainSéparée" label={t('separated_bathroom')} info={t('campsite')} />
                    </div>

                </div>
            }
        </>
    )
}

export default DetailsBathroom