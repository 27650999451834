import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Select from '../../../components/forms/Select';
import Input from '../../../components/forms/Input';
import RadioInput from '../../../components/forms/RadioInput';
import { useTranslation } from "react-i18next";

const WifiFees = () => {
    const { t } = useTranslation();
    const [groupId] = useState(uuidv4());

    const [infos, setInfos] = useState({
        expense: "Gratuit",
    });

    const handleExpense = (value) => {
        setInfos({ ...infos, expense: value });
    };

    const feesData = [
        { title: t('per_stay'), value: "ParSejour" },
        { title: t('per_night'), value: "ParNuit" },
        { title: t('per_day'), value: "ParJour" },
        { title: t('per_week'), value: "ParSemaine" },
        { title: t('per_minute'), value: "ParMinute" },
        { title: t('per_hour'), value: "ParHeure" },
        { title: t('per_24h'), value: "ParPériode" },
        { title: t('per_several_minutes'), value: "ParPlusieursMinutes" },
        { title: t('per_several_hours'), value: "ParPlusieursHeures" }
    ]

    return (
        <>
            <Row className='fs-6 align-items-center'>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <span className='text-dark'>{t('internet_access_fees')}</span>
                </Col>
                <Col md="6" className='d-flex align-items-center my-3'>
                    <div className='mx-3'>
                        <RadioInput label={t('free')} id={`Gratuit_${groupId}`} name={`wifi_${groupId}`} defaultChecked value="Gratuit" onChange={() => handleExpense('Gratuit')} />
                    </div>

                    <div className='mx-3'>
                        <RadioInput label={t('supplement')} id={`Supplément_${groupId}`} name={`wifi_${groupId}`} value="Supplément" onChange={() => handleExpense('Supplément')} />
                    </div>
                </Col>
            </Row>

            {
                infos.expense === "Supplément" && (
                    <Row>
                        <Col md="6" className='my-3'>
                            <Input placeholder={t('amount_and_taxes')} type="number" />
                        </Col>
                        <Col md="6" className='my-3'>
                            <Select data={feesData} />
                        </Col>
                    </Row>
                )
            }
        </>
    )
}

export default WifiFees