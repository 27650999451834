import { Col, Row } from 'react-bootstrap';
import Select from '../../../components/forms/Select';
import Choice from '../../../components/Choice'
import { useState } from 'react';
import Input from '../../../components/forms/Input'
import { useTranslation } from "react-i18next";

const DetailsRoom = () => {

    const { t } = useTranslation();

    const [infos, setInfos] = useState({
        smoking: "no",
        wheelchair: "no"
    });

    const handleSmoking = (value) => {
        setInfos({ ...infos, smoking: value });
    };

    const handleWheelchair = (value) => {
        setInfos({ ...infos, wheelchair: value });
    };

    const roomsLength = Array.from({ length: 20 }, (_, index) => ({
        title: `${index + 1}`,
        value: index + 1,
    }));

    const rooms = [
        { title: t('appart'), value: "Appartement" },
        { title: t('bungalow'), value: "Bungalow" },
        { title: t('double_room_with_twinbeds'), value: "ChambreDoubleLitsJumeaux" },
        { title: t('twin_room'), value: "ChambreDouble" },
        { title: t('twin_room_for_1'), value: "ChambreUnePersonne" },
        { title: t('duplex'), value: "Duplex" },
        { title: t('house'), value: "Maison" },
        { title: t('loft'), value: "Loft" },
        { title: t('lux_appart'), value: "AppartementLuxe" },
        { title: t('quadruple_room'), value: "ChambreQuadruple" },
        { title: t('room'), value: "Chambre" },
        { title: t('single_room'), value: "ChambreSimple" },
        { title: t('studio'), value: "Studio" },
        { title: t('suite'), value: "Suite" },
        { title: t('triple_room'), value: "ChambreTriple" },
        { title: t('room_with_twinbed'), value: "ChambreLitsJumeaux" },
        { title: t('villa'), value: "Villa" },
    ]

    const type = [
        { title: t('by_basic'), value: "DeBase" },
        { title: t('business'), value: "Affaires" },
        { title: t('city'), value: "Ville" },
        { title: t('classic'), value: "Classique" },
        { title: t('club'), value: "Club" },
        { title: t('comfort'), value: "Confort" },
        { title: t('lux'), value: "DeLuxe" },
        { title: t('design'), value: "Design" },
        { title: t('economic'), value: "Économique" },
        { title: t('elite'), value: "Élite" },
        { title: t('exclusif'), value: "Exclusif" },
        { title: t('executif'), value: "Exécutif" },
        { title: t('family'), value: "Famille" },
        { title: t('galery'), value: "Galerie" },
        { title: t('majestic'), value: "Majestueux" },
        { title: t('honeymoon'), value: "Lune de miel" },
        { title: t('Junior'), value: "Junior" },
        { title: t('premier'), value: "Premier" },
        { title: t('premium'), value: "Premium" },
        { title: t('presidentiel'), value: "Présidentiel" },
        { title: t('romantique'), value: "Romantique" },
        { title: t('royal'), value: "Royal" },
        { title: t('panoramique'), value: "Panoramique" },
        { title: t('senior'), value: "Senior" },
        { title: t('signature'), value: "Signature" },
        { title: t('standard'), value: "Standard" },
        { title: t('superior'), value: "Supérieur" },
        { title: t('tradition'), value: "Tradition" }
    ]

    return (
        <>
            <Row className='align-items-center'>
                <Col lg="6" className='my-3'>
                    <span className='text-dark fs-6'>{t('room_type_q')}</span>
                    <div className='mt-2'>
                        <Select data={rooms} />
                    </div>
                </Col>
                <Col lg="6" className='my-3'>
                    <span className='text-dark fs-6'>{t('which_category_q')}</span>
                    <div className='mt-2'>
                        <Select data={type} />
                    </div>
                </Col>
            </Row>

            <Row className='align-items-center'>
                <Col lg="6" className='my-3'>
                    <span className='text-dark fs-6'>{t('spaces_length_q')}</span>
                    <div className='mt-2'>
                        <Select data={roomsLength} />
                    </div>
                </Col>
                <Col lg="6" className='my-3'>
                    <span className='text-dark fs-6'>{t('rooms_length_q')}</span>
                    <div className='mt-2'>
                        <Select data={roomsLength} />
                    </div>
                </Col>
            </Row>

            <Choice text={t('smoking_allowed_q')} onChange={handleSmoking} selectedChoice={infos.smoking} />
            <Choice text={t('wheelchair_accessible_q')} onChange={handleWheelchair} selectedChoice={infos.wheelchair} />

            <Row className='align-items-center'>
                <Col lg="6" className='my-3'>
                    <span className='fs-6 text-dark'>{t('type_rooms_length_q')}</span>
                </Col>
                <Col lg="6" className='my-3'>
                    <Input type="number" placeholder={t('rooms')} />
                </Col>
            </Row>
        </>
    )
}

export default DetailsRoom