import { Check, X } from 'lucide-react'
import React from 'react'
import { Col, Row } from 'react-bootstrap'

const Competitors = ({t}) => {

    const data = [
        { title: t('indentity_validation'), caption: t('indentity_validation_caption'), us: true, competitors: false },
        { title: t('booking_validation'), caption: t('booking_validation_caption'), us: true, competitors: true },
        { title: t('24h_assit'), caption: t('24h_assit_caption'), us: true, competitors: false },
        { title: t('technical_knowledges'), caption: t('technical_knowledges_caption'), us: false, competitors: true },
        { title: t('booking_process'), caption: t('booking_process_caption'), us: true, competitors: true },
        { title: t('publish_delay'), caption: t('publish_delay_caption'), us: true, competitors: true },
    ]

    return (
        <div className="py-5">
            <div className='fs-1 fw-medium text-dark'>{t('competitors_title')}</div>
            <p className='fs-3 fw-light' dangerouslySetInnerHTML={{__html: t('competitors_caption')}}></p>
            <div className='my-3 mt-5'>
                <Row className='align-items-center justify-content-end fs-5 fw-medium text-dark border-bottom py-3'>
                    <Col xs="3" className='d-flex justify-content-center'>
                        TravelPartner
                    </Col>
                    <Col xs="3" className='d-flex justify-content-center'>
                        {t('competitors')}
                    </Col>
                </Row>
            {data.map(item => (
                <Row className='align-items-center py-3'>
                    <Col xs="6" className='my-3'>
                        <div className='fs-5 text-dark'>{item.title}</div>
                        <div className='fs-6 mt-1'>{item.caption}.</div>
                    </Col>
                    <Col xs="3" className='d-flex justify-content-center my-3'>
                        {item.us ? <span className='text-green'><Check/></span> : <span className='text-red'><X/></span>}
                    </Col>
                    <Col xs="3" className='d-flex justify-content-center my-3'>
                        {item.competitors ? <span className='text-green'><Check/></span> : <span className='text-red'><X/></span>}
                    </Col>
                </Row>
            ))}
            </div>
        </div>
    )
}

export default Competitors
