import React, { useState } from "react";
import Select from "../../../components/forms/Select";
import { Col, Row } from "react-bootstrap";
import Today from "../charts/Today"
import Week from "../charts/Week"
import Month from "../charts/Month"

const Chart = ({ t }) => {
  const data = [
    { title: t('today'), value: "today" },
    { title: t('last_week'), value: "per week" },
    { title: t('this_year'), value: "per month" },
  ]

  const [filter, setFilter] = useState("per week")

  const handleSetFilter = (value) => {
    setFilter(value)
  }

  return (

    <div className="bg-white border rounded h-100 d-flex flex-column justify-content-between" style={{ overflow: "hidden" }}>
      <div className="p-3">
        <Row>
          <Col xl="8">
            <div className="fs-5 fw-medium text-dark">{t("overview")}</div>
            <p className="fs-6">{t('weekly_earnings')}</p>
          </Col>
          <Col xl="4">
            <Select data={data} value={filter} handleChange={(e) => handleSetFilter(e.target.value)} />
          </Col>
        </Row>
        <div className="mt-3">
          {filter === "today" && <Today t={t} />}
          {filter === "per week" && <Week t={t} />}
          {filter === "per month" && <Month t={t} />}
        </div>
      </div>

    </div>
  );
}

export default Chart;
