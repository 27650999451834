import { Row, Col } from "react-bootstrap";
import Cancel from "../components/Cancel";
import TimeInput from "../../../components/forms/TimeInput";
import DateOneInput from "../../../components/forms/DateOneInput";
import { useState } from "react";
import { Calendar, LucideMonitorPlay, User } from "lucide-react";
import Textarea from "../../../components/forms/Textarea";
import Select from "../../../components/forms/Select";

const Policy = ({ t }) => {

    const [EventType, setEventType] = useState("present")

    const handleEventType = (value) => {
        setEventType(value)
    }

    const data = [
        { title: t('present'), value: "present", icon: <User size={20} strokeWidth={1.5} /> },
        { title: t('online'), value: "online", icon: <LucideMonitorPlay size={20} strokeWidth={1.5} /> },
        { title: t('Upcoming'), value: "upcoming", icon: <Calendar size={20} strokeWidth={1.5} /> },
    ]

    const platform = [
        { title: "Zoom", value: "Zoom" },
        { title: "Microsoft team", value: "Microsoft team" },
        { title: "YouTube live", value: "YouTube" },
        { title: "Facebook live", value: "Facebook" },
        { title: "Twitch", value: "Twitch" },
        { title: "Eventbrite", value: "Eventbrite" },
        { title: "Discord", value: "Discord" },
        { title: "Google Meet", value: "Google Meet" },
        { title: t('other'), value: "other" },
    ]

    return (
        <section className="bg-white position-relative form-transition">
            <div className="container">
                <Row className="justify-content-center">
                    <Col xl="6" className="my-3">
                        <h2 className="fs-3 lh-base fw-medium text-dark">{t('activityperso')}</h2>
                        <p className="fs-5 fw-light">{t('activitypersoP')}</p>

                        <div className="my-4 fs-6">
                            <div className="fs-5 text-dark">{t('event_title')}</div>
                            <div className="d-flex align-items-center my-3">
                                {data.map(choice => (
                                    <div role="button" className={`d-flex align-items-center me-3 rounded-5 p-3 border-blue ${EventType === choice.value ? "bg-blue text-white" : "bg-light text-dark"}`} onClick={() => { handleEventType(choice.value) }}>
                                        <span className={`${EventType === choice.value ? "text-white" : "text-blue"}`} >{choice.icon}</span><span className="fs-6 fw-light mx-2">{choice.title}</span>
                                    </div>
                                ))}
                            </div>

                            {EventType === "present" && (
                                <Textarea placeholder={t('more_details_about_events')} rows={6} />
                            )}

                            {EventType === "online" && (
                                <>
                                    <div>{t('which_platform')}</div>
                                    <div className="mt-2">
                                        <Select data={platform} />
                                    </div>
                                </>
                            )}
                        </div>

                        <div className="my-4 fs-6">
                            <div className="fs-5 text-dark">{t('event_duration')}</div>
                            <p className="fs-6 fw-light">{t('event_caption_caption')}</p>
                            <Row className="fs-6">
                                <Col md="6" className="my-3">
                                    <DateOneInput placeholder={t('date')} />
                                </Col>

                                <Col md="3" className="my-3">
                                    <TimeInput type="time" placeholder={t('debut')} />
                                </Col>

                                <Col md="3" className="my-3">
                                    <TimeInput type="time" placeholder={t('end')} />
                                </Col>
                            </Row>

                        </div>

                        <div className="my-4">
                            <Cancel />
                        </div>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Policy;
