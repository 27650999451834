import { CopyPlus, Dot, EyeOff, Trash2 } from "lucide-react";
import { Col, Row } from "react-bootstrap"
import Pagination from "../../../components/Pagination";
import { useState } from "react";

const ListingGrid = ({ mode, t, data }) => {

    const [hoveredIndex, setHoveredIndex] = useState(null);

    const renderItem = (item, index) => (
        <Col key={item.title} sm="6" lg="4" xl="3" className="my-3">
            <div
                className={`position-relative rounded-3 h-100  ${mode ? "bg-dark text-white" : "border bg-white text-dark"}`}
                style={{ overflow: "hidden" }}
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
            >
                <img src={item.avatar} alt="Illustration du produit" width="100%" height="220" />
                <div className='p-3 d-flex flex-column justify-content-between align-items-center'>
                    <div className='fs-6 text-center'>{item.title}</div>
                    <div className='fw-light d-flex align-items-center'>From <span className='fs-5 secondary-text fw-medium mx-2'>{item.amount}€</span></div>
                    <div className='mt-2 d-flex align-items-center'>
                        <span className={`${item.status === "En marche" ? "text-green" : item.status === "En attente" ? "text-orange" : "text-red"}`}><Dot size={35} /></span> {item.status}
                    </div>
                </div>
                <div className='w-100 h-100 position-absolute top-0 left-0 d-flex flex-column justify-content-between align-items-center py-3' style={{ background: "#FFFFFF80", transition: "0.5s", opacity: hoveredIndex === index ? "1" : "0" }}>
                    <div className='d-flex justify-content-center w-100 mt-5 py-5 text-dark'>
                        <span role="button" title={t('clone')} className='bg-white rounded-circle d-flex justify-content-center align-items-center m-2' style={{ width: "50px", height: "50px" }}><CopyPlus size={20} strokeWidth={1.5} /></span>
                        <span role="button" title={t('disable')} className='bg-white rounded-circle d-flex justify-content-center align-items-center m-2' style={{ width: "50px", height: "50px" }}><EyeOff size={20} strokeWidth={1.5} /></span>
                        <span role="button" title={t('delete')} className='text-red bg-white rounded-circle d-flex justify-content-center align-items-center m-2' style={{ width: "50px", height: "50px" }}><Trash2 size={20} strokeWidth={1.5} /></span>
                    </div>

                    <a href={item.type === "hotel" ? "/myhotel" : `/myactivity/${item.id}`} className="btn-orange text-white p-3 px-5 rounded-5 mb-3">{t('edit')}</a>

                </div>
            </div>
        </Col>
    );

    return (
        <Pagination data={data} renderItems={(items) => (<Row>{items.map(renderItem)}</Row>)} itemsPerPage={8} />
    );
};

export default ListingGrid;


