import { Coins, Library, LucideBarChart3, MapPin, Network, Users2 } from 'lucide-react';
import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import CheckInput from '../../../components/forms/CheckInput';

const Attributes = ({ t }) => {
    const [checkboxes, setCheckboxes] = useState([
        { id: 1, isChecked: false, title: t('reach_visits'), caption: t('reach_visits_caption'), icon: <Users2 strokeWidth={1.2} size={25} /> },
        { id: 2, isChecked: false, title: t('promote_product'), caption: t('promote_product_caption'), icon: <MapPin strokeWidth={1.2} size={25} /> },
        { id: 3, isChecked: false, title: t('share_knowledges'), caption: t('share_knowledges_caption'), icon: <Library strokeWidth={1.2} size={25} /> },
        { id: 4, isChecked: false, title: t('create_network'), caption: t('create_network_caption'), icon: <Network strokeWidth={1.2} size={25} /> },
        { id: 5, isChecked: false, title: t('markey_analysis'), caption: t('markey_analysis_caption'), icon: <LucideBarChart3 strokeWidth={1.2} size={25} /> },
        { id: 6, isChecked: false, title: t('stimulate_eco'), caption: t('stimulate_eco_caption'), icon: <Coins strokeWidth={1.2} size={25} /> },
    ]);

    const toggleCheckbox = (id) => {
        setCheckboxes((prevState) =>
            prevState.map((checkbox) =>
                checkbox.id === id ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
            )
        );  
    };

    return (
        <section className="bg-white form-transition">
            <div className="container">
                <Row className="justify-content-center">
                    <Col lg="7" className="my-3">
                        <>
                            <h2 className="fs-3 lh-base fw-medium text-dark">{t('goal_title')}</h2>
                            <p className="fs-5 fw-light">{t('RestauInfosP')}</p>
                            <div className="mt-5 fs-6 text-dark">
                                {checkboxes.map(check => (
                                    <div
                                        key={check.id}
                                        className={`my-3 rounded-4 py-2 px-3 ${check.isChecked ? "border-blue" : "border"}`}
                                        onClick={() => toggleCheckbox(check.id)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <Row className='align-items-center'>
                                            <Col xl="1" className='my-3'>
        
                                                    <CheckInput
                                                        id={check.id}
                                                        checked={check.isChecked}
                                                        onChange={() => toggleCheckbox(check.id)}
                                                    />

                                            </Col>
                                            <Col xl="10" className='my-3'>
                                                <div className='fs-6 text-dark fw-medium'>{check.title}</div>
                                                <div className='fw-light'>{check.caption}</div>
                                            </Col>
                                            <Col xl="1" className='my-3'>
                                                <span className='text-blue'>{check.icon}</span>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </div>
                        </>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Attributes;
