import Input from "../../../components/forms/Input"
import { Col, Row } from 'react-bootstrap'

const Users = ({ users, handleUserClick, selectedUser, messages, t }) => {

  return (
    <div className='my-2'>
      <Input placeholder={t('search_per_name')} />
      <Row className="mt-2">
        {users.slice(0, 6).map((user) => (
          <Col xs="2" sm="12" key={user.id}>
            <div
              onClick={() => handleUserClick(user)}
              className='py-3 px-2 my-1'
              style={{
                cursor: 'pointer',
                backgroundColor: selectedUser?.id === user.id ? '#e0e0e080' : 'transparent',
              }}
            >
              <div className='d-flex align-items-center'>
                <span>
                  <img className='rounded-circle' src={user.image} width={45} height={45} alt={user.first_name} />
                </span>
                <span className='mx-2 d-none d-lg-block'>
                  <div className='fs-6'>{user.first_name} {user.last_name} </div>
                  <p className='fw-light'>{messages[user.id] ? messages[user.id][0].text[messages[user.id][0].text.length - 1] : t('no_message')}</p></span>
              </div>

            </div>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default Users
