import { LOGOUT, LOGIN, SET_LOADING, MODE } from './actions';

const initialState = {
  isLoggedIn: false,
  isLoading: false,
  mode: false,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return { ...state, isLoggedIn: true, isLoading: false };
    case LOGOUT:
      return { ...state, isLoggedIn: false, isLoading: false };
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
      case MODE:
        return { ...state, mode: action.payload };
    default:
      return state;
  }
};

export default authReducer;
