import { Col, Row } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { LayoutGrid, Plus, StretchHorizontal } from 'lucide-react';
import DisplayGrid from './ListingGrid';
import products from '../../../data/products.json'
import { useState } from 'react';
import DisplayFlex from './ListingFlex';

const Details = ({ mode }) => {

  const { t } = useTranslation();


  const [ActivButton, setActivButton] = useState("flex")

  const handleActivButton = (value) => {
    setActivButton(value)
  }


  return (
    <section className='py-3'>
      <Row className="align-items-center">
        <Col xs="4" sm="6" className="my-2">
          <h2 className={`fs-3 ${mode ? "text-white" : "text-dark"}`}>{t('my_products')}</h2>
        </Col>
        <Col xs="8" sm="6" className="my-2">
          <div className={`d-flex justify-content-end ${mode ? "text-white" : "text-dark"}`}>
            <span role='button' className={`me-3 ${ActivButton === "flex" ? "text-blue" : ""}`} onClick={() => (handleActivButton('flex'))}><StretchHorizontal size={25} strokeWidth={1.2} /></span>
            <span role='button' className={`me-3 ${ActivButton === "grid" ? "text-blue" : ""}`} onClick={() => (handleActivButton('grid'))}><LayoutGrid size={25} strokeWidth={1.2} /></span>
            <a href='/structure' role='button' className={`${mode ? "text-white" : "text-dark"}`} title={t('add_your_product')}><Plus size={25} strokeWidth={1.2} /> </a>
          </div>
        </Col>
      </Row>
      <div>
        {ActivButton === "grid" && <DisplayGrid mode={mode} t={t} data={products.products} />}
        {ActivButton === "flex" && <DisplayFlex mode={mode} t={t} data={products.products} />}
      </div>
    </section>
  );
};

export default Details;
