import DashboardIcon from "@rsuite/icons/Dashboard";
import CalendarIcon from "@rsuite/icons/Calendar";
import TagIcon from "@rsuite/icons/Tag";
import OffIcon from "@rsuite/icons/Off";
import GearIcon from '@rsuite/icons/Gear';
import Details from "./Details";
import Promotions from "./Promotions";
import Availability from "./Availability";
import Administration from "./Administration";
import { useTranslation } from "react-i18next";
import LeftMenu from "../../layout/LeftMenu";
import { useState } from "react";


const Home = () => {

    const { t } = useTranslation();

    const data = [
        { id: 1, type: "button", title: t('home'), value: "home", icon: <DashboardIcon />, component: <Details t={t} /> },
        { id: 2, type: "button", title: t('availability'), value: "availability", icon: <CalendarIcon />, component: <Availability t={t} /> },
        { id: 3, type: "button", title: t('promotions'), value: "promotions", icon: <TagIcon />, component: <Promotions t={t} /> },
        { id: 4, type: "button", title: t('admin'), value: "admin", icon: <GearIcon />, component: <Administration t={t} /> },
        { id: 5, type: "button", title: t('disable'), value: "", icon: <OffIcon />, component: "" },
    ];

    const [boutonActif, setBoutonActif] = useState("home");

    const handleClick = (bouton) => {
        setBoutonActif(bouton);
    };

    return (
        <LeftMenu data={data} separator={3} boutonActif={boutonActif} handleClick={handleClick} />
    );
};

export default Home;
