import React from 'react'
import Input from '../../../components/forms/Input'
import { Col, Row } from 'react-bootstrap'

const Billing = ({ InputSection, t }) => {
    return (
        <>
            <div className='fs-5 fw-medium text-dark'>Comment et où recevrai-je mes paiements ?</div>
            <p className='fw-light'>Update your billing details.</p>

            <InputSection title="Informations de la carte" description="Le nom et le numéro de 16 chiffres qui se trouve sur votre carte de crédit." input={<Row>
                <Col sm="6">
                    <Input type="text" placeholder={t('TransportI1')} />
                </Col>
                <Col sm="6">
                    <Input type="number" placeholder="0000 0000 0000 0000" />
                </Col>
            </Row>} />

            <hr className="border-secondary my-4" />

            <InputSection title="Expiration" description="Assurez-vous que votre carte est valide." input={<Input type="number" placeholder="MM / AA" />} />

            <InputSection title={t('cryptogramme')} description="Le code de sécurité à trois chiffres situé au dos de la carte." input={<Input placeholder={t("CVC")} />} />

        </>
    )
}

export default Billing
