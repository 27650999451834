import { Facebook, Instagram, Linkedin, Twitter } from 'lucide-react'
import React from 'react'
import { Col, Row } from 'react-bootstrap'
import LangChanger from './LangChanger'
import { useLocation } from "react-router-dom";

const Footer = ({ t }) => {

    const location = useLocation();

    return (
        <div className='bg-dark text-white py-4 w-100'>
            <div className='container'>
                <Row className='align-items-center'>
                    <Col xs="8" md="8" lg="8" xl="9" className='my-3'>
                        <div className='fs-5 fw-light'>Copyright © 2023 <span className='text-blue'>TravelPartner</span> | All rights réserved</div>
                    </Col>
                    <Col xs="4" md="2" lg="2" xl="1" className='my-3'>
                        <div className='px-2'> <LangChanger location={location} t={t} /></div>
                    </Col>
                    <Col md="2" lg="2" xl="2" className='my-3'>
                        <div className='d-flex justify-content-between'>
                            <span><Facebook size={20} strokeWidth={1.5} /></span>
                            <span><Instagram size={20} strokeWidth={1.5} /></span>
                            <span><Linkedin size={20} strokeWidth={1.5}/></span>
                            <span><Twitter size={20} strokeWidth={1.5}/></span>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Footer
