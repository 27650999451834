import React from 'react'
import { Col, Row } from 'react-bootstrap'
import DateOneInput from '../../../components/forms/DateOneInput'
import TimeInput from '../../../components/forms/TimeInput'
import Input from '../../../components/forms/Input'

const Trip = ({ t }) => {
    return (
        <>
            <Row className="fs-6 ">
                <Col md="6" className="my-3">
                    <div className="fs-6 text-dark">{t('departure_place')}</div>
                    <div className="my-1">
                        <Input type="text" />
                    </div>
                </Col>
                <Col md="6" className="my-3">
                    <div className="fs-6 text-dark">{t('arrival_place')}</div>
                    <div className="my-1">
                        <Input type="text" />
                    </div>
                </Col>
            </Row>

            <Row className="fs-6 ">
                <Col md="6" className="my-3">
                    <div className="fs-6 text-dark">{t('departure_date')}</div>
                    <Row>
                        <Col md="6" className='my-1'>
                            <DateOneInput placeholder={t('date')} />
                        </Col>
                        <Col md="6" className='my-1'>
                            <TimeInput placeholder={t('hour')} />
                        </Col>
                    </Row>
                </Col>
                <Col md="6" className="my-3">
                    <div className="fs-6 text-dark">{t('ho_act_reservation_det_date_arrive')}</div>
                    <Row>
                        <Col md="6" className='my-1'>
                            <DateOneInput placeholder={t('date')} />
                        </Col>
                        <Col md="6" className='my-1'>
                            <TimeInput placeholder={t('hour')} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    )
}

export default Trip
