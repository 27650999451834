import { Col, Row } from 'react-bootstrap';
import Trip from './Trip';
import { useState } from 'react';
import Stopover from './Stopover';
import { MinusCircle, PlusCircle } from 'lucide-react';
import Choice from '../../../components/Choice';

const Process = ({ t }) => {
    const [outboundStopovers, setOutboundStopovers] = useState([]);
    const [returnStopovers, setReturnStopovers] = useState([]);
    const [outboundIdCounter, setOutboundIdCounter] = useState(1);
    const [returnIdCounter, setReturnIdCounter] = useState(1);

    const addOutboundStopover = () => {
        setOutboundStopovers([...outboundStopovers, { id: outboundIdCounter }]);
        setOutboundIdCounter(outboundIdCounter + 1);
    };

    const removeOutboundStopover = (id) => {
        const newStopovers = outboundStopovers.filter(stopover => stopover.id !== id);
        const reindexedStopovers = newStopovers.map((stopover, index) => ({
            id: index + 1,
        }));
        setOutboundStopovers(reindexedStopovers);
        setOutboundIdCounter(reindexedStopovers.length + 1);
    };

    const addReturnStopover = () => {
        setReturnStopovers([...returnStopovers, { id: returnIdCounter }]);
        setReturnIdCounter(returnIdCounter + 1);
    };

    const removeReturnStopover = (id) => {
        const newStopovers = returnStopovers.filter(stopover => stopover.id !== id);
        const reindexedStopovers = newStopovers.map((stopover, index) => ({
            id: index + 1,
        }));
        setReturnStopovers(reindexedStopovers);
        setReturnIdCounter(reindexedStopovers.length + 1);
    };

    const [infos, setInfos] = useState({
        return: "no",
    });

    const handleInfos = (key) => (value) => {
        setInfos(prev => ({ ...prev, [key]: value }));
    };

    return (
        <section className="bg-white position-relative form-transition">
            <div className="container">
                <Row className="justify-content-center">
                    <Col lg="10" xl="8" className="my-3">
                        <>
                            <h2 className="fs-3 lh-base fw-medium text-dark">{t('create_trip')}</h2>
                            <p className="fs-5 fw-light">{t('trip_caption')}</p>
                            <div className='mt-4'>
                                <Trip t={t} />
                                {outboundStopovers.map(stopover => (
                                    <div key={stopover.id} className='d-flex flex-column my-1'>
                                        <div className='text-blue'>{t('stopover')} {stopover.id} :</div>
                                        <Stopover t={t} />
                                        <button className="bg-transparent text-danger align-self-end" type="button" onClick={() => removeOutboundStopover(stopover.id)}>
                                            <MinusCircle strokeWidth={1.5} /> {t('remove')}
                                        </button>
                                    </div>
                                ))}
                            </div>

                            {outboundStopovers.length <= 4 && <button className="bg-transparent text-blue mt-1" type="button" onClick={addOutboundStopover}>
                                <PlusCircle strokeWidth={1.5} /> {t('add_stopover')}
                            </button>}

                            <hr className='border-secondary my-5' />

                            <>
                                <div className="my-3">
                                    <Choice
                                        text={<span className="mx-2">{t('i_have_return_light')}</span>}
                                        onChange={handleInfos('return')}
                                        selectedChoice={infos.return}
                                    />
                                </div>
                                {infos.return === "yes" &&
                                    <>
                                        <div className='mt-4'>
                                            <Trip t={t} />
                                            {returnStopovers.map(stopover => (
                                                <div key={stopover.id} className='d-flex flex-column my-1'>
                                                    <div className='text-blue'>{t('stopover')} {stopover.id} :</div>
                                                    <Stopover t={t} />
                                                    <button className="bg-transparent text-danger align-self-end" type="button" onClick={() => removeReturnStopover(stopover.id)}>
                                                        <MinusCircle strokeWidth={1.5} /> {t('remove')}
                                                    </button>
                                                </div>
                                            ))}
                                        </div>
                                        {returnStopovers.length <= 4 && <button className="bg-transparent text-blue mt-1" type="button" onClick={addReturnStopover}>
                                            <PlusCircle strokeWidth={1.5} /> {t('add_stopover')}
                                        </button>}
                                    </>
                                }
                            </>
                        </>
                    </Col>
                </Row>
            </div>
        </section>
    );
};

export default Process;
