import { XCircle } from "lucide-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const TagForm = ({ data, list }) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);
  const [value, setValue] = useState("");

  const handleInputChange = (e) => {
    setValue(e.target.value);
  };

  const handleTagSelection = () => {
    if (value.trim() !== "" && !tags.includes(value)) {
      setTags([...tags, value]);
      setValue("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleTagSelection();
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    const updatedTags = tags.filter((tag) => tag !== tagToRemove);
    setTags(updatedTags);
  };

  return (
    <div>
      <input className="input-blue w-100 p-4 border fw-light" type="text" placeholder={t('research')} value={value} onChange={handleInputChange} onKeyDown={handleKeyDown} list={list} />
      <datalist id={list}>
        {data.map((option, index) => (
          <option key={index} value={option.value} />
        ))}
      </datalist>

      <div className="d-flex flex-wrap">
        {tags.map((tag, index) => (
          <div key={index} className="d-flex rounded-5 p-3 border me-2 my-3">
            <span className="me-2 fw-light">{tag}</span>
            <span className="text-white" type="button" onClick={() => handleRemoveTag(tag)}>
              <XCircle fill="#FF0000" />
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TagForm;
