import { Row, Col } from "react-bootstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TbUser,TbUserStar } from "react-icons/tb";
import SelectedChoice from "../../../components/forms/SelectedChoice";

const Visibility = () => {

  const { t } = useTranslation();


  const [infos, setInfos] = useState({
    choice: "",
  });

  const handleChoice = (value) => {
    setInfos({ ...infos, choice: value });
  };

  const data = [
    {
      title: t('any_traveler'),
      icon: <TbUser strokeWidth={1.2} size={25} />,
      value: "NimporteQuelVoyageur",
      caption: t('any_traveler_text'),
    },
    {
      title: t('experienced_traveler'),
      icon: <TbUserStar strokeWidth={1.2} size={25} />,
      value: "UnVoyageurExpérimenté",
      caption: t('experienced_traveler_text'),
    },
  ];

  return (
    <section className="bg-white position-relative form-transition">
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="8" xl="6" className="my-3">
            <>
              <h2 className="fs-3 lh-base fw-medium text-dark">{t('fist_booking_title')}</h2>
              <p className="fs-5 fw-light">{t('first_booking_text')}</p>
              <SelectedChoice data={data} handleChoice={handleChoice} selectedChoice={infos.choice} />
            </>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Visibility;
