import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const Chart = ({ t }) => {
    const [series] = useState([
        {
            name: t('sales'),
            group: 'sales',
            data: [18, 29, 51, 9, 15, 15, 18]
        },
        {
            name: t('earnings'),
            group: 'sales',
            data: [5, 10, 16, 3, 4, 4, 6]
        },
    ]);

    const [options] = useState({
        chart: {
            type: 'bar',
            height: 280,
            stacked: true,
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        dataLabels: {
            formatter: (val) => {
                return val + '€'
            }
        },
        plotOptions: {
            bar: {
                horizontal: false
            }
        },
        xaxis: {
            categories: [
                t('sat'),
                t('sun'),
                t('mon'),
                t('thu'),
                t('wed'),
                t('tue'),
                t('fri'),
            ]
        },
        fill: {
            opacity: 1
        },
        colors: ['#8077ec', '#16A571'],
        yaxis: {
            labels: {
                formatter: (val) => {
                    return val + '€'
                }
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left'
        }
    });

    return (

        <ReactApexChart options={options} series={series} type="bar" height={360} />
    );
}

export default Chart;
