import { Col, Row } from 'react-bootstrap'
import Select from '../../../components/forms/Select'
import { useTranslation } from "react-i18next";

const DetailsViews = () => {

  const { t } = useTranslation();

  const data = [
    { title: t('no_special_view'), value: "PasDeVueSpéciale" },
    { title: t('bay'), value: "Baie" },
    { title: t('beach'), value: "Plage" },
    { title: t('canal'), value: "Canal" },
    { title: t('city'), value: "Ville" },
    { title: t('yard'), value: "Cour" },
    { title: t('desert'), value: "Désert" },
    { title: t('garden'), value: "Jardin" },
    { title: t('golf_field'), value: "TerrainGolf" },
    { title: t('port'), value: "Port" },
    { title: t('hill'), value: "Colline" },
    { title: t('lake'), value: "Lac" },
    { title: t('mountain'), value: "Montagne" },
    { title: t('ocean'), value: "Océan" },
    { title: t('sea'), value: "Mer" },
    { title: t('park'), value: "Parc" },
    { title: t('pool'), value: "Piscine" },
    { title: t('touristic_complex'), value: "ComplexTouristique" },
    { title: t('river'), value: "Rivière" },
    { title: t('valley'), value: "Vallée" },
    { title: t('vineyard'), value: "Vignoble" },
    { title: t('on_the_water'), value: "SurLeau" }
  ]

  return (
    <Row className='align-items-center'>
      <Col md="6" className='my-2'>
        <span className='fs-6 text-dark'>{t('do_ths_room_offer_special_view')}</span>
      </Col>
      <Col md="6" className='my-2'>
        <Select data={data} />
      </Col>
    </Row>
  )
}

export default DetailsViews