import React from "react";

const Checkbox = ({ id, title, isChecked, value, onChange, icon, color }) => {
  return (
    <div role="button" style={{ background: color }} className={`p-3 rounded text-dark h-100 ${isChecked ? "border-dark" : "border"}`} onClick={() => onChange(id, value)}>
      {icon}
      <div className="mt-2 fw-light">{title}</div>
    </div>
  );
};

export default Checkbox;
