import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import DateOneInput from "./forms/DateOneInput";

const DateYear = () => {

  const { t } = useTranslation();

  return (
    <Row>
      <Col md="6" className="my-3">
        <DateOneInput placeholder={t('from')} />
      </Col>
      <Col md="6" className="my-3">
        <DateOneInput placeholder={t('to')} />
      </Col>
    </Row>
  );
};

export default DateYear;
