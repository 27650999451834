import React from 'react';

const Messages = ({ selectedUser, messages, t }) => {
    const userMessages = messages[selectedUser.id] || [];

    return (
        <div className='border rounded-3 p-3 my-1 h-100'>
            {userMessages.map((msg, index) => (
                <div key={index} className='d-flex align-items-start my-4'>
                    <span>
                        <img className='rounded-circle' src={selectedUser.image} width={45} height={45} alt={selectedUser.first_name} />
                    </span>
                    <span className='mx-2'>
                        <div className='fs-6'>{selectedUser.first_name} {selectedUser.last_name}</div>
                        <p className='fw-light'>{msg.time}</p>
                        <div className='d-flex flex-column align-items-start'>
                            {Array.isArray(msg.text) ? (
                                msg.text.map((item, itemIndex) => (
                                    <div key={itemIndex} className='my-2 bg-green text-white p-3 text-start' style={{ borderRadius: "0 20px 20px 20px" }}>
                                        {item}
                                    </div>
                                ))
                            ) : (
                                <div className='my-2 bg-green text-white p-3 text-start' style={{ borderRadius: "0 20px 20px 20px" }}>
                                    {msg.text}
                                </div>
                            )}
                        </div>
                    </span>
                </div>
            ))}

            <div className='d-flex justify-content-end align-items-end'>
                <div className='d-flex align-items-start my-4'>
                    <span>
                        <img className='rounded-circle' src="https://html.phoenixcoded.net/light-able/bootstrap/assets/images/user/avatar-5.jpg" width={45} height={45} alt={selectedUser.first_name} />
                    </span>
                    <span className='mx-2'>
                        <div className='fs-6'>{t('you')}</div>
                        <p className='fw-light'>13:50PM</p>
                        <div className='my-2 bg-secondary-blue text-dark p-3 text-start' style={{ borderRadius: "0 20px 20px 20px" }}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                        </div>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Messages;
