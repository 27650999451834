import { TimePicker } from 'antd';

const TimeInput = ({ placeholder, value, onChange }) => {

    const format = 'HH:mm';

    return (
        <TimePicker format={format} className='input-blue w-100 text-dark p-4 border rounded-0' value={value} onChange={onChange} placeholder={placeholder} needConfirm={false}/>
    );
};

export default TimeInput;
