import React from 'react'
import { Col, Row } from 'react-bootstrap';
import Select from '../../../components/forms/Select';
import { useState } from 'react';
import RoomOption from './RoomOption'
import Input from '../../../components/forms/Input';
import ExtraCharge from '../../../components/ExtraCharge';
import CheckInput from '../../../components/forms/CheckInput';
import { useTranslation } from "react-i18next";

const DetailsEntertainment = () => {

    const { t } = useTranslation();

    const [checkedItems, setCheckedItems] = useState({});

    const handleCheckboxChange = (value) => {
        setCheckedItems((prevCheckedItems) => ({
            ...prevCheckedItems,
            [value]: !prevCheckedItems[value],
        }));
    };

    const entertainment = [
        { title: t('entr_title1'), value: "Télévision" },
        { title: t('entr_title2'), value: "iPod" },
        { title: t('entr_title3'), value: "LecteurMP3" },
        { title: t('entr_title4'), value: "LecteurCD" },
        { title: t('entr_title5'), value: "Ordinateur" },
        { title: t('entr_title6'), value: "ConsoleDeJeuxVidéo" },
        { title: t('entr_title7'), value: "EnceinteIntelligente" }
    ]

    const television = [
        { title: t('tv_title1'), value: "Écran plat" },
        { title: t('tv_title2'), value: "LCD" },
        { title: t('tv_title3'), value: "LED" },
        { title: t('tv_title4'), value: "Plasma" },
        { title: t('tv_title5'), value: "Smart" },
        { title: t('tv_title6'), value: "Télévision" }
    ]

    const mesures = [
        { title: t('pouces'), value: "Pouces" },
        { title: t('centimeters'), value: "Centimétres" },
    ]

    const features = [
        { title: t('features_title1'), value: "ChaînesThématiques" },
        { title: t('features_title2'), value: "FilmsAlaCarte" },
        { title: t('features_title3'), value: "LecteurDVD" },
        { title: t('features_title4'), value: "MagnétoscopeNumérique" },
        { title: t('features_title5'), value: "ServicesDeStreaming" },
        { title: t('features_title6'), value: "FilmsEnPremièreDiffusio" },
        { title: t('features_title7'), value: "SupplémentPourLaTélévision" }
    ]

    const computer = [
        { title: t('ipad'), value: "iPad" },
        { title: t('ordinateur'), value: "Tablette" },
        { title: t('tablette'), value: "Ordinateur" }
    ]

    const services = [
        { title: t('services_title1'), value: "TélévisionAvecChaînesParCâble" },
        { title: t('services_title2'), value: "TélévisionAvecChaînesParSatellite" },
        { title: t('services_title3'), value: "TélévisionAvecChaînesTNT" }
    ]

    return (
        <div className='my-3 fs-6'>
            <>

                {entertainment.map((check, index) => (
                    <div key={index} className='my-3'>
                        <>
                            <CheckInput id={check.value} label={check.title} value={check.value} checked={checkedItems[check.value] || false} onChange={() => handleCheckboxChange(check.value)} />
                        </>

                        {checkedItems[check.value] && (
                            <>
                                {check.value === 'Télévision' && (
                                    <>
                                        <RoomOption />
                                        <Row className='align-items-center'>
                                            <Col md="6" className='my-3'>
                                                <span className='fs-6 text-dark'>{t('tv_type')}</span>
                                            </Col>
                                            <Col md="6" className='my-3'>
                                                <Select data={television} />
                                            </Col>
                                        </Row>

                                        <Row className='align-items-center'>
                                            <Col md="6" className='my-3'>
                                                <Input placeholder={t('tv_size')} type="number" />
                                            </Col>
                                            <Col md="6" className='my-3'>
                                                <Select data={mesures} />
                                            </Col>
                                        </Row>

                                        <Row className='align-items-center'>
                                            <Col md="6" className='my-3'>
                                                <span className='fs-6 text-dark'>{t('tv_services')}</span>
                                            </Col>
                                            <Col md="6" className='my-3'>
                                                <Select data={services} />
                                            </Col>
                                        </Row>

                                        <div className='p-3'>
                                            {features.map((check, index) => (
                                                <>
                                                    <div key={index} className='my-3'>
                                                        <CheckInput id={check.value} label={check.title} value={check.value} checked={checkedItems[check.value] || false} onChange={() => handleCheckboxChange(check.value)} />
                                                    </div>
                                                    {checkedItems[check.value] && (
                                                        <div className='px-3'>
                                                            {check.value === 'ServicesDeStreaming' && (
                                                                <>
                                                                    <div className='my-3'>
                                                                        <CheckInput id="Netflix" label="Netflix" />
                                                                    </div>

                                                                    <div className='my-3'>
                                                                        <CheckInput id="Hulu" label="Hulu" />
                                                                    </div>
                                                                </>
                                                            )}

                                                            {check.value === 'SupplémentPourLaTélévision' && (
                                                                <ExtraCharge />
                                                            )}

                                                        </div>
                                                    )}
                                                </>
                                            ))}
                                        </div>
                                    </>
                                )}

                                {['iPod', 'LecteurMP3', 'LecteurCD', 'EnceinteIntelligente'].includes(check.value) && (
                                    <RoomOption />
                                )}

                                {check.value === 'ConsoleDeJeuxVidéo' && (
                                    <>
                                        <RoomOption />
                                        <div className='px-3'>
                                            <CheckInput id="JeuxVidéos" label={t('games')} />
                                        </div>
                                    </>
                                )}

                                {check.value === 'Ordinateur' && (
                                    <>
                                        <RoomOption />
                                        <Row className='align-items-center'>
                                            <Col md="6" className='my-3'>
                                                <span className='fs-6 text-dark'>{t('select_option')}</span>
                                            </Col>
                                            <Col md="6" className='my-3'>
                                                <Select data={computer} />
                                            </Col>
                                        </Row>
                                    </>
                                )}

                            </>
                        )}


                    </div>
                ))}
            </>
        </div>
    )
}

export default DetailsEntertainment