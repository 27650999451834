import { useTranslation } from "react-i18next";
import { useState } from 'react';
import Questions from "./Questions";
import Modal from 'react-bootstrap/Modal';
import Logo from "./Logo";

const TopContent = ({ step }) => {

  const { t } = useTranslation();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [quit, setQuit] = useState(false);

  const closeQuit = () => setQuit(false);
  const showQuit = () => setQuit(true);

  return (
    <div className="bg-white w-100">

      <Questions t={t} step={step} show={show} handleClose={handleClose} />

      <Modal show={quit} onHide={closeQuit}>
        <Modal.Header closeButton>
          <Modal.Title><div className="text-dark">{t('save_and_exit')}</div></Modal.Title>
        </Modal.Header>
        <Modal.Body><div className="fs-6 text-dark py-3 fw-light">{t('quit_message')}</div></Modal.Body>
        <Modal.Footer>
          <button className="btn-outline-blue p-3 px-4" onClick={closeQuit}>
            {t('cancel')}
          </button>
          <a href="/" className="btn-blue p-3 px-4 text-white" onClick={closeQuit}>
            {t('save')}
          </a>
        </Modal.Footer>
      </Modal>

      <div className="container d-flex justify-content-between align-items-center py-3">
        <Logo width={180}/>

        <span className="fs-6">
          {(step !== 0 && step !== 1 && step !== 6 && step !== 12 && step !== 17 && step !== 18) &&
            <button className="mx-2 p-3 px-4 bg-white rounded-5 border text-dark top-button" onClick={handleShow}>{t('your_questions')}</button>
          }
          <button className="p-3 px-4 bg-white rounded-5 border text-dark top-button" onClick={showQuit}>
            {t("save_and_exit")}
          </button>
        </span>
      </div>
    </div>
  );
};

export default TopContent;
